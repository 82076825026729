import { ApiEndpointBuilder } from '@/services/api/types';

const deleteEnterpriseStore = (builder: ApiEndpointBuilder) => {
	return builder.mutation<string, Request>({
		query: ({ id }) => ({
			url: `enterprises/${id}`,
			method: 'DELETE',
		}),
	});
}

type Request = {
	id: string
};

export default deleteEnterpriseStore;
