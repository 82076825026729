import { ApiEndpointBuilder } from "@/services/api/types";

const employeeOtpRequestEndPoint = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, employeeOtpRequest>({
    query: ({ employeeId, ...otpDetails }) => ({
      url: `/enterpriseemployees/${employeeId}/request-otp`,
      method: "PUT",
      body: {
        ...otpDetails,
      },
    }),
  });
};

export type employeeOtpRequest = {
  employeeId: string;
  phoneNumber: string;
  channel: string;
};

export default employeeOtpRequestEndPoint;
