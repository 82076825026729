export enum ESidebarMenuColors {
  blue = "#2D5BFF",
  darkBlue = "rgba(45, 91, 255, 0.05)",
  purple = "#5C2DD3",
  darkPurple = "rgba(92, 45, 211, 0.05)",
  grey = "#212121",
  darkGrey = "rgba(235, 236, 239, 0.3)",
}

export interface ISubItem {
  title: string;
  link: string;
  matchFullLink?: boolean;
  iconName: string;
  description?: string;
  id?: string;
  isProFeature?: boolean;
}

export interface IInteractiveItem {
  title: string;
  primaryColor: ESidebarMenuColors.blue | ESidebarMenuColors.purple | ESidebarMenuColors.grey;
  secondaryColor: ESidebarMenuColors;
  isAccordion?: boolean;
  iconName?: string;
  link?: string;
  items: ISubItem[];
  id?: string;
  isTop?: boolean;
}

export interface ISidebarMenuList {
  interactiveItems: IInteractiveItem[];
  fixedItems: {
    title: string;
    primaryColor: ESidebarMenuColors.blue | ESidebarMenuColors.purple | ESidebarMenuColors.grey;
    secondaryColor: ESidebarMenuColors;
    items: ISubItem[];
  };
}

export const networkIla26Endpoint = "/network-ila26";
