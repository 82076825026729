import { defineMessages } from "react-intl";

export const modulesScope = "sideBar.modulesItems";
export const parametersScope = "sideBar.parametersItems";

export default defineMessages({
  network: {
    id: `${modulesScope}.network`,
    defaultMessage: "Réseau",
  },
  home: {
    id: `${modulesScope}.home`,
    defaultMessage: "Accueil",
  },
  enterprise: {
    id: `${modulesScope}.enterprise`,
    defaultMessage: "Entreprises",
  },
  personnal: {
    id: `${modulesScope}.personnal`,
    defaultMessage: "Personnel",
  },
  accountParameters: {
    id: `${modulesScope}.accountParameters`,
    defaultMessage: "Paramètres du compte",
  },
  myFeeds: {
    id: `${modulesScope}.myFeeds`,
    defaultMessage: "Energie Net",
  },
  dashboard: {
    id: `${modulesScope}.dashboard`,
    defaultMessage: "Tableau de bord",
  },
  news: {
    id: `${modulesScope}.news`,
    defaultMessage: "Actualités",
  },
  directoryPro: {
    id: `${modulesScope}.directoryPro`,
    defaultMessage: "Annuaire pro",
  },
  directoryCompany: {
    id: `${modulesScope}.directoryCompany`,
    defaultMessage: "Market place",
  },
  sharedResources: {
    id: `${modulesScope}.sharedResources`,
    defaultMessage: "Ressources partagées",
  },
  knowledgeBase: {
    id: `${modulesScope}.knowledgeBase`,
    defaultMessage: "Connaissances",
  },
  library: {
    id: `${modulesScope}.library`,
    defaultMessage: "Bibliothèque",
  },
  sharedCalendar: {
    id: `${modulesScope}.sharedCalendar`,
    defaultMessage: "Calendrier",
  },
  inbox: {
    id: `${modulesScope}.inbox`,
    defaultMessage: "Messagerie",
  },
  businessMap: {
    id: `${modulesScope}.businessMap`,
    defaultMessage: "Business Map",
  },
  myColleagues: {
    id: `${modulesScope}.myColleagues`,
    defaultMessage: "Collègues",
  },
  ficheEntreprise: {
    id: `${modulesScope}.ficheEntreprise`,
    defaultMessage: "Fiche",
  },
  feedback: {
    id: `${modulesScope}.feedback`,
    defaultMessage: "Remontées",
  },
  taskManagement: {
    id: `${modulesScope}.taskManagement`,
    defaultMessage: "Tâches",
  },
  marketplace: {
    id: `${modulesScope}.marketplace`,
    defaultMessage: "Market place",
  },
  prestataires: {
    id: `${modulesScope}.prestataires`,
    defaultMessage: "Prestataires",
  },
  support: {
    id: `${parametersScope}.support`,
    defaultMessage: "Demande de support",
  },
  profile: {
    id: `${parametersScope}.profile`,
    defaultMessage: "Mon profil", // Changed from "Mon Profil" to "Mon profil"
  },
  companyProfile: {
    id: `${parametersScope}.companyProfile`,
    defaultMessage: "Profil entreprise",
  },
  persoLite: {
    id: `${parametersScope}.persoLite`,
    defaultMessage: "Perso Lite",
  },
  realEstate: {
    id: `${parametersScope}.realEstate`,
    defaultMessage: "Immobilier",
  },
  serviceOffer: {
    id: `${modulesScope}.serviceOffer`,
    defaultMessage: "Sous-traitances",
  },
  productsCatalog: {
    id: `${modulesScope}.productsCatalog`,
    defaultMessage: "Produits et services",
  },
  myNetwork: {
    id: `${modulesScope}.myNetwork`,
    defaultMessage: "Mon réseau",
  },
  partners: {
    id: `${modulesScope}.partners`,
    defaultMessage: "Partneaires",
  },
  signingDocuments: {
    id: `${modulesScope}.signingDocuments`,
    defaultMessage: "E Signatures",
  },
  savPro: {
    id: `${modulesScope}.savPro`,
    defaultMessage: "SAV Pro",
  },
});
