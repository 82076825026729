import { FC } from "react";
import { ContainerPages } from "@pages/error/styles.ts";
import theme from "@/theme/theme.tsx";
import { Box, Typography } from "@mui/material";
import ERROR from "@assets/images/disconnected-error.svg";
import { useIntl } from "react-intl";
import messages from "./messages.ts";

const ErrorPage: FC = () => {
  const { formatMessage: __ } = useIntl();

  return (
    <ContainerPages padding={"25px 20px"} sx={{}}>
      <Box
        sx={{
          border: "1px solid #D8D8D8",
          borderRadius: "8px",
          backgroundColor: theme.palette.background.default,
          minHeight: "88vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ display: "inline-block", textAlign: "center" }}>
          <img
            src={ERROR}
            alt="error image ..."
            style={{
              width: "500px",
              height: "500px",
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: "34px", marginTop: "-50px" }}>
              {__(messages.title)}
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>{__(messages.description)}</Typography>
            <Typography sx={{ fontWeight: 500 }}>{__(messages.action)}</Typography>
          </Box>
        </Box>
      </Box>
    </ContainerPages>
  );
};

export default ErrorPage;
