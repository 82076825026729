import { ISubItem } from "@/pages/layout/pagesListSideBar";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SidebarState {
  isSidebarOpen: boolean;
  isQuickMenuOpen: boolean;
  isSidebarHover: boolean;
  pinnedItems: string[];
  sortedItems: ISubItem[];
  isPinnedItemsLoad: boolean;
}
const initialState: SidebarState = {
  isSidebarOpen: true,
  isQuickMenuOpen: false,
  isSidebarHover: false,
  pinnedItems: [],
  sortedItems: [], 
  isPinnedItemsLoad: false,
};

const toggleSidebarSlice = createSlice({
  name: "toggleSidebar",
  initialState,
  reducers: {
    toggleSidebarAction: state => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleQuickNavigationMenu: state => {
      state.isQuickMenuOpen = !state.isQuickMenuOpen;
    },
    sidebarMouseEnter: state => {
      state.isSidebarHover = true;
    },
    sideBarMouseLeave: state => {
      state.isSidebarHover = false;
    },

    pinItem: (state, action) => {
      if (!state.pinnedItems.includes(action.payload)) {
        state.pinnedItems.push(action.payload);
      } else {
        state.pinnedItems = state.pinnedItems.filter(id => id !== action.payload);
      }
    },

    pinItems: (state, action) => {
      action.payload.forEach((item: string) => {
        if (!state.pinnedItems.includes(item)) {
          state.pinnedItems.push(item);
        }
      });
      state.isPinnedItemsLoad = true;
    },

    pinnedItemsLoad: state => {
      state.isPinnedItemsLoad = true;
    },

    setSortedItems: (state, action: PayloadAction<ISubItem[]>) => {
      state.sortedItems = action.payload;
    }, 
  },
});

export const {
  toggleSidebarAction,
  toggleQuickNavigationMenu,
  sidebarMouseEnter,
  sideBarMouseLeave,
  pinItem,
  pinItems,
  pinnedItemsLoad,
  setSortedItems
} = toggleSidebarSlice.actions;

export default toggleSidebarSlice.reducer;
