import { styled } from "@mui/material/styles";
import { classesIcon } from "@components/phone-country-input/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const classesTimer = {
  danger: "bg-danger",
};

export const Timer = styled("span")({
  ["&"]: {
    color: "black",
    fontWeight: "bold",
    marginLeft: "5px",
    [`&.${classesIcon.danger}`]: {
      color: "#cc1a36",
    },
  },
});

export const InformationIcon = styled(FontAwesomeIcon)({
  ["&"]: {
    fontWeight: "bold",
    color: "#cc1a36",
  },
});

