import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import countriesData from "./countries.json";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { FormikProps, FormikValues } from "formik";
import theme from "@/theme/theme";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Tooltip } from "@components/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleInfo, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import PhoneOtpModal from "@components/phone-country-input/opt-modal";
import { classesIcon, ValidationIcon } from "@components/phone-country-input/styles";
import { PhoneOtpObject } from "@components/phone-country-input/types";
import { useEmployeeOtpRequestMutation } from "@/store/employee/endpoints";
import toast from "react-hot-toast";
import { countryCodeToFlagImage } from "@components/phone-country-input/utils.tsx";

type PhoneCountryInputProps = {
  name: string;
  labelText?: string | JSX.Element;
  placeholder?: string;
  form: FormikProps<any>;
  values: FormikValues;
  attributes: any;
  field: any;
};
export type validationOtpType =
  | "otp-validated"
  | "otp-not-validated"
  | "not-validated"
  | "validated";

const PhoneCountryInput = ({
  values,
  form,
  name,
  labelText,
  placeholder,
  attributes,
}: PhoneCountryInputProps) => {
  const { formatMessage: __ } = useIntl();

  const [country, setCountry] = useState("+33");
  const [phone, setPhone] = useState("");
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const shouldValidated = typeof values[name] !== "string";
  const [otpStatus, setOtpStatus] = useState<validationOtpType>("otp-not-validated");
  const [objectOtpValidation, setObjectOtpValidation] = useState<PhoneOtpObject>();
  const [isInitialValue, setIsInitialValue] = useState<boolean>(true);
  const [sendCode, { isLoading: isSendingCode }] = useEmployeeOtpRequestMutation();

  const getCustomPhoneError = (errors: any, errorType: string, field?: string) => {
    if (errors && errors[errorType]) {
      if (typeof field === "string") {
        return errors[errorType][field];
      }
    }
    return undefined;
  };

  const handleClickOtpValidation = async () => {
    if (otpStatus !== "otp-validated") {
      try {
        await sendCode({
          employeeId: values[name].employeeId,
          phoneNumber: values[name].phoneNumber,
          channel: "sms",
        }).unwrap();
        setObjectOtpValidation(values[name] as PhoneOtpObject);
        setOpenOtpModal(true);
        setOtpStatus("otp-not-validated");
        toast.success(__(messages.codeSuccess));
      } catch (error) {
        const Error = error as {
          status: number;
          data: {
            messages: string[];
          };
        };

        if (
          Error &&
          Error.status === 400 &&
          Error.data &&
          Error.data.messages[0] === "The Phone number is already valid"
        ) {
          form.setFieldValue(name, {
            ...values[name],
            otp: true,
          });
          setOtpStatus("otp-validated");
          toast.success(__(messages.validPhoneNumber));
        } else {
          toast.error(__(messages.codeError));
        }
      }
    }
  };

  const changeValue = (phone: string, code: string) => {
    if (phone) {
      const newPhone = phone.startsWith("0") ? code + phone.slice(1) : code + phone;
      form.setFieldValue(
        name,
        shouldValidated
          ? {
              ...values[name],
              otp: false,
              phoneNumber: newPhone,
            }
          : newPhone
      );
      setOtpStatus("not-validated");
    } else {
      form.setFieldValue(
        name,
        shouldValidated
          ? {
              ...values[name],
              otp: false,
              phoneNumber: "",
            }
          : ""
      );
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const code = event.target.value as string;
    setCountry(code);
    changeValue(phone, code);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value;
    if (/^\d*$/.test(inputNumber)) {
      setPhone(inputNumber);
      changeValue(inputNumber, country);
    }
  };

  const getPhoneNumberWithoutCountryCode = (phoneNumber: string, countryCode: string) => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode as CountryCode);

      return parsedPhoneNumber.nationalNumber;
      //return parsedPhoneNumber.format("NATIONAL", { nationalPrefix: false });
    } catch {
      return null;
    }
  };

  const getCodeIfStartsWithCountryCode = (phone: string) => {
    const dialCode: any[] = [];
    for (const country of countriesData) {
      if (phone.startsWith(country.dial_code)) {
        dialCode.push(country);
      }
    }
    const sortedDialCode = dialCode.sort((a, b) => b.dial_code.length - a.dial_code.length);

    for (const country of sortedDialCode) {
      if (phone.startsWith(country.dial_code)) {
        return country;
      }
    }
    return null;
  };

  useEffect(() => {
    if (values[name] && isInitialValue) {
      if (shouldValidated) {
        setOtpStatus(values[name].otp ? "otp-validated" : "otp-not-validated");
      }

      const value = shouldValidated ? values[name].phoneNumber : values[name];
      const phoneValue =
        value.startsWith("0") || value.startsWith("+") || !value ? value : "+" + value;
      if (phoneValue) {
        const countryCode = getCodeIfStartsWithCountryCode(phoneValue);
        if (countryCode) {
          setCountry(countryCode.dial_code);
          const nationalNumber = getPhoneNumberWithoutCountryCode(phoneValue, countryCode.code);
          if (nationalNumber) {
            setPhone(nationalNumber);
            form.setFieldValue(
              name,
              shouldValidated
                ? {
                    otp: values[name].otp,
                    employeeId: values[name].employeeId,
                    phoneNumber: countryCode.dial_code + nationalNumber,
                  }
                : countryCode.dial_code + nationalNumber
            );
          }
        } else {
          phoneValue.startsWith("0") ? setPhone(phoneValue.slice(1)) : setPhone(phoneValue);
          form.setFieldValue(
            name,
            shouldValidated
              ? {
                  otp: values[name].otp,
                  employeeId: values[name].employeeId,
                  phoneNumber: country + phoneValue,
                }
              : country + phoneValue
          );
        }
      }
    }
    setIsInitialValue(false);
  }, [values[name]]);

  useEffect(() => {
    if (objectOtpValidation && otpStatus !== "not-validated") {
      form.setFieldValue(name, {
        ...values[name],
        otp: objectOtpValidation.otp,
      });
      setOtpStatus(objectOtpValidation.otp ? "otp-validated" : "otp-not-validated");
    }
  }, [objectOtpValidation]);

  const toggleOtpModal = useCallback(() => {
    setOpenOtpModal(prev => !prev);
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "start", gap: "15px" }}>
        <FormControl size={"small"} sx={{ borderRadius: "10px 0px 0px 10px" }}>
          <InputLabel id="demo-simple-select-label" size={"small"} sx={{ fontWeight: 600 }}>
            <>
              {__(messages.code)}
              <Tooltip title={__(messages.countryPhoneCode)} arrow>
                <FontAwesomeIcon
                  color={theme.palette.primary.main}
                  icon={faCircleInfo}
                  size="xs"
                  style={{ margin: "4px 0px 0px 4px" }}
                />
              </Tooltip>
            </>
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={country}
            label="Country"
            onChange={handleChange}
            sx={{
              width: "70px",
              maxWidth: "70px",
              "& .MuiTypography-root.MuiTypography-subtitle1": {
                fontSize: "12px",
                fontWeight: 400,
                color: theme.palette.error.main,
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            renderValue={selected => {
              const flag = countriesData.find(x => x.dial_code === selected)?.flag as string;
              return countryCodeToFlagImage(flag);
            }}
          >
            {countriesData.map((country: any, index) => (
              <MenuItem
                key={index}
                value={country.dial_code}
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ marginRight: "10px" }}>{countryCodeToFlagImage(country.flag)}</span>
                {country.name}
                <Typography style={{ marginLeft: "10px", color: "gray" }}>
                  {country.dial_code}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          name={name}
          label={labelText}
          type={"tel"}
          size={"small"}
          value={phone}
          placeholder={placeholder}
          error={Boolean(form.errors[name])}
          helperText={
            form.errors[name] && (
              <>
                {typeof values[name] === "string"
                  ? form.errors[name]
                  : getCustomPhoneError(form.errors, name, "phoneNumber")}
              </>
            )
          }
          onChange={handlePhoneNumberChange}
          sx={{
            width: "calc(100% - 70px)",
            maxWidth: "calc(100% - 70px)",
            "& .MuiTypography-root.MuiTypography-subtitle1": {
              fontSize: "12px",
              fontWeight: 400,
              color: theme.palette.error.main,
            },
          }}
          InputLabelProps={{
            shrink: attributes.placeholder,
            sx: { fontWeight: 600 },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {shouldValidated &&
                !Boolean(
                  form.errors[name] && getCustomPhoneError(form.errors, name, "phoneNumber")
                ) &&
                otpStatus !== "validated" ? (
                  openOtpModal || isSendingCode ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <Tooltip
                      title={
                        otpStatus === "otp-not-validated"
                          ? __(messages.otpNotValidated)
                          : otpStatus === "otp-validated"
                          ? __(messages.otpValidated)
                          : __(messages.notValidated)
                      }
                      arrow
                    >
                      <ValidationIcon
                        icon={
                          otpStatus === "otp-not-validated"
                            ? faCircleXmark
                            : otpStatus === "otp-validated"
                            ? faCheckCircle
                            : faCircleInfo
                        }
                        className={
                          otpStatus === "otp-not-validated"
                            ? classesIcon.danger
                            : otpStatus === "otp-validated"
                            ? classesIcon.success
                            : classesIcon.warning
                        }
                        onClick={handleClickOtpValidation}
                      />
                    </Tooltip>
                  )
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {objectOtpValidation && (
        <PhoneOtpModal
          open={openOtpModal}
          otpPhoneObject={objectOtpValidation}
          setOtpPhoneObject={setObjectOtpValidation}
          handleClose={toggleOtpModal}
        />
      )}
    </>
  );
};

export default PhoneCountryInput;
