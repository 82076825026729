import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton as MuiIconButton, TextField, Typography } from "@mui/material";
import theme from "@/theme/theme";


export const CloseIcon = styled(FontAwesomeIcon)({
  ["&"]: {
    fontSize: "10px",
    color: "white",
  },
});

export const IconButton = styled(MuiIconButton)({
  ["&"]: {
    position: "absolute",
    right: "15px",
    top: "15px",
    background: "#BEBEBE",
    height: "15px",
    width: "15.2px",
    [`&:hover`]: {
      backgroundColor: "#d8d7d7",
      color: "white",
      "& *": {
        color: "white",
      },
    },
  },
});

export const Title = styled(Typography)({
  ["&"]: {
    fontWeight: "bold",
    color: "black",
    marginBottom: "15px",
  },
});

export const Description = styled(Typography)({
  ["&"]: {
    color: "black",
  },
});

export const Phone = styled("span")({
  ["&"]: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
});

export const Code = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
});

export const CodeTextField = styled(TextField)({
  ["&"]: {
    width: "65px",
    height: "60px",
    ".MuiInputBase-input": {
      fontSize: "24px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  },
});


export const ResendCodeBox = styled(Box)({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0px 40px 0px",
  },
});

export const ResendCode = styled("span")<{ disabled?: boolean }>(({ disabled }) => ({
  ["&"]: {
    color: disabled ? 'grey' :theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: disabled ? "default" : "pointer",
  },
}));
