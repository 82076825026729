import { UserRepository } from "@amityco/ts-sdk";
import useLiveObject from "./useAmityLiveObject";

export default function useUser(userId: string) {
  const {
    data: user,
    error,
    initialLoading: loading,
  } = useLiveObject({
    fetcher: UserRepository.getUser,
    params: userId,
  });
  return { user, error, loading };
}
