import { api } from "@/services/api";
import searchActivityDomainEndpoint from "./search-activity-domain.ts";
import searchActivityDomainStatisticsEndpoint from "./search-activity-domain-statistics.ts";

export const activityDomainApi = api.injectEndpoints({
  endpoints: build => ({
    searchActivityDomain: searchActivityDomainEndpoint(build),
    searchActivityDomainStatistics: searchActivityDomainStatisticsEndpoint(build),
  }),
});

export const { useSearchActivityDomainQuery, useSearchActivityDomainStatisticsQuery } =
  activityDomainApi;
