import { ApiEndpointBuilder } from "@/services/api/types";

const getEmployeeNovuSignature = (builder: ApiEndpointBuilder) => {
  return builder.query<string, void>({
    query: () => ({
      url: `/enterpriseemployees/notifications/signature`,
      method: "GET",
    }),
  });
};
export default getEmployeeNovuSignature;
