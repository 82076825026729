import { defineMessages } from "react-intl";

export const scope = "timer.component";

export default defineMessages({
  expired: {
    id: `${scope}.expired`,
    defaultMessage: "Votre code a expiré. Cliquez sur le bouton 'Renvoyer' pour envoyer un autre code.",
  },
});
