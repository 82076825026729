import { AbilityBuilder } from "@casl/ability";
import { appAbility } from ".";
import { ProfileResponse } from "@/types/profile";
import { defineCommercialAbility } from "@/casl/commercialAbilities";
import { defineCommunityManagerAbility } from "@/casl/communityManagerAbilities";
import { defineBasicAbility } from "@/casl/basicAbilities";

export const defineAdminAbility = (user: ProfileResponse) => {
  const { can, rules } = new AbilityBuilder(appAbility);

  can(
    ["View", "Create", "Update", "Delete"],
    [
      "Users",
      "Article",
      "Event",
      "Announcement",
      "Comments",
      "PersoLite",
      "Feedback",
      "Kanban",
      "Calendar",
      "CalendarResources",
      "Enterprise",
      "Support",
      "Certificates",
      "Signatures",
      "News",
      "Feedbacks",
    ]
  );

  can("Update", "Feedbacks", { assignedTo: user.id });
  can("Update", "Documents", { permissionType: 1 });
  can(["Update", "Delete"], ["Article", "Event", "Announcement"], { createdBy: user.id });
  can("Interact", "EnterpriseReferents");
  can("View", "Offers");
  can(["View", "Interact"], "Products");

  can("Sign", "Signatures");

  return rules;
};

//Admin && Basic
export const defineAB_Ability = (user: ProfileResponse) => {
  const A_Rules = defineAdminAbility(user);
  const B_Rules = defineBasicAbility(user);

  const combinedRules = [...B_Rules, ...A_Rules];

  return combinedRules;
};

//Admin && Commercial
export const defineAC_Ability = (user: ProfileResponse) => {
  const A_Rules = defineAdminAbility(user);
  const C_Rules = defineCommercialAbility(user);

  const combinedRules = [...C_Rules, ...A_Rules];

  return combinedRules;
};

//Admin && Manager
export const defineAM_Ability = (user: ProfileResponse) => {
  const A_Rules = defineAdminAbility(user);
  const M_Rules = defineCommunityManagerAbility(user);

  const combinedRules = [...A_Rules, ...M_Rules];

  return combinedRules;
};

//Admin && Commercial && Basic
export const defineACB_Ability = (user: ProfileResponse) => {
  const AC_Rules = defineAC_Ability(user);
  const B_Rules = defineBasicAbility(user);

  const combinedRules = [...B_Rules, ...AC_Rules];

  return combinedRules;
};

//Admin && Manager && Basic
export const defineAMB_Ability = (user: ProfileResponse) => {
  const AM_Rules = defineAM_Ability(user);
  const B_Rules = defineBasicAbility(user);

  const combinedRules = [...B_Rules, ...AM_Rules];

  return combinedRules;
};

//Admin && Commercial && Manager
export const defineACM_Ability = (user: ProfileResponse) => {
  const AM_Rules = defineAM_Ability(user);
  const C_Rules = defineCommercialAbility(user);

  const combinedRules = [...AM_Rules, ...C_Rules];

  return combinedRules;
};

//Admin && Commercial && Manager && BASIC
export const defineACMB_Ability = (user: ProfileResponse) => {
  const ACM_Rules = defineACM_Ability(user);
  const B_Rules = defineBasicAbility(user);

  const combinedRules = [...B_Rules, ...ACM_Rules];

  return combinedRules;
};

export const A_Ability = (user: ProfileResponse) =>
  new appAbility(defineAdminAbility(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AB_Ability = (user: ProfileResponse) =>
  new appAbility(defineAB_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AC_Ability = (user: ProfileResponse) =>
  new appAbility(defineAC_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AM_Ability = (user: ProfileResponse) =>
  new appAbility(defineAM_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACB_Ability = (user: ProfileResponse) =>
  new appAbility(defineACB_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AMB_Ability = (user: ProfileResponse) =>
  new appAbility(defineAMB_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACM_Ability = (user: ProfileResponse) =>
  new appAbility(defineACM_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACMB_Ability = (user: ProfileResponse) =>
  new appAbility(defineACMB_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const commercialAdminAbility = (user: ProfileResponse) =>
  new appAbility(defineAC_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });
