import { useGetCurrentProfileQuery } from "@/store/profile/endpoints";
import { ChannelRepository } from "@amityco/ts-sdk";

export default function useCreateChannel() {
  const { data: currentUser } = useGetCurrentProfileQuery();
  const newChannel = {
    type: "conversation" as Amity.ChannelType,
  };

  const createChannel = async (userId: string[], username: string) => {
    if (currentUser) {
      const { data: channel } = await ChannelRepository.createChannel({
        ...newChannel,
        displayName: `${currentUser.firstName} ${currentUser.lastName},${username}`,
        userIds: userId,
      });

      return channel;
    }
  };

  return { createChannel };
}
