import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantType } from "./endpoints/tenants";

export interface TenantsType {
  shouldSelectTenant: boolean;
  tenantId?: string;
  tenantsList?: TenantType[];
  userId?: string;
}

const initialState: TenantsType = {
  shouldSelectTenant: false,
  tenantId: undefined,
  tenantsList: undefined,
  userId: undefined,
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    shouldSelectTenantAction: (
      state,
      action: PayloadAction<{ shouldSelectTenant: boolean; tenantsList?: TenantType[] }>
    ) => {
      const { shouldSelectTenant, tenantsList } = action.payload;
      state.shouldSelectTenant = shouldSelectTenant;
      state.tenantsList = tenantsList;
    },
    selectTenant: (state, action: PayloadAction<{ tenantId: string; userId?: string }>) => {
      const { tenantId, userId } = action.payload;
      state.tenantId = tenantId;
      if (userId) {
        state.userId = userId;
      }
    },
    resetTenant: state => {
      state.shouldSelectTenant = false;
      state.tenantId = undefined;
      state.tenantsList = undefined;
      state.userId = undefined;
    },
  },
});

export const { shouldSelectTenantAction, selectTenant, resetTenant } = tenantsSlice.actions;

export default tenantsSlice.reducer;
