import { UAParser } from "ua-parser-js";

const useCheckDevice = () => {
  const unwantedDevices = ["mobile"];
  const unwantedBrowsers = ["IE"];
  const parser = new UAParser();
  const parserResults = parser.getResult();
  const os = parserResults.os.name?.toLowerCase() || "";

  return {
    isProhibited:
      unwantedDevices.includes(parserResults.device.type as string) ||
      unwantedBrowsers.includes(parserResults.browser.name as string),
    isMacOS: os === "mac os",
  };
};

export default useCheckDevice;
