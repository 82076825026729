import HorizontalList from '@/components/list/horizontal-list';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { networkIla26Endpoint } from '../pagesListSideBar';

const NetworkNavbarLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(location.pathname === networkIla26Endpoint) navigate(`${networkIla26Endpoint}/my-feeds`)
  }, [location])
  return (
    <Box sx={{ overflow: 'hidden', height: 'calc(100dvh - 70px)' }}>
      <HorizontalList />
      <Box sx={{ height: 'calc(100dvh - 180px)', overflow: 'auto', paddingInline: '20px' }}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default NetworkNavbarLayout