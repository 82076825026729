import { api } from "@/services/api";
import getCheckoutUrl, { getStripePortalUrl, getTenant, getTenantsList } from "./tenants";

export const tenantsApi = api.injectEndpoints({
  endpoints: build => ({
    getTenantsList: getTenantsList(build),
    getTenant: getTenant(build),
    getStripePortalUrl: getStripePortalUrl(build),
    getCheckoutUrl: getCheckoutUrl(build),
  }),
});

export const {
  useGetTenantsListQuery,
  useGetTenantQuery,
  useGetStripePortalUrlQuery,
  useLazyGetStripePortalUrlQuery,
  useGetCheckoutUrlQuery,
  useLazyGetCheckoutUrlQuery,
} = tenantsApi;
