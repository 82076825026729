import { createRef, FC, useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import messages from "./messages";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import {
  CloseIcon,
  Code,
  CodeTextField,
  Description,
  IconButton,
  Phone,
  ResendCode,
  ResendCodeBox,
  Title,
} from "./styles";
import TimerComponent from "@components/phone-country-input/opt-modal/timer-component";
import { PhoneOtpObject } from "@components/phone-country-input/types";
import {
  useEmployeeOtpRequestMutation,
  useEmployeeValidateOtpCodeMutation,
} from "@/store/employee/endpoints";
import toast from "react-hot-toast";

interface Props {
  open: boolean;
  otpPhoneObject: PhoneOtpObject;
  setOtpPhoneObject: (value: PhoneOtpObject) => void;

  handleClose(): void;
}

const PhoneOtpModal: FC<Props> = props => {
  const { formatMessage: __ } = useIntl();
  const { open, otpPhoneObject, setOtpPhoneObject, handleClose } = props;
  const [sendCode, { isLoading: isSendingCode }] = useEmployeeOtpRequestMutation();
  const [confirmCode, { isLoading: isCheckCode }] = useEmployeeValidateOtpCodeMutation();
  const otpNbValidationField = 6;
  const [otpCode, setOtpCode] = useState(new Array(otpNbValidationField).fill(""));
  const [otpCodeExpired, setOtpCodeExpired] = useState<boolean>(false);
  const [resendOtpCode, setResendOtpCode] = useState<boolean>(false);
  const otpFieldsReference = useRef<Array<any>>(new Array(6).fill(createRef()));

  const handleChange = (value: string, index: number) => {
    if (/^\d*$/.test(value)) {
      const newValue = [...otpCode];
      newValue[index] = value;
      setOtpCode(newValue);
    }
  };

  const handleResendClick = async () => {
    if (!isSendingCode) {
      try {
        await sendCode({
          employeeId: otpPhoneObject.employeeId,
          phoneNumber: otpPhoneObject.phoneNumber,
          channel: "sms",
        }).unwrap();
        otpFieldsReference.current[0].focus();
        setResendOtpCode(true);
        setOtpCodeExpired(false);
        toast.success(__(messages.codeSuccess));
      } catch (error) {
        toast.error(__(messages.codeError));
      }
    }
  };

  const handleStartValidation = async () => {
    const code = otpCode.join("");
    try {
      await confirmCode({
        code: code,
        employeeId: otpPhoneObject.employeeId,
        phoneNumber: otpPhoneObject.phoneNumber,
      }).unwrap();
      setOtpPhoneObject({
        ...otpPhoneObject,
        otp: true,
      });
      handleClose();
      toast.success(__(messages.validateCodeSuccess));
    } catch (error) {
      toast.error(__(messages.validateCodeError));
    }
  };

  const handleBackspaceAndEnter = (e: any, index: number) => {
    if (
      (e.key === "Enter" || (e.key >= "0" && e.key <= "9")) &&
      e.target.value &&
      index < otpNbValidationField - 1
    ) {
      otpFieldsReference.current[index + 1].focus();
    }
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpFieldsReference.current[index - 1].focus();
      otpFieldsReference.current[index - 1].select();
    }
  };

  useEffect(() => {
    if (open) {
      const focusTimeout = setTimeout(() => {
        if (otpFieldsReference.current[0]) {
          otpFieldsReference.current[0].focus();
        }
      }, 100);

      return () => clearTimeout(focusTimeout);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOtpCodeExpired(false);
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setOtpCodeExpired(false);
            handleClose();
          }}
        >
          <CloseIcon icon={faClose} />
        </IconButton>
        <DialogContent sx={{ paddingX: "30px", width: "500px" }}>
          <Title id="modal-modal-title" variant="h3">
            {__(messages.title)}
          </Title>
          <Description variant="body1">
            {__(messages.description)}
            <Phone> {otpPhoneObject?.phoneNumber}</Phone> .
          </Description>
          <Code>
            {otpCode.map((digit, index) => (
              <CodeTextField
                key={index}
                value={digit}
                onChange={e => handleChange(e.target.value, index)}
                onKeyDown={e => handleBackspaceAndEnter(e, index)}
                inputProps={{ maxLength: 1 }}
                inputRef={reference => {
                  otpFieldsReference.current[index] = reference;
                }}
              />
            ))}
          </Code>
          <ResendCodeBox>
            <Typography>
              {__(messages.timer)}
              <TimerComponent
                duration={1}
                resend={resendOtpCode}
                setResend={setResendOtpCode}
                expired={otpCodeExpired}
                setExpired={setOtpCodeExpired}
              />
            </Typography>
            <Typography>
              {__(messages.receiveCode)}
              <ResendCode
                disabled={!otpCodeExpired}
                onClick={otpCodeExpired ? handleResendClick : undefined}
              >
                {__(messages.resendCode)}
              </ResendCode>
            </Typography>
          </ResendCodeBox>
          <Button
            style={{ textTransform: "none", fontSize: "18px" }}
            variant={"outlined"}
            disabled={
              otpCodeExpired ||
              isSendingCode ||
              isCheckCode ||
              otpCode.join("").length < otpNbValidationField
            }
            onClick={handleStartValidation}
            fullWidth
          >
            {isCheckCode && <CircularProgress size="1rem" />}
            {__(messages.validButton)}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PhoneOtpModal;
