import { api } from "@/services/api";
import addEnterpriseStoreEndpoint from "./add-enterprise";
import deleteEnterpriseEndpoint from "./delete-enterprise";
import getBusinessMapStatisticsEndpoint from "./get-business-map-statistics";
import getCurrentEnterpriseEndpoint from "./get-current-entreprise";
import getEnterpriseByIdEndpoint from "./get-entreprise-by-id";
import searchEnterprisesEndpoint from "./search-enterprise";
import updateEnterpriseEndpoint from "./update-enterprise";
import searchEnterprisesEndpoint_V2 from "./search-enterprise_V2";
import searchEnterprisesMapEndpoint from "./search-enterprises-map";

export const enterprisesApi = api.injectEndpoints({
  endpoints: build => ({
    searchEnterprises: searchEnterprisesEndpoint(build),
    searchEnterprises_V2: searchEnterprisesEndpoint_V2(build), // version with enhanced caching of searchEnterprises
    searchEnterprisesMap: searchEnterprisesMapEndpoint(build),
    addEnterpriseStore: addEnterpriseStoreEndpoint(build),
    getEnterpriseById: getEnterpriseByIdEndpoint(build),
    updateEnterprise: updateEnterpriseEndpoint(build),
    deleteEnterprise: deleteEnterpriseEndpoint(build),
    getCurrentEnterprise: getCurrentEnterpriseEndpoint(build),
    getBusinessMapStatistics: getBusinessMapStatisticsEndpoint(build),
  }),
});

export const {
  useSearchEnterprisesQuery,
  useLazySearchEnterprises_V2Query,
  useAddEnterpriseStoreMutation,
  useGetEnterpriseByIdQuery,
  useGetCurrentEnterpriseQuery,
  useUpdateEnterpriseMutation,
  useDeleteEnterpriseMutation,
  useGetBusinessMapStatisticsQuery,
  useLazyGetBusinessMapStatisticsQuery,
  useLazySearchEnterprisesQuery,
  useSearchEnterprisesMapQuery,
  useLazySearchEnterprisesMapQuery,
} = enterprisesApi;
