import { ApiEndpointBuilder } from "@/services/api/types";


const employeeValidateOtpCode = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, employeeOtpRequest>({
    query: ({ employeeId, ...otpDetails }) => ({
      url: `/enterpriseemployees/${employeeId}/validate-otp-code`,
      method: "PUT",
      body: {
        ...otpDetails,
      },
    }),
  });
};

export type employeeOtpRequest = {
  code: string,
  employeeId: string,
  phoneNumber: string,
}

export default employeeValidateOtpCode;
