import { Avatar, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const classes = {
  customIcon: "custom-icon",
  defaultColor: "default-icon",
  dividerColor: "divider-icon",
  addMarginIcon: "add-margin-icon",
  primaryColor: "primary-icon",
  whiteColor: "white-icon",
  warningColor: "warning-icon",
  circleIcon: "circle-icon",
};

export const BoxIcon = styled("i")(({ theme }) => ({
  [`&.${classes.customIcon} svg`]: {
    fontSize: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
  },
  [`&.${classes.defaultColor}`]: {
    color: theme.palette.text.primary,
  },
  [`&.${classes.primaryColor}`]: {
    color: theme.palette.primary.main,
  },
  [`&.${classes.dividerColor}`]: {
    color: theme.palette.secondary.main,
  },
  [`&.${classes.addMarginIcon}`]: {
    margin: "0 13px",
  },
  [`&.${classes.warningColor}`]: {
    color: theme.palette.warning.dark,
  },
  [`&.${classes.whiteColor}`]: {
    color: theme.palette.background.default,
  },
}));

export const AvatarCustom = styled(Avatar)(({ theme }) => ({
  [`&`]: {
    bgColor: theme.palette.secondary.main,
    width: "40px",
    height: "39px",
    imageRendering: "-webkit-optimize-contrast",
    borderRadius: "50%",
    cursor: "pointer",
    border: "2px solid #fff",
  },
}));


export const Loading = styled(Stack)({
  ["&"]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    px: "30%",
  },
});
