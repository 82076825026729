import { createSlice } from '@reduxjs/toolkit';

interface LibraryState {
    
}

const initialState: LibraryState = {

};

const librarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {},
});

export default librarySlice.reducer;