import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import theme from "@/theme/theme";

const DeleteMenu = ({
  deleteAction,
  deleteLabel,
}: {
  deleteAction: () => void;
  deleteLabel: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(v => (v ? null : event.currentTarget));
  };

  return (
    <>
      <IconButton
        color="inherit"
        size="small"
        onClick={e => {
          e.stopPropagation();
          toggleMenu(e);
        }}
        sx={{
          width : "25px",
          height : "25px",
          p : 0.5
        }}
      >
        <FontAwesomeIcon
          icon={faEllipsis}
          style={{
            cursor: "pointer",
            fontSize: "14px"
          }}
        />
      </IconButton>
      <Menu
        open={open}
        onClose={toggleMenu}
        anchorEl={anchorEl}
        elevation={1}
        MenuListProps={{
          sx: {
            padding: 0,
            borderRadius: "6px",
          },
        }}
        onClick={e => {
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: "8px",
        }}
      >
        <MenuItem
          onClick={e => {
            toggleMenu(e);
            deleteAction();
          }}
          sx={{
            display: "flex",
            alignItems: "start",
            gap: ".7rem",
          }}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{
              color: theme.palette.primary.main,
            }}
          />

          <Typography variant="subtitle2">{deleteLabel}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default DeleteMenu;
