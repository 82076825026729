import { defineMessages } from "react-intl";

const scope = "myNetwork";
export default defineMessages({
  Invitations: {
    id: `${scope}.Invitations`,
    defaultMessage: "Mes Invitations",
  },
  Connections: {
    id: `${scope}.Connections`,
    defaultMessage: "Mes Connections",
  },
  Search: {
    id: `${scope}.Search`,
    defaultMessage: "Rechercher",
  },
  ConfirmationContent: {
    id: `${scope}.ConfirmationContent`,
    defaultMessage: "Etes-vous sûr de vouloir supprimer cette connexion ?",
  },
  Message: {
    id: `${scope}.Message`,
    defaultMessage: "Message",
  },
  Delete: {
    id: `${scope}.Delete`,
    defaultMessage: "Supprimer",
  },
  Accept: {
    id: `${scope}.Accept`,
    defaultMessage: "Accepter",
  },

  ConfirmationRejectContent: {
    id: `${scope}.ConfirmationRejectContent`,
    defaultMessage: "Etes-vous sûr de vouloir rejeter cette invitation ?",
  },

  Reject: {
    id: `${scope}.rejectInvitation`,
    defaultMessage: "Rejeter",
  },
});
