import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import useRedirectErrorPageHook from "./redirectErrorPageHook.ts";

const useSafeQuery = <TQueryFn extends UseQuery<any>>(
    useQuery: TQueryFn,
    queryArgs: Parameters<TQueryFn>[0],
    options?: Parameters<TQueryFn>[1]
)  => {
  const result = useQuery(queryArgs, options);

  useRedirectErrorPageHook(result.error);

  return result;
};

export default useSafeQuery;
