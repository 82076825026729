import { ApiEndpointBuilder } from "@/services/api/types";
import {
  Address,
  Enterprise,
  EnterpriseContact,
  EnterpriseSize,
  LegalInformation,
  SocialMedia,
  Video,
} from "@/types/store/profile";
import { EnterpriseCategory } from "@/types/store/store-category";
import { EnterpriseSubActivity } from "@/store/market-place-categories/endpoints/search-enterprise-sub-activities";
import { enterprisesApi } from ".";
import _ from "lodash";
const updateEnterprise = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Enterprise, Request>({
    query: request => ({
      url: `/enterprises/${request.id}`,
      method: "PUT",
      body: request,
    }),

    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      const patchResult = dispatch(
        enterprisesApi.util.updateQueryData("getCurrentEnterprise", {}, draft => {
          const merged = _.merge(_.cloneDeep(draft), _.cloneDeep(args));
          return merged;
        })
      );

      try {
        await queryFulfilled;
      } catch (error) {
        console.error(error);
        patchResult.undo();
      }
    },
    invalidatesTags: ["Enterprise"],
  });
};

type Request = {
  id?: string;
  title?: string;
  quote?: string;
  description?: string;
  date?: Date | string;
  presentation?: string;
  address?: Address;
  contact?: EnterpriseContact;
  legalInformation?: LegalInformation;
  socialMedia?: SocialMedia[];
  enterpriseSize?: EnterpriseSize;
  enterpriseVideos?: Video[];
  isVerified?: boolean;
  arePricesVisible?: boolean;
  enterpriseCategories?: EnterpriseCategory[];
  enterpriseSubActivities?: EnterpriseSubActivity[];
  enterpriseActivityZoneIds?: string[];
  activityDomainId?: string;
  enterpriseSpecialitiesIds?: string[];
  enterpriseSubActivityDomainId?: string;
};

export default updateEnterprise;
