import { ApiEndpointBuilder } from "@/services/api/types";

const addEmployeeProfilePicture = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Files, Request>({
    query: ({ request, entityId }) => ({
      url: `/enterprisefiles/profile/${entityId}`,
      method: "POST",
      body: request[0],
    }),
    invalidatesTags: ["Employee", "currentEmployee"],
  });
};

type Request = {
  entityId: string;
  request: RequestFile[];
};

export interface RequestFile {
  name: string;
  extension: string;
  data: string;
  assetType: number;
}

export interface Files {
  id?: string;
  name: string;
  type: string;
  assetType: number;
  azureBlobUri?: string;
  contentItemId?: string;
}

export default addEmployeeProfilePicture;
