import { api } from "@/services/api";
import getCurrentProfile from "./getCurrentProfile";

export const profileApi = api.injectEndpoints({
  endpoints: build => ({
    getCurrentProfile: getCurrentProfile(build),
  }),
});

export const { useGetCurrentProfileQuery } = profileApi;
