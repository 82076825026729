import {
  ESidebarMenuColors,
  ISidebarMenuList,
  networkIla26Endpoint,
} from "@/pages/layout/pagesListSideBar";
import theme from "@/theme/theme";
import { Divider, Stack } from "@mui/material";
import { useIntl } from "react-intl";
import HorizontalListItem from "./horizontal-list-item";
import "@/assets/scripts/user-guiding.js";
import annuaireBold from "@assets/icons/annuaire-bold.svg";
import marketPlace from "@assets/icons/annuaire-entreprise.svg";
import inbox from "@assets/icons/inbox.svg";
import myNetwork from "@assets/icons/profile-2user.svg";
import serviceOffer from "@assets/icons/services-offer.svg";
import messages from "@/pages/layout/messages";
import company from "@assets/icons/company.svg";
import networkIlan from "@assets/icons/network-ilan-26.svg";
import home from "@assets/icons/networkHome.svg";
import { useMemo } from "react";
import useUnreadCount from "@/hooks/amity/useUnreadCount";
import useFollowers from "@/hooks/amity/useFollowers";

const HorizontalList = () => {
  const { formatMessage: __, locale } = useIntl();
  const unreadMessagesCount = useUnreadCount();
  const { followers: connectionRequests } = useFollowers("pending");

  const sidebarMenuList: Omit<ISidebarMenuList, "fixedItems"> = {
    interactiveItems: [
      {
        title: __(messages.network),
        primaryColor: ESidebarMenuColors.purple,
        secondaryColor: ESidebarMenuColors.darkPurple,
        isAccordion: false,
        iconName: networkIlan,
        link: `${networkIla26Endpoint}`,
        items: [
          {
            title: __(messages.home),
            iconName: home,
            link: `${networkIla26Endpoint}/my-feeds`,
          },
          {
            title: __(messages.myNetwork),
            iconName: myNetwork,
            link: `${networkIla26Endpoint}/my-network`,
          },
          {
            title: __(messages.inbox),
            iconName: inbox,
            link: `${networkIla26Endpoint}/inbox`,
          },
          {
            title: __(messages.directoryPro),
            link: `${networkIla26Endpoint}/shared-resources`,
            iconName: annuaireBold,
          },
          {
            title: __(messages.directoryCompany),
            link: `${networkIla26Endpoint}/store`,
            iconName: company,
          },
          {
            title: __(messages.serviceOffer),
            link: `${networkIla26Endpoint}/offers`,
            iconName: serviceOffer,
          },
          {
            title: __(messages.productsCatalog),
            link: `${networkIla26Endpoint}/catalog`,
            iconName: marketPlace,
          },
        ],
        isTop: true,
      },
    ],
  };

  const navbarItems = useMemo(() => sidebarMenuList.interactiveItems[0].items, [locale]);

  return (
    <Stack
      direction="row"
      sx={{
        p: "15px 27px",
        m: "19px 19px 15px 19px",
        borderRadius: 2,
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {navbarItems.map((item, index) => (
        <>
          <HorizontalListItem
            key={index}
            title={item.title}
            link={item.link}
            matchFullLink={item.matchFullLink}
            iconName={item.iconName}
            notifCount={
              item.link.includes("inbox")
                ? unreadMessagesCount
                : item.link.includes("my-network")
                ? connectionRequests.length
                : undefined
            }
          />
          {index !== navbarItems.length - 1 && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ marginRight: "20px" }}
            />
          )}
        </>
      ))}
    </Stack>
  );
};

export default HorizontalList;
