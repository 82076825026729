import { defineMessages } from "react-intl";

export const common = "common";

export default defineMessages({
  resetBtn: {
    id: `${common}.resetBtn`,
    defaultMessage: "Réinitialiser",
  },
  saveBtn: {
    id: `${common}.saveBtn`,
    defaultMessage: "Enregistrer",
  },
  startTypingYour: {
    id: `${common}.startTypingYour`,
    defaultMessage: "Saisissez votre",
  },
  select: {
    id: `${common}.select`,
    defaultMessage: "sélectionnez",
  },
  now: {
    id: `${common}.now`,
    defaultMessage: "à l'instant",
  },
});
