import { useState, useRef, useLayoutEffect } from "react";
type props = { margin?: number };

const useDimensions = ({ margin }: props) => {
  const safeSpace = useRef<number>(88);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const navBarHeight = document.querySelectorAll(".add-border-bottom")[0]?.clientHeight;

  const headerHeight = document.querySelectorAll("header")[0]?.clientHeight;

  const [navbar, setNavbar] = useState<number>(navBarHeight);
  const [header, setHeader] = useState<number>(navBarHeight);
  const [safeHeight, setSafeHeight] = useState<number>(
    windowHeight - (navBarHeight || 0) - headerHeight - safeSpace.current - (margin || 0)
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setNavbar(document.querySelectorAll(".add-border-bottom")[0]?.clientHeight);
      setHeader(document.querySelectorAll("header")[0]?.clientHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    setSafeHeight(
      windowHeight - (navBarHeight || 0) - headerHeight - safeSpace.current - (margin || 0)
    );
  }, [windowHeight, navbar, header, margin, headerHeight, navBarHeight]);

  return { windowHeight, navbar, header, safeHeight };
};

export default useDimensions;
