import { Chip, chipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';

export const CustomChip = styled(Chip)(({ theme }) => ({
  ['&']: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginX: '2px',
    [`& .${chipClasses.deleteIcon}`]: {
      color: "white",
    }
  },
}));
