import { ApiEndpointBuilder } from '@/services/api/types';

const getEmployeeReferentById = (builder: ApiEndpointBuilder) => {
  return builder.query<any, any>({
    query: (id) => ({
      url: `/enterpriseemployees/${id}/validation`,
      method: 'GET',
    }),
  });
};
export default getEmployeeReferentById;
