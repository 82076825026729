import {ApiEndpointBuilder} from '@/services/api/types';
import {
    Address,
    Enterprise,
    EnterpriseContact,
    EnterpriseSize,
    LegalInformation,
    SocialMedia, Video
} from "@/types/store/profile";
import {EnterpriseCategory} from "@/types/store/store-category";


const addEnterpriseStore = (builder: ApiEndpointBuilder) => {
    return builder.mutation<Enterprise, Request>({
        query: ({
                    title,
                    quote,
                    description,
                    date,
                    presentation,
                    address,
                    contact,
                    legalInformation,
                    socialMedia,
                    enterpriseSize,
                    enterpriseVideos,
            enterpriseCategories,
                }) => ({
            url: '/enterprises',
            method: 'POST',
            body: {
                title,
                quote,
                description,
                presentation,
                address,
                contact,
                legalInformation,
                socialMedia,
                enterpriseSize,
                enterpriseVideos,
                enterpriseCategories,
                date,
            },
        }),
    });
};

type Request = {
    title?: string;
    quote?: string;
    description?: string;
    date?: Date;
    presentation?: string;
    address?: Address;
    contact?: EnterpriseContact;
    legalInformation?: LegalInformation;
    socialMedia?: SocialMedia[];
    enterpriseSize?: EnterpriseSize;
    enterpriseVideos?: Video[];
    enterpriseCategories?: EnterpriseCategory[];
};

export default addEnterpriseStore;
