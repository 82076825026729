import { ApiEndpointBuilder } from "@/services/api/types";
import { ProfileResponse } from "@/types/profile";

const getCurrentProfile = (builder: ApiEndpointBuilder) => {
  return builder.query<ProfileResponse, void>({
    query: () => ({
      url: `/personal/profile`,
      method: "GET",
    }),
    transformResponse: (userData: ProfileResponse) => ({
      ...userData,
      picture: `${userData?.picture}?t=${new Date().getTime()}`,
    }),
    providesTags: ["currentEmployee"],
  });
};
export default getCurrentProfile;
