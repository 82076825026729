import { IKanbanTask } from "@/pages/tasks-manager/kanban/kanban-task/kanban-task.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPagination {
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  pageSize?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}


export interface ITaskToUpdate {
  task?: Partial<IKanbanTask>;
  subTask?: Partial<IKanbanTask>;
  isDrawerOpen?: boolean;
  parent?: any;
}

export interface IKanbanFilters {
  searchTasks: string;
  priority: string | null;
  userAssigneeId: string;
}
interface IKanbanData {
  kanbanData: IKanbanTask[];
  currentTaskToUpdate?: Partial<ITaskToUpdate>;
  currentSubTaskToUpdate?: Partial<ITaskToUpdate>;
  filters: IKanbanFilters;
  [key: string]: IPagination | IKanbanTask[] | Partial<ITaskToUpdate> | IKanbanFilters | undefined;
}

const initialState: IKanbanData = {
  kanbanData: [],
  filters: {
    searchTasks: '',
    priority: null,
    userAssigneeId: ''
  }
};

const KanbanSlice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    resetKanbanData () {
      return initialState
    },
    resetTaskToUpdate(state) {
      const newState = {
        ...state,
        currentTaskToUpdate: {
          task: undefined,
          isDrawerOpen: false,
        },
        currentSubTaskToUpdate: {
          task: undefined,
          isDrawerOpen: false,
        },
      };
      return newState;
    },
    setTaskToUpdate(state, action: PayloadAction<{ task: IKanbanTask }>) {
      const { task } = action.payload;
      return {
        ...state,
        currentSubTaskToUpdate: {
          task: undefined,
          isDrawerOpen: true,
        },
        currentTaskToUpdate: {
          task,
          isDrawerOpen: true,
        },
      }
    },
    setSubTaskToUpdate (state, action: PayloadAction<{ subtask: IKanbanTask }>) {
      const { subtask } = action.payload;
      return {
        ...state,
        currentSubTaskToUpdate: {
          task: subtask,
          isDrawerOpen: true,
        },

      }
    },
    resetKanbanFilters (state) {
      return {
        ...state,
        filters: initialState.filters
      }
    },
    setTasksNameFilter (state, action: PayloadAction<{ searchTasks: string; }>) {
       return ({
        ...state,
        filters: {
          ...state.filters,
          searchTasks: action.payload.searchTasks,
        }
       })
    },
    setTasksPriorityFilter (state, action: PayloadAction<{ priority: string | null; }>) {
       return ({
        ...state,
        filters: {
          ...state.filters,
          priority: action.payload.priority,
        }
       })
    },
    setTasksAssigneeFilter (state, action: PayloadAction<{ userAssigneeId: string }>) {
       return ({
        ...state,
        filters: {
          ...state.filters,
          userAssigneeId: action.payload.userAssigneeId,
        }
       })
    }
  },
});

export const {
  resetKanbanData,
  setTaskToUpdate,
  setSubTaskToUpdate,
  resetTaskToUpdate,
  setTasksNameFilter,
  setTasksPriorityFilter,
  setTasksAssigneeFilter,
  resetKanbanFilters
} = KanbanSlice.actions;
export default KanbanSlice.reducer;
