import { api } from "@/services/api";
import searchSpecialitiesEndpoint from "./search-specialities.ts";

export const specialitiesApi = api.injectEndpoints({
  endpoints: build => ({
    searchSpecialities: searchSpecialitiesEndpoint(build),
  }),
});

export const { useSearchSpecialitiesQuery, useLazySearchSpecialitiesQuery } = specialitiesApi;
