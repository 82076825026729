import { TenantMembership, TenantPlanType, TenantType } from "@/store/tenants/endpoints/tenants";

export type NonEmptyArray<T> = [T, ...T[]];

export const decideMultiTenancy = (
  tenantsList: NonEmptyArray<TenantType>
): {
  shouldSelectTenant: boolean | undefined;
  selectedTenant: TenantType | NonEmptyArray<TenantType> | undefined;
} => {
  if (!tenantsList || tenantsList.length === 0) {
    console.warn("Tenants list is empty or undefined.");
    return { shouldSelectTenant: undefined, selectedTenant: undefined };
  }

  const isBasic = (tenant: TenantType) => tenant.plan === TenantPlanType.BASIC;
  const isBusinessOrEnterprise = (tenant: TenantType) =>
    tenant.plan === TenantPlanType.BUSINESS || tenant.plan === TenantPlanType.ENTERPRISE;

  const ownedBasic = tenantsList.filter(
    tenant => isBasic(tenant) && tenant.membership === TenantMembership.OWNER
  );

  const ownedBusinessOrEnterprise = tenantsList.filter(
    tenant => isBusinessOrEnterprise(tenant) && tenant.membership === TenantMembership.OWNER
  );

  const memberedBusinessOrEnterprise = tenantsList.filter(
    tenant => isBusinessOrEnterprise(tenant) && tenant.membership === TenantMembership.MEMBER
  );

  const userOwnsBusinessOrEnterprise = ownedBusinessOrEnterprise.length > 0;
  const userIsPartOfBusinessOrEnterprise = memberedBusinessOrEnterprise.length > 0;

  // Multiple business or enterprise tenants and owner of Basic
  if (
    (userOwnsBusinessOrEnterprise || userIsPartOfBusinessOrEnterprise) &&
    [...memberedBusinessOrEnterprise, ...ownedBusinessOrEnterprise].length > 1
  ) {
    return {
      shouldSelectTenant: true,
      selectedTenant: [
        ...memberedBusinessOrEnterprise,
        ...ownedBusinessOrEnterprise,
        ...ownedBasic,
      ] as NonEmptyArray<TenantType>,
    };
  }

  // Member of one business or enterprise, but not an owner of business or enterprise
  if (
    userIsPartOfBusinessOrEnterprise &&
    memberedBusinessOrEnterprise.length === 1 &&
    !userOwnsBusinessOrEnterprise
  ) {
    return {
      shouldSelectTenant: false,
      selectedTenant: [memberedBusinessOrEnterprise[0], ...ownedBasic] as NonEmptyArray<TenantType>,
    };
  }

  // Owns one business or enterprise plan + basic owner
  if (userOwnsBusinessOrEnterprise && ownedBusinessOrEnterprise.length === 1) {
    return {
      shouldSelectTenant: false,
      selectedTenant: [ownedBusinessOrEnterprise[0], ...ownedBasic] as NonEmptyArray<TenantType>,
    };
  }

  // Single basic tenant
  if (tenantsList.length === 1 && tenantsList[0].plan === TenantPlanType.BASIC) {
    return {
      shouldSelectTenant: false,
      selectedTenant: tenantsList[0],
    };
  }

  console.warn("No matching case found for tenants list.");
  return { shouldSelectTenant: undefined, selectedTenant: undefined };
};

export const getCurrentTenantInfo = ({
  tenantsList,
  tenantId,
}: {
  tenantsList: NonEmptyArray<TenantType> | undefined;
  tenantId: string | undefined;
}) => {
  const currentTenant = tenantsList?.find(tenant => tenant.id === tenantId);
  if (!tenantsList || !tenantId)
    return {
      id: tenantId,
      isOwner: false,
      isBasic: false,
      isEnterprise: false,
      isBusiness: false,
      currentTenant,
    };
  return {
    id: tenantId,
    isOwner: currentTenant?.membership === TenantMembership.OWNER,
    isBusiness: currentTenant?.plan === TenantPlanType.BUSINESS,
    isBasic: currentTenant?.plan === TenantPlanType.BASIC,
    isEnterprise: currentTenant?.plan === TenantPlanType.ENTERPRISE,
    currentTenant,
  };
};
