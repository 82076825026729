import { ApiEndpointBuilder } from "@/services/api/types";
import { DataResponse } from "@/types/reponse-data";
import { AdvancedFilter } from "@/types/search-request";
import { Enterprise } from "@ilan-types/store/profile";

const searchEnterprisesEndpoint_V2 = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<Enterprise>, SearchRequest & { scoreThreshold: number }>({
    query: request => ({
      url: "/enterprises/search",
      method: "POST",
      body: request,
    }),
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return draft;
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;

      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    providesTags: ["Enterprise"],
  });
};

export default searchEnterprisesEndpoint_V2;

export interface SearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
  enterpriseCategoryIds?: string[];
  enterpriseSubActivityIds?: string[];
}
