import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '@ilan-types/news/event';
import { DataResponse } from '@/types/reponse-data';
import { ObjectId } from '@/types/object-id';

interface EventsOfNewsSate {
  data: DataResponse<Event>;
}

const initialState: EventsOfNewsSate = {
  data: {
    data: [],
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

const eventOfNewsSlice = createSlice({
  name: 'eventsOfNews',
  initialState,
  reducers: {
    fetchEventsOfNews(state, action: PayloadAction<DataResponse<Event>>) {
      state.data = action.payload;
    },
    addEventToState(state, action: PayloadAction<Event>) {
      // Check if the state already has data, and if not, initialize it as an empty array
      if (!state.data.data) {
        state.data.data = [];
      }
      // Add the new article to the state's data array
      state.data.data.push(action.payload);
    },
    deleteEventFromState(state, action: PayloadAction<ObjectId>) {
      if (state.data.data) {
        state.data.data = state.data.data.filter(
          (event) => event.id !== action.payload
        );
      }
    },
  },
});

export const { fetchEventsOfNews, addEventToState, deleteEventFromState } =
  eventOfNewsSlice.actions;
export default eventOfNewsSlice.reducer;
