import {createContext, Dispatch, FC, ReactNode, useReducer} from 'react';
import {useIntl} from 'react-intl';

interface SharedDataState {
    sharedDocuments: string[];
}

type SharedDataAction = { type: 'UPDATE_SHARED_DATA'; payload: string[] };

interface SharedDataContextType {
    state: SharedDataState;
    dispatch: Dispatch<SharedDataAction>;
}

const SharedDataContext = createContext<SharedDataContextType | undefined>(undefined);

const initialState: SharedDataState = {
    sharedDocuments: [],
};

const sharedDataReducer = (state: SharedDataState, action: SharedDataAction): SharedDataState => {
    switch (action.type) {
        case 'UPDATE_SHARED_DATA':
            return { ...state, sharedDocuments: action.payload };
        default:
            return state;
    }
};

interface SharedDataProviderProps {
    children: ReactNode;
}

const SharedDataProvider: FC<SharedDataProviderProps> = ({ children }) => {
    const {formatMessage: __} = useIntl();
    const [state, dispatch] = useReducer(sharedDataReducer, initialState);
    return (
        <SharedDataContext.Provider value={{ state, dispatch }}>
            {children}
        </SharedDataContext.Provider>
    );
};

export { SharedDataContext, SharedDataProvider };
