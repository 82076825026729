import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Chip, TextField, Typography } from "@mui/material";
import theme from "@/theme/theme";
import { Option } from "@components/auto-complete";
import { faCloudArrowDown } from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";

const ReadOnlyDisplay: React.FC<{
  labelText: string;
  type?: string;
  values?: any;
  description?: string;
  onReadOnlyDisplay?: JSX.Element | null;
  options?: any,
  asFormStyle?: boolean,
}> = ({ labelText, type, values, description, onReadOnlyDisplay , asFormStyle,options}) => {
  return (
    <Box style={{ display: asFormStyle ? "inline-block" : "flex", alignItems: "center"}}>
      {type !== "customComponents" && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            width: asFormStyle ? "100%" : "40%",
          }}
        >
          <Typography marginRight="3px">{type === "colorPicker" ? "color" : labelText}</Typography>
          <Tooltip title={description}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon={faCircleInfo} size="xs" />
          </Tooltip>
        </Box>
      )}
      <Box style={{ display: "flex", width: type !== "customComponents" && !asFormStyle ? "60%" : "100%" }}>
      {values === "" || (Array.isArray(values) && values.length === 0) ? (
          "-"
        ) : type === "datetime" ? (
          <TextField value={values} size="small" placeholder={labelText} disabled fullWidth />
        ) : type === "date" ? (
          <TextField
            value={values.split("T")[0]}
            size="small"
            placeholder={labelText}
            disabled
            fullWidth
          />
        ) : type === "toggle" ? (
          <TextField
            value={values ? "On" : "Off"}
            size="small"
            placeholder={labelText}
            disabled
            fullWidth
          />
        ) : type === "textarea" ? (
          <TextField
            size="small"
            placeholder={labelText}
            value={values}
            multiline
            fullWidth
            rows={4}
            disabled
          />
        ) : type === "multi" ? (
          <Box>
            {[...(values as Option[])].map(value => (
              <Chip style={{ marginRight: "3px" }} label={value.name} key={value.id} />
            ))}
          </Box>
        ) : type === "select" ? (
          <TextField value={options?.find((obj:any) => obj.value === values)?.text || "-"} size="small" placeholder={labelText} disabled fullWidth />
        ) : type === "text" || type === "number" || type === "password" ? (
          <TextField size="small" type={type} fullWidth value={values} disabled />
        ) : type === "file2" ? (
          <Box>
            {[...(values as Option[])].map((value, index) => (
              <Chip
                style={{
                  marginRight: "3px",
                  marginBottom: "10px",
                  backgroundColor: theme.palette.violet.light,
                  border: "0px solid #000",
                  borderRadius: "5px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  // Replace '/your-link-path' with the actual path you want to navigate to
                  window.location.href = value.azureBlobUri;
                }}
                label={
                  <>
                    <FontAwesomeIcon
                      icon={faCloudArrowDown}
                      style={{
                        margin: "0px 5px",
                        fontWeight: "bolder",
                      }}
                    />
                    {value.name}
                  </>
                }
                key={index}
              />
            ))}
          </Box>
        ) : type === "richtexteditor" ? (
          <Box
            sx={{
              minHeight: "100px",
              padding: "5px",
              border: "1px solid #000",
              borderRadius: "5px",
              width: "100%",
            }}
            dangerouslySetInnerHTML={{ __html: values }}
          />
        ) : type === "checkbox" ? (
          <TextField
            value={values ? "On" : "Off"}
            size="small"
            placeholder={labelText}
            disabled
            fullWidth
          />
        ) : type === "radiogroup" ? (
          <TextField value={values} size="small" placeholder={labelText} disabled fullWidth />
        ) : type === "autocomplete" ? (
          <>
            {[...(values as Option[])].map(value => (
              <Chip style={{ marginRight: "3px" }} label={value.text} key={value.id} />
            ))}
          </>
        ) : type === "tags" ? (
          <>
            {[...(values as Option[])].map((value, index) => (
              <Chip style={{ marginRight: "3px" }} label={value.text} key={index} />
            ))}
          </>
        ) : type === "customComponents" ? (
          onReadOnlyDisplay
        ) : type === "colorPicker" ? (
          <Box
            sx={{
              height: "30px",
              width: "70px",
              padding: "5px",
              border: "1px solid #000",
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                backgroundColor: values,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        ) : (
          "-"
        )}
      </Box>
    </Box>
  );
};

export default ReadOnlyDisplay;
