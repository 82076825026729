import { AbilityBuilder } from "@casl/ability";
import { appAbility } from ".";
import { ProfileResponse } from "@/types/profile";
import { defineBasicAbility } from "@/casl/basicAbilities";
import {
  defineAB_Ability,
  defineAC_Ability,
  defineACB_Ability,
  defineACM_Ability,
  defineACMB_Ability,
  defineAdminAbility,
  defineAM_Ability,
  defineAMB_Ability,
} from "@/casl/adminAbilities.ts";
import {
  defineBC_Ability,
  defineCM_Ability,
  defineCMB_Ability,
  defineCommercialAbility,
} from "@/casl/commercialAbilities.ts";
import {
  defineBM_Ability,
  defineCommunityManagerAbility,
} from "@/casl/communityManagerAbilities.ts";

export const defineSavProAbility = (_user: ProfileResponse) => {
  const { can, rules } = new AbilityBuilder(appAbility);

  can(["View", "Create"], "SavPro");

  return rules;
};

//Admin && SavPro
export const defineAS_Ability = (user: ProfileResponse) => {
  const A_Rules = defineAdminAbility(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...S_Rules, ...A_Rules];

  return combinedRules;
};

//Basic && SavPro
export const defineBS_Ability = (user: ProfileResponse) => {
  const B_Rules = defineBasicAbility(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...B_Rules, ...S_Rules];

  return combinedRules;
};

//Commercial && SavPro
export const defineCS_Ability = (user: ProfileResponse) => {
  const C_Rules = defineCommercialAbility(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...C_Rules, ...S_Rules];

  return combinedRules;
};

//Manager && SavPro
export const defineMS_Ability = (user: ProfileResponse) => {
  const M_Rules = defineCommunityManagerAbility(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...M_Rules, ...S_Rules];

  return combinedRules;
};

//Basic && Commercial && SavPro
export const defineBCS_Ability = (user: ProfileResponse) => {
  const BC_Rules = defineBC_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...BC_Rules, ...S_Rules];

  return combinedRules;
};

//Basic && Commercial && SavPro
export const defineBMS_Ability = (user: ProfileResponse) => {
  const BM_Rules = defineBM_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...BM_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Basic && SavPro
export const defineABS_Ability = (user: ProfileResponse) => {
  const AB_Rules = defineAB_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...AB_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Commercial && SavPro
export const defineACS_Ability = (user: ProfileResponse) => {
  const AC_Rules = defineAC_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...AC_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Manager && SavPro
export const defineAMS_Ability = (user: ProfileResponse) => {
  const AM_Rules = defineAM_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...AM_Rules, ...S_Rules];

  return combinedRules;
};

//Commercial && Manager && SavPro
export const defineCMS_Ability = (user: ProfileResponse) => {
  const CM_Rules = defineCM_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...CM_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Basic && Commercial && SavPro
export const defineACBS_Ability = (user: ProfileResponse) => {
  const ACB_Rules = defineACB_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...ACB_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Manager && Commercial && SavPro
export const defineACMS_Ability = (user: ProfileResponse) => {
  const ACM_Rules = defineACM_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...ACM_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Manager && Basic && SavPro
export const defineAMBS_Ability = (user: ProfileResponse) => {
  const AMB_Rules = defineAMB_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...AMB_Rules, ...S_Rules];

  return combinedRules;
};

//Commercial && Manager && Basic && SavPro
export const defineCMBS_Ability = (user: ProfileResponse) => {
  const CMB_Rules = defineCMB_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...CMB_Rules, ...S_Rules];

  return combinedRules;
};

//Admin && Commercial && Manager && Basic && SavPro
export const defineACMBS_Ability = (user: ProfileResponse) => {
  const ACMB_Rules = defineACMB_Ability(user);
  const S_Rules = defineSavProAbility(user);

  const combinedRules = [...ACMB_Rules, ...S_Rules];

  return combinedRules;
};

export const S_Ability = (user: ProfileResponse) =>
  new appAbility(defineSavProAbility(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AS_Ability = (user: ProfileResponse) =>
  new appAbility(defineAS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const BS_Ability = (user: ProfileResponse) =>
  new appAbility(defineBS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const CS_Ability = (user: ProfileResponse) =>
  new appAbility(defineCS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const MS_Ability = (user: ProfileResponse) =>
  new appAbility(defineMS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const BCS_Ability = (user: ProfileResponse) =>
  new appAbility(defineBCS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const BMS_Ability = (user: ProfileResponse) =>
  new appAbility(defineBMS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ABS_Ability = (user: ProfileResponse) =>
  new appAbility(defineABS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACS_Ability = (user: ProfileResponse) =>
  new appAbility(defineACS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AMS_Ability = (user: ProfileResponse) =>
  new appAbility(defineAMS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const CMS_Ability = (user: ProfileResponse) =>
  new appAbility(defineCMS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACBS_Ability = (user: ProfileResponse) =>
  new appAbility(defineACBS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACMS_Ability = (user: ProfileResponse) =>
  new appAbility(defineACMS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const AMBS_Ability = (user: ProfileResponse) =>
  new appAbility(defineAMBS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const CMBS_Ability = (user: ProfileResponse) =>
  new appAbility(defineCMBS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const ACMBS_Ability = (user: ProfileResponse) =>
  new appAbility(defineACMBS_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });
