import { Box, TextField, Typography } from "@mui/material";
import Connection from "./connection";
import { ChangeEvent, useCallback, useState } from "react";
import { LOCALES } from "@/i18n/locales";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import useConnections from "@/hooks/amity/useConnections";
import useDimensions from "@/hooks/useDimensions";
import useConfirmationModal from "@/hooks/useConfirmationModal";
import { useIntl } from "react-intl";
import messages from "../messages";

export default function Connections() {
  const { connections, loading: connectionsLoading, removeConnection } = useConnections();
  const { formatMessage: __ } = useIntl();

  const { modalJSX, toggle } = useConfirmationModal({
    content: __(messages.ConfirmationContent),
  });

  const handleRemoveConnection = useCallback(
    (userId: string) => {
      toggle({ okAction: () => removeConnection(userId) });
    },
    [removeConnection, toggle]
  );

  const [search, setSearch] = useState("");
  const locale = useSelector((state: RootState) => state.language.locale);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  };
  const { safeHeight } = useDimensions({ margin: 80 });
  return (
    <Box sx={{ height: safeHeight, overflowY: "scroll" }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <TextField
          size="small"
          name="search"
          id="search"
          label={locale === LOCALES.ENGLISH ? "Search" : "Recherche"}
          value={search}
          onChange={e => handleOnChange(e)}
          variant="outlined"
          placeholder={locale === LOCALES.ENGLISH ? "Search" : "Recherche"}
          InputLabelProps={{ shrink: true }}
          sx={{ marginX: "30px", marginY: "20px", width: "300px", position: "sticky" }}
        />
      </Box>
      {!connectionsLoading && connections.length === 0 && (
        <Typography
          sx={{
            marginTop: "200px",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          {locale === LOCALES.ENGLISH ? "No connections" : "Pas de connexions"}
        </Typography>
      )}
      {!connectionsLoading &&
        connections.map(connection => {
          return (
            <Connection
              key={connection}
              userId={connection}
              removeFollower={handleRemoveConnection}
              search={search}
            />
          );
        })}
      {modalJSX}
    </Box>
  );
}
