import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {User} from "@/types/user";

interface UserState {
  data: User[];
}

const initialState: UserState = {
  data: []
};

const usersProfileSlice = createSlice({
  name: 'usersProfile',
  initialState,
  reducers: {
    fetchUsers(state, action: PayloadAction<User[]>) {
      state.data = action.payload;
    },
    addUsersState(state, action: PayloadAction<User>) {
      if (!state.data) {
        state.data = []
      }
      state.data.unshift(action.payload);
    },
    editUsersState(state, action: PayloadAction<User>) {
      if (!state.data) {
        state.data = []
      }
      const index = state.data.findIndex((User) => User.id === action.payload.id);
      state.data[index] = action.payload;
    },
  },
});

export const { addUsersState, fetchUsers, editUsersState } = usersProfileSlice.actions;
export default usersProfileSlice.reducer;
