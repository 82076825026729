import { useIntl } from "react-intl";
import { dateTimemessages } from "@/i18n/globalMessages";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import dayjs from "@/utils/date/dayjs.config";

type argsT = {
  dateDiff?: {
    hours: number;
    days: number;
    months: number;
    years: number;
    minutes: number;
  };
};

export const useGetDateMessage = ({ dateDiff }: argsT) => {
  const { formatMessage: __ } = useIntl();
  if (!dateDiff) return { message: "" };

  const { years, months, days, hours, minutes } = dateDiff;

  return {
    message: (() => {
      if (years)
        return __(dateTimemessages.publishedYear, {
          value: years,
        });
      if (months)
        return __(dateTimemessages.publishedMonth, {
          value: months,
        });
      if (days)
        return __(dateTimemessages.publishedDay, {
          value: days,
        });
      if (hours)
        return __(dateTimemessages.publishedHour, {
          value: hours,
        });
      if (minutes)
        return __(dateTimemessages.publishedMinute, {
          value: minutes,
        });
    })(),
  };
};

export const useRelativeTimeFormatter = (date: Date | string | number) => {
  const locale = useSelector((state: RootState) => state.language.locale);

  dayjs.locale(locale === "fr-FR" ? "fr" : "en");

  return date ? dayjs(date).fromNow() : "-";
};
