import { defineMessages } from "react-intl";

export const scope = "tagsInput";

export default defineMessages({
  invalidFormat: {
    id: `${scope}.invalidFormat`,
    defaultMessage: "Le format de l'entrée est incorrect.",
  },
  placeHolder: {
    id: `${scope}.placeHolder`,
    defaultMessage: "Saisissez vos tags",
  },
  helperText: {
    id: `${scope}.helperText`,
    defaultMessage: "Saisissez un text et appuyez sur “Entrée” pour valider.",
  },
});
