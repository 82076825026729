import { defineMessages } from "react-intl";

export const scope = "profile.myProfilePage.locationSearch";
export const common = "common";

export default defineMessages({
  helperTitle: {
    id: `${scope}.helperTitle`,
    defaultMessage: "Commencez à entrer votre adresse",
  },
  helperSubtitle: {
    id: `${scope}.helperSubtitle`,
    defaultMessage: "Propulsé par Google Maps",
  },

  startTypingYour: {
    id: `${common}.startTypingYour`,
    defaultMessage: "Saisissez votre",
  },
});
