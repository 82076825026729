import { Enterprise } from "@/types/profile";

export const phoneNumber = (inputNumber = ""): { phone: string; countryCode: string } => {
  if (!inputNumber) return { phone: "", countryCode: "" };
  const digitsOnly = inputNumber.replace(/\D/g, "");
  const formattedPhoneNumber = digitsOnly
    .slice(-9)
    .replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
  const countryCode = digitsOnly.slice(0, -9);
  return {
    phone: formattedPhoneNumber,
    countryCode: countryCode === "0" ? "+33" : `+${countryCode}`,
  };
};

export const getInitials = (firstName: string, lastName: string) =>
  `${firstName.charAt(0)}${lastName.charAt(0)}`;

export const EnterprisesTooltipText = (enterprises: Enterprise[]): string => {
  const EntreprisesArray =
    enterprises && enterprises?.length > 1 ? enterprises?.map(e => e.title) : [];
  const newEntreprisesArray = EntreprisesArray.slice(1);
  const TooltipText = newEntreprisesArray.join(", ");
  return TooltipText;
};
