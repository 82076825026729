import { ListItemText, styled } from "@mui/material";

const ListTextSideBar = styled(ListItemText)({
  ["& .MuiListItemText-primary"]: {
    fontSize: "12px",
    lineHeight: "12px",
  },
  flex : "none"
});

export default ListTextSideBar;
