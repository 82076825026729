import { defineMessages } from "react-intl";

export const scope = "phoneInputComponent";

export default defineMessages({
  error: {
    id: `${scope}.error`,
    defaultMessage: "Le numéro téléphone n'est pas valide",
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: "Code",
  },
  countryPhoneCode: {
    id: `${scope}.countryPhoneCode`,
    defaultMessage: "Indicatifs téléphoniques internationaux",
  },
  otpNotValidated: {
    id: `${scope}.otpNotValidated`,
    defaultMessage: "Votre numéro de téléphone n'est pas valide. Appuyez sur le bouton 'Invalide' pour le révalider.",
  },
  otpValidated: {
    id: `${scope}.otpValidated`,
    defaultMessage: "Votre numéro de téléphone a été validé avec succès.",
  },
  validPhoneNumber: {
    id: `${scope}.validPhoneNumber`,
    defaultMessage: "Votre numéro de téléphone est déja validé.",
  },
  notValidated: {
    id: `${scope}.notValidated`,
    defaultMessage: "Le numéro de téléphone doit être validé. Appuyez sur le bouton d'information pour commencer la validation.",
  },
  codeSuccess: {
    id: `${scope}.codeSuccess`,
    defaultMessage: "Le code a été envoyé avec succès, veuillez vérifier votre téléphone.",
  },
  codeError: {
    id: `${scope}.codeError`,
    defaultMessage: "Une erreur est survenue lors de la tentative d'envoi d'un code. Veuillez réessayer",
  },
});
