import { ChangeEvent, useState } from "react";
import { Box, TextField, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { dateTimemessages } from "@/i18n/globalMessages";
import { FormikProps } from "formik";
import { messages } from "@/pages/store/entreprise-page/entreprise-details-page/servicesOffers/messages";
import { useIntl } from "react-intl";
import { publishedOfferT } from "@/types/offers";

const valuesInverter = {
  months: "durationDays",
  days: "durationMonths",
};
const valuesMapper = {
  months: "durationMonths",
  days: "durationDays",
};

type DurationFieldProps = { form: FormikProps<any>; selectedOffer: publishedOfferT | undefined };
const DurationField = ({ form, selectedOffer }: DurationFieldProps) => {
  const { formatMessage: __ } = useIntl();

  const [selectValue, setSelectValue] = useState<keyof typeof valuesInverter>(
    (selectedOffer &&
      ((selectedOffer?.durationDays && "days") || (selectedOffer?.durationMonths && "months"))) ||
      "days"
  );
  const handleChange = (
    event: SelectChangeEvent & {
      target: {
        value: keyof typeof valuesInverter;
      };
    }
  ) => {
    const _value = event.target.value;
    setSelectValue(_value);
    form.setFieldValue(valuesMapper[_value], form.values[valuesInverter[_value]]);
    form.setFieldValue(valuesInverter[_value], 0);
  };
  const handleDurationChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const _value = parseInt(e.target.value);
    form.setFieldValue(valuesMapper[selectValue], _value);
    // used for inner calculations to be removed fron the request payload
    form.setFieldValue("duration", _value);
  };
  return (
    <Box display="flex" alignItems="center" gap="1rem" marginTop="10px">
      <TextField
        fullWidth
        size="small"
        onChange={handleDurationChange}
        type="number"
        InputLabelProps={{
          shrink: true,

          sx: {
            fontWeight: 600,
          },
        }}
        defaultValue={
          (selectedOffer && (selectedOffer?.durationDays || selectedOffer?.durationMonths)) || null
        }
        sx={{
          "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          "& input[type=number]": {
            "-moz-appearance": "textfield" /* Firefox */,
          },
        }}
        label={`${__(messages.completionDeadline)} (${
          selectValue === "days"
            ? __(dateTimemessages.isDayPlural, { value: 2 })
            : __(dateTimemessages.isMonthPlural, { value: 2 })
        })`}
        placeholder={`${__(messages.completionDeadline)}`}
      />
      <Select
        value={selectValue}
        defaultValue="days"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
        size="small"
        sx={{
          width: "150px",
          "& .MuiInputBase-input": {
            fontWeight: 600,
          },
        }}
      >
        <MenuItem value="days">{__(dateTimemessages.isDayPlural, { value: 2 })}</MenuItem>
        <MenuItem value="months">{__(dateTimemessages.isMonthPlural, { value: 2 })}</MenuItem>
      </Select>
    </Box>
  );
};

export default DurationField;
