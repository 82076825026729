import { ApiEndpointBuilder } from "@/services/api/types";
import {ActivityZones, EnterpriseRecord, JobType, Profile} from "@/types/profile";
import { ResponseFile } from "@/types/store/file/imageFile";
import { Address } from "@/types/store/profile";

const updateEmployee = (builder: ApiEndpointBuilder) => {
  return builder.mutation<Profile, Partial<ProfileType>>({
    query: arg => ({
      url: `/enterpriseemployees/${arg.id}`,
      method: "PUT",
      body: {
        ...arg,
      },
    }),
    invalidatesTags: ["Employee", "currentEmployee"],
  });
};

export type ProfileType = {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  jobTitle?: string;
  biography?: string;
  tenantId?: string;
  picture?: ResponseFile;
  phone1?: string;
  isPhone1Shared?: boolean;
  phone2?: string;
  whatsapp?: string;
  isVerified?: boolean;
  isPublic?: boolean;
  dateOfIntegration?: Date;
  dateOfBirth?: Date;
  location?: Address;
  expertiseDomaineIds?: string[];
  employeeCertificatIds?: string[];
  subActivityDomainId?: string;
  enterpriseRecord?: EnterpriseRecord;
} & (
  | {
      ActivityZonesIds?: string[];
      jobTypeId?: string;
    }
  | {
      jobType?: JobType;
      ActivityZonesIds?: ActivityZones[];
    }
);

export default updateEmployee;
