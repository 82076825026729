import { useCallback, useState } from "react";
import InvitationCard from "./invitation";
import { Box, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { LOCALES } from "@/i18n/locales";
import useConnections from "@/hooks/amity/useConnections";
import useDimensions from "@/hooks/useDimensions";
import useConfirmationModal from "@/hooks/useConfirmationModal";
import messages from "../messages";
import { useIntl } from "react-intl";

export default function Invitations() {
  const {
    receivedConnectRequests,
    loading: connectionsLoading,
    acceptConnectRequest,
    rejectConnectRequest,
  } = useConnections();

  const { formatMessage: __ } = useIntl();

  const [search, setSearch] = useState("");
  const locale = useSelector((state: RootState) => state.language.locale);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value);
  };
  const { safeHeight } = useDimensions({ margin: 80 });

  const { modalJSX, toggle } = useConfirmationModal({
    content: __(messages.ConfirmationRejectContent),
  });

  const handleRemoveInvitation = useCallback(
    (userId: string) => {
      toggle({ okAction: () => rejectConnectRequest(userId) });
    },
    [rejectConnectRequest, toggle]
  );

  return (
    <Box sx={{ height: safeHeight, overflowY: "scroll" }}>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <TextField
          name="search"
          size="small"
          id="search"
          label={locale === LOCALES.ENGLISH ? "Search" : "Recherche"}
          value={search}
          onChange={e => handleOnChange(e)}
          variant="outlined"
          placeholder={locale === LOCALES.ENGLISH ? "Search" : "Recherche"}
          InputLabelProps={{ shrink: true }}
          sx={{ marginX: "30px", marginY: "20px", width: "300px" }}
        />
      </Box>
      {!connectionsLoading && receivedConnectRequests.length === 0 && (
        <Typography
          sx={{
            marginTop: "200px",
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          {locale === LOCALES.ENGLISH ? "No invitations" : "Pas d'invitations"}
        </Typography>
      )}
      {!connectionsLoading &&
        receivedConnectRequests.map(connectRequest => {
          return (
            <InvitationCard
              key={connectRequest}
              userId={connectRequest}
              acceptConnectRequest={acceptConnectRequest}
              rejectConnectRequest={handleRemoveInvitation}
              search={search}
            />
          );
        })}
      {modalJSX}
    </Box>
  );
}
