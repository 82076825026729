/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import useFormGenerator, { FormGenConfig } from "@/hooks/useFormGenerator";
import * as yup from "yup";
import { LocationPicker } from "@/components/location-picker";
import {
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useSearchOfferSkillsQuery,
  useGetOfferByIdQuery,
} from "@/store/offers/endpoints";
import { OfferT } from "@/types/offers";
import { FormLoadingSkeleton } from "./FromLoadingSkeleton";
import { toast } from "react-hot-toast";
import { useIntl } from "react-intl";
import { messages } from "@/pages/store/entreprise-page/entreprise-details-page/servicesOffers/messages";
import { Option } from "@components/auto-complete";
import { useSearchSpecialitiesQuery } from "@/store/specialities/endpoints";
import { useSearchSubActivityDomainQuery } from "@/store/sub-activity-domain/endpoints";
import { useGetCurrentEnterpriseQuery } from "@/store/market-place/endpoints";
import { SubActivityDomain } from "@/types/activity-domain";
import { EnterpriseSpeciality } from "@/types/store/profile.ts";
import OutlinedReactSelect from "@/components/react-select/outlined-react-select";
import DurationField from "./components/duration-field";
import { convertToUTC } from "@/utils/date/dayjs.config";

export const useOffersForm = () => {
  const { formatMessage: __, locale } = useIntl();
  const { data: enterpriseData } = useGetCurrentEnterpriseQuery({});

  const [offerId, setOfferId] = useState<string | null>(null);
  const { data: getOfferByIdResult, isFetching: isFetchingOffer } = useGetOfferByIdQuery(
    { id: offerId as string },
    { skip: !offerId, refetchOnMountOrArgChange: true }
  );

  const { data: offerSkillsData } = useSearchOfferSkillsQuery({
    pageNumber: 0,
    pageSize: 100,
  });
  // API: update offers
  const [updateOffer, { isLoading: isUpdating }] = useUpdateOfferMutation();
  // API: create offers
  const [createOffer, { isLoading: isCreating }] = useCreateOfferMutation();

  const { data: subActivityDomainData } = useSearchSubActivityDomainQuery(
    {
      pageNumber: 0,
      pageSize: 50,
      advancedFilter: {
        logic: "and",
        filters: [
          {
            operator: "eq",
            value: enterpriseData?.activityDomain?.id,
            field: "activityDomain.id",
          },
        ],
      },
    },
    {
      skip: !enterpriseData?.activityDomain?.id,
    }
  );

  const { data: allSpecialties } = useSearchSpecialitiesQuery({});

  const formConfig = useMemo(
    () =>
      ({
        title: {
          type: "text",
          attributes: {},
          labelText: __(messages.offerTitle),
          validationSchema: () => yup.string().required(__(messages.fieldRequired)),
          initialValue: offerId ? getOfferByIdResult?.title ?? "" : "",
        },
        categoryTypeAndSpecialty: {
          type: "customComponents",
          defaultValidationError: false,
          initialValue: undefined,
          content: ({ form }) => {
            return (
              <Box display="flex" flexDirection="column" gap={2}>
                <OutlinedReactSelect
                  options={subActivityDomainData?.data ?? []}
                  value={form.values.categoryId}
                  placeholder={__(messages.activity)}
                  label={__(messages.activity)}
                  getOptionValue={(option: SubActivityDomain) => option?.id as string}
                  getOptionLabel={(option: SubActivityDomain) => {
                    return option.name.length > 0
                      ? option.name
                      : subActivityDomainData?.data.find(e => e.id === option.id)?.name ?? "error";
                  }}
                  isMulti
                  onChange={e => {
                    form.setFieldValue("categoryId", e);
                    form.setFieldValue("specialtyId", []);
                  }}
                  onBlur={() => {
                    form.setFieldTouched("categoryId");
                    form.setFieldTouched("specialtyId");
                  }}
                  error={
                    form.touched.categoryId && form.errors.categoryId
                      ? form.errors.categoryId
                      : null
                  }
                />

                <OutlinedReactSelect
                  options={allSpecialties?.data ?? []}
                  filterOption={option =>
                    (form.values.categoryId as { id: string; name: string }[]).some(
                      category => category.id === option.data.enterpriseSubActivityDomain.id
                    )
                  }
                  value={form.values.specialtyId}
                  placeholder={__(messages.speciality)}
                  label={__(messages.speciality)}
                  getOptionValue={(option: EnterpriseSpeciality) => option.id}
                  getOptionLabel={(option: EnterpriseSpeciality) => {
                    return option.label.length > 0
                      ? option.label
                      : allSpecialties?.data.find(e => e.id === option.id)?.label ?? "error";
                  }}
                  isMulti
                  onChange={e => form.setFieldValue("specialtyId", e)}
                  onBlur={() => form.setFieldTouched("specialtyId")}
                  error={
                    form.touched.specialtyId && form.errors.specialtyId
                      ? form.errors.specialtyId
                      : null
                  }
                />
              </Box>
            );
          },
        },
        categoryId: {
          type: "hidden",
          attributes: {},
          validationSchema: () => yup.array().min(1, __(messages.fieldRequired)),
          labelText: "",
          initialValue: offerId
            ? (getOfferByIdResult?.offerSpecialities ?? []).map(
                ({ offerSubActivityDomain: { id, name } }) => ({ id, name })
              )
            : [],
        },
        specialtyId: {
          type: "hidden",
          attributes: {},
          validationSchema: () => yup.array().min(1, __(messages.fieldRequired)),
          labelText: "",
          initialValue: offerId
            ? (getOfferByIdResult?.offerSpecialities ?? []).map(({ id, label }) => ({
                id,
                label,
              })) ?? []
            : [],
        },
        description: {
          type: "textarea",
          attributes: { placeholder: __(messages.description), rows: 7 },
          validationSchema: () =>
            yup
              .string()
              .required(__(messages.fieldRequired))
              .max(
                2000,
                __(messages.maxCharacters, {
                  value: 2000,
                })
              ),
          labelText: __(messages.description),
          initialValue: offerId ? getOfferByIdResult?.description ?? "" : "",
        },

        address: {
          type: "customComponents",
          content: ({ form }) => {
            return (
              <LocationPicker
                label={__(messages.SiteAddress)}
                inputName="address"
                disableHelperTxt
                initialValue={getOfferByIdResult?.address?.streetLine1 ?? undefined}
                onChange={({
                  address,
                  city,
                  country,
                  zipCode,
                  countryCode,
                  departement,
                  coordinates,
                }) => {
                  form.setFieldValue("address.streetLine1", address);
                  form.setFieldValue("address.postalCode", zipCode);
                  form.setFieldValue("address.city", city);
                  form.setFieldValue("address.country", country);
                  form.setFieldValue("address.countryCode", countryCode);
                  form.setFieldValue("address.region", departement);
                  form.setFieldValue("address.coordinates", coordinates);
                  // isLocationChanged.current = true;
                }}
              />
            );
          },
          // initialValue: undefined,
          initialValue: offerId
            ? {
                city: getOfferByIdResult?.address?.city ?? "",
                country: getOfferByIdResult?.address?.country ?? "",
                postalCode: getOfferByIdResult?.address?.postalCode ?? "",
                region: getOfferByIdResult?.address?.region ?? "",
                streetLine1: getOfferByIdResult?.address?.streetLine1 ?? "",
                streetLine2: getOfferByIdResult?.address?.streetLine2 ?? "",
                coordinates: getOfferByIdResult?.address?.coordinates ?? "",
              }
            : undefined,

          validationSchema: () => yup.object().required(__(messages.fieldRequired)),
        },
        startDate: {
          type: "date",
          labelText: __(messages.startDate),
          initialValue: offerId
            ? getOfferByIdResult?.startDate ?? new Date().toISOString()
            : new Date().toISOString(),
          validationSchema: () => yup.date().required(__(messages.fieldRequired)),
          attributes: {},
        },
        expirationDate: {
          type: "datetime",
          labelText: __(messages.expirationDate),
          initialValue: offerId
            ? getOfferByIdResult?.expirationDate ?? new Date().toISOString()
            : new Date().toISOString(),
          validationSchema: () =>
            yup
              .date()
              .required(__(messages.fieldRequired))
              .test(
                "custom-validation",
                __(messages.expirationDateValidation),
                function (endDateTime) {
                  const startDateTime = this.resolve(yup.ref("startDate"));
                  if (startDateTime) {
                    return endDateTime ? endDateTime > startDateTime : false;
                  }
                  return true;
                }
              ),
          attributes: {},
        },
        // Extra field for treatment - remove it from the sent body
        duration: {
          type: "customComponents",
          content: ({ form }) => <DurationField form={form} selectedOffer={getOfferByIdResult} />,
          validationSchema: () =>
            yup
              .number()
              .typeError(__(messages.fieldRequired))
              .positive(__(messages.invalidValue))
              .required(__(messages.fieldRequired)),
          initialValue: offerId
            ? getOfferByIdResult?.durationDays || getOfferByIdResult?.durationMonths // assumes at least one is not undefined
            : undefined,
        },
        durationDays: {
          type: "hidden",
          attributes: {},
          labelText: "",
          validationSchema: () => null,
          initialValue: offerId ? getOfferByIdResult?.durationDays ?? 0 : 0,
        },
        durationMonths: {
          type: "hidden",
          attributes: {},
          labelText: "",
          validationSchema: () => null,
          initialValue: offerId ? getOfferByIdResult?.durationMonths ?? 0 : 0,
        },
        skills: {
          type: "autocomplete",
          options: offerSkillsData?.data ?? [],
          attributes: { placeholder: __(messages.requiredSkills) },
          labelText: __(messages.skills),
          validationSchema: () => yup.array(),
          initialValue: offerId ? ((getOfferByIdResult?.skills ?? []) as Option[]) : [],
          optionLabel: "label",
          optionValue: "id",
        },
      } satisfies FormGenConfig),
    [locale, allSpecialties, getOfferByIdResult, offerId, offerSkillsData, subActivityDomainData]
  );

  const { formJSX, toggleModal } = useFormGenerator({
    title: offerId ? __(messages.editServiceOffer) : __(messages.addOffer),
    isLoading: isCreating || isUpdating,
    isFetching: isFetchingOffer,
    FormLoadingSkeleton: () => <FormLoadingSkeleton editMode={Boolean(offerId)} />,
    //@ts-ignore
    config: formConfig,
    onSubmit: async values => {
      try {
        if (offerId) {
          // update offer
          const {
            duration: _duration,
            specialtyId,
            startDate,
            expirationDate,
            ..._payload
          } = values;
          await updateOffer({
            payload: {
              offerId,
              ..._payload,
              isPublished: true,
              offerSpeciailitiesIds: specialtyId.map(({ id }: { id: string }) => id),
              startDate: convertToUTC(startDate),
              expirationDate: convertToUTC(expirationDate),
            } as OfferT & { offerId: string },
            id: offerId,
          }).unwrap();
        } else {
          // ignoring duration
          const {
            duration: _duration,
            specialtyId,
            startDate,
            expirationDate,
            ..._payload
          } = values;
          await createOffer({
            ..._payload,
            startDate: convertToUTC(startDate),
            expirationDate: convertToUTC(expirationDate),
            isPublished: true,
            offerSpeciailitiesIds: specialtyId.map(({ id }: { id: string }) => id),
          } as OfferT).unwrap();
        }
        toast.success(__(messages.successOperation));
      } catch (error) {
        toast.error(__(messages.errorOperation));
      }
    },
    onClose: () => setOfferId(null),
    type: "drawer",
  });

  return {
    Form: formJSX,
    openForm: (offerId?: string) => {
      offerId && setOfferId(offerId);
      toggleModal();
    },
  };
};
