import { defineMessages } from "react-intl";

export const scope = "otpPhone.modal";

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Validez votre numéro",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: "Veuillez entrer le code que vous avez reçu par sms sur ce numéro",
  },
  timer: {
    id: `${scope}.timer`,
    defaultMessage: "Temps restant",
  },
  receiveCode: {
    id: `${scope}.receiveCode`,
    defaultMessage: "Pas reçu de code ?",
  },
  resendCode: {
    id: `${scope}.resendCode`,
    defaultMessage: "Renvoyer",
  },
  codeSuccess: {
    id: `${scope}.codeSuccess`,
    defaultMessage: "Le code est envoyé avec succès, veuillez vérifier votre téléphone.",
  },
  codeError: {
    id: `${scope}.codeError`,
    defaultMessage: "Une erreur est survenue lors de la tentative d'envoi d'un code. Veuillez réessayer",
  },
  validateCodeSuccess: {
    id: `${scope}.validateCodeSuccess`,
    defaultMessage: "Votre numéro de téléphone a été validé avec succès.",
  },
  validateCodeError: {
    id: `${scope}.validateCodeError`,
    defaultMessage: "Une erreur est survenue lors de la validation de votre Code. Veuillez réessayer",
  },
  validButton: {
    id: `${scope}.validButton`,
    defaultMessage: "Valider mon numéro",
  },

});
