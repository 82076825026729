import { ApiEndpointBuilder } from "@/services/api/types";
import { DataResponse } from "@/types/reponse-data";
import { AdvancedFilter } from "@/types/search-request";
import { SubActivityDomain } from "@/types/activity-domain";

const searchEnterpriseSubActivityDomainEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<SubActivityDomain>, SearchRequest>({
    query: request => ({
      url: "/enterprisesubactivitydomain/search",
      method: "POST",
      body: request,
    }),
  });
};

export default searchEnterpriseSubActivityDomainEndpoint;

export interface SearchRequest {
  advancedSearch?: {
    fields: string[];
    keyword: string;
  };
  keyword?: string;
  advancedFilter?: AdvancedFilter;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string[];
}
