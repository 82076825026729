import { TalkUser } from '@/types/inbox/talk.user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InboxState {
    user: TalkUser | null;
    conversationId: string | null;
}

const initialState: InboxState = {
    user: null,
    conversationId: null,
};

const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        fetchCurrentUser(state, action: PayloadAction<TalkUser>) {
            state.user = action.payload;
        },
        fetchConversationId(state, action: PayloadAction<string>) {
            state.conversationId = action.payload;
        }
    },
});

export const { fetchCurrentUser } = inboxSlice.actions;
export default inboxSlice.reducer;
