import { FormikErrors } from "formik";
import { LoadingButton } from "@mui/lab";
import { Button, ButtonProps } from "@mui/material";
import theme from "@/theme/theme";

type SingleButton = {
  textButton: string | JSX.Element;
  buttonProps: ButtonProps;
  buttonState: "primary" | "secondary";
  isLoading: boolean;
};
type InputWrapperProps = {
  buttons: (SingleButton | undefined)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FormikErrors<any>;
};

const InputWrapper = ({ buttons }: InputWrapperProps) => {
  return (
    <div style={{ display: "flex", marginLeft: "auto", marginTop: "-1rem" }}>
      {(buttons.filter(Boolean) as SingleButton[]).map(({ textButton, buttonProps, isLoading }) => (
        <Button
          type="submit"
          disabled={isLoading}
          {...buttonProps}
          key={`button-${
            typeof textButton === "string" ? textButton : Math.round(Math.random() * 1000)
          }`}
          sx={{
            marginLeft: "8px",
            borderRadius: 0,
            boxShadow: "none",
            ["&:disabled"]: {
              background: theme.palette.customGrey.dark,
              cursor: "not-allowed",
            },
          }}
          href=""
        >
          {isLoading ? <LoadingButton loading disabled={false} /> : textButton}
        </Button>
      ))}
    </div>
  );
};

export default InputWrapper;
