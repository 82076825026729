import { User } from "@/types/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UsersState {
    data: User[] | null;
}


const initialState: UsersState = {
    data: [],
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchAllUsers(state, action: PayloadAction<User[]>) {
            state.data = action.payload;
        },
    },

});

export const { fetchAllUsers } = usersSlice.actions;
export default usersSlice.reducer;
