import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Certificate from '@/types/certificates/certificate';
import { DataResponse } from '@/types/reponse-data';

interface CertificatesState {
  data: DataResponse<Certificate>;
}

const initialState: CertificatesState = {
  data: {
    data: [],
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },
};

const certificatesSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    fetchCertificates(state, action: PayloadAction<DataResponse<Certificate>>) {
      state.data = action.payload;
    },
    addCertificateToState(state, action: PayloadAction<Certificate>) {
      if (!state.data.data) {
        state.data.data = []
      }
      state.data.data.push(action.payload);
      state.data.totalCount !== undefined && state.data.totalCount++;
    },
    editCertificateInState(state, action: PayloadAction<Certificate>) {
      if (!state.data.data) {
        state.data.data = []
      }
      const index = state.data.data.findIndex((certificate) => certificate.id === action.payload.id);
      state.data.data[index] = action.payload;
    },
    deleteCertificateFromState(state, action: PayloadAction<string>) {
      if (!state.data.data) {
        state.data.data = []
      }
      state.data.data = state.data.data.filter((certificate) => certificate.id !== action.payload);
      state.data.totalCount !== undefined && state.data.totalCount--;
    }
  },
});

export const {
  fetchCertificates,
  addCertificateToState,
  deleteCertificateFromState,
  editCertificateInState
} = certificatesSlice.actions;

export default certificatesSlice.reducer;
