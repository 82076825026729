import React, { FC, ReactElement, ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Global, css } from '@emotion/react';
import theme from './theme';
declare module "@mui/material/styles" {
  interface Palette {
    violet: Palette['primary'];
  }
  interface PaletteOptions {
    violet?: Palette['primary'];
  }
}

const Theme: FC<Props> = (props) => {
  const { children } = props;
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
          }
          /* Scroll bar window */
          /* width */
          ::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f0f0f0;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #d8d8d8;
            border-radius: 4px;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: rgba(85, 85, 85, 0.8);
          }
        `}
      />
      {children}
    </ThemeProvider>
  );
};

interface Props {
  children: React.JSX.Element | ReactElement | ReactNode;
}

export default Theme;
