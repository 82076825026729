// import { Knowledge } from '@/types/articles-of-news';
import { KnowledgeBase } from '@/types/knowledge';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : KnowledgeBase = {}

const KnowledgeBaseSlice = createSlice({
  name: 'KnowledgeBase',
  initialState,
  reducers: {
      updateKnowledgeBase: (state, { payload }: PayloadAction<Partial<KnowledgeBase>>) => {
				return { ...state, ...payload }
			}
  },
});

export const { updateKnowledgeBase } = KnowledgeBaseSlice.actions
export const knowledgeBaseReducer = KnowledgeBaseSlice.reducer
