import { useCallback } from "react";
import useCreateChannel from "./amity/useCreateChannel";
import { useDispatch } from "react-redux";
import { openChatPopup } from "@/store/chat-popup";
import { Employee } from "@/types/employee";

const useAmityChatPopup = () => {
  const dispatch = useDispatch();
  const { createChannel } = useCreateChannel();

  const openAmityChatPopup = useCallback(
    async (employee: Employee) => {
      const channel = await createChannel(
        [employee.id],
        `${employee.firstName} ${employee.lastName}`
      );
      if (channel) {
        dispatch(openChatPopup(channel._id));
      }
    },
    [createChannel, dispatch]
  );

  return { openAmityChatPopup };
};

export default useAmityChatPopup;
