import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useRedirectErrorPageHook = (error: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.status === 404) {
      navigate("/error");
    }
  }, [error, navigate]);
};

export default useRedirectErrorPageHook;
