import { ApiEndpointBuilder } from "@/services/api/types";

const searchActivityDomainStatisticsEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<Response[], Request>({
    query: request => ({
      url: "/enterpriseactivitydomain/statistics",
      method: "GET",
      params: request,
    }),
  });
};

export default searchActivityDomainStatisticsEndpoint;

interface Response {
  id: string;
  name: string;
  employeeCount: number;
}

interface Request {
  activityZoneId: string;
}
