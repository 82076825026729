import { useCallback, useEffect, useState } from "react";
import { Client } from "@amityco/ts-sdk";

const useUnreadCount = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const handleLiveObject = useCallback(
    (liveObject: Amity.LiveObject<Amity.UserUnread | undefined>) => {
      if (liveObject.data) {
        const { unreadCount } = liveObject.data;
        setUnreadMessagesCount(unreadCount);
      }
    },
    []
  );

  useEffect(() => {
    // Subscribe to live updates
    const unsubscribe = Client.getUserUnread(handleLiveObject);

    // Unsubscribe when component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return unreadMessagesCount;
};

export default useUnreadCount;
