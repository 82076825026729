import { ApiEndpointBuilder } from "@/services/api/types";
import { Employee } from "@/types/employee";
import { DataResponse } from "@/types/reponse-data";
import { SearchRequest } from "@/types/search-request";

const getPublicEmployees = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<Employee>, SearchRequest & { scoreThreshold: number }>({
    query: request => {
      return {
        url: `/enterpriseemployees/public/search`,
        method: "POST",
        body: request,
      };
    },
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return { ...draft };
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    // merge current cache with new data
    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    providesTags: result =>
      result
        ? [
            ...(result?.data ?? []).map((item: Employee) => ({
              type: "publicEmployee",
              id: item.id,
            })),
            { type: "publicEmployee", id: "LIST" },
          ]
        : [{ type: "publicEmployee", id: "LIST" }],
  });
};

export default getPublicEmployees;
