import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const classesTenantBox = {
  selected: "box-selected",
};

export const LogoIla26 = styled("img")({
  ["&"]: {
    width: "200px",
  },
});

export const TenantsBox = styled(Box)({
  ["&"]: {
    border: "1px solid #D4D3DC",
    borderRadius: 5,
    boxShadow: "none",
    backgroundColor: "#F6F6F6",
    width: "60%",
    padding: "15px",
  },
});

export const TenantBox = styled(Box)(({ theme }) => ({
  ["&"]: {
    borderRadius: 5,
    boxShadow: "none",
    backgroundColor: "white",
    width: "90%",
    padding: "14px",
    margin: "15px",
    fontWeight: "bold",
    border: `1px solid transparent`,

    [`&:hover, &.${classesTenantBox.selected}`]: {
      backgroundColor: "#f0ecfa",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "& *": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const Icon = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    height: "18px",
    width: "20px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
}));
