import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { TabData } from "@/types/tab-data";
import { ContainerPages } from "@pages/store/styles";
import theme from "@/theme/theme";
import TabsPage from "@components/layout/tabs";
import { useGetOfferByIdQuery } from "@/store/offers/endpoints";
import { getInitials } from "@/utils/UI/getInitials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-duotone-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useRelativeTimeFormatter } from "@/hooks/useGetDateMessage";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { useOffersForm } from "@/pages/store/sevicesOffers-page/useOffersForm";
import messages from "./messages";
import useAppAbility from "@/casl/useAppAbility";
import useSafeQuery from "@/store/error-handler";

const OfferApplicationDetailsLayout = () => {
  const navigate = useNavigate();
  const { formatMessage: __ } = useIntl();

  const { ability } = useAppAbility();

  const { Form, openForm } = useOffersForm();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(v => (v ? null : event.currentTarget));
  };

  const { offerId } = useParams();

  const { data: offerData, isFetching } = useSafeQuery(
    useGetOfferByIdQuery,
    { id: offerId as string },
    {
      skip: !offerId,
    }
  );

  const tabs: TabData[] = [
    {
      label: `${__(messages.offerDetails)}`,
      url: ``,
    },
  ];

  if (ability?.can("Update", "OfferApplications")) {
    tabs.push({
      label: `${__(messages.applicationsReceived)}`,
      url: `applications-received`,
    });
  }

  const publicationDate = useRelativeTimeFormatter(offerData?.publicationDate);

  return (
    <Box pb="30px">
      <Grid item xs={12}>
        {/* go back */}
        <Box
          sx={{
            border: `1px solid ${theme.palette.customGrey.main}`,
            padding: "22px  18px",
            borderRadius: "6px",
            marginBottom: "10px",
            backgroundColor: theme.palette.background.default,
          }}
          onClick={() => navigate(-1)}
        >
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              style={{ marginRight: "9px", width: "15px", height: "13px" }}
            />
            <Typography fontWeight={400} fontSize="14px">
              {__(messages.Back)}
            </Typography>
          </Box>
        </Box>
      </Grid>

      {isFetching ? (
        <Box sx={{ display: "flex", flexDirection: "column" }} gap="16px">
          <Skeleton variant="rounded" height="170px" />
        </Box>
      ) : (
        <Stack
          sx={{
            border: `1px solid ${theme.palette.customGrey.main}`,
            paddingTop: "10px",
            paddingX: "30px",
            marginBottom: "15px",
            borderRadius: "6px",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={1}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Avatar
                aria-label="avatar"
                alt="Remy Sharp"
                src={offerData?.title}
                sx={{
                  width: "70px",
                  height: "70px",
                  marginLeft: "15px",
                  marginBlock: "20px 16px",
                  bgcolor: theme.palette.violet.contrastText,
                  color: theme.palette.violet.main,
                  fontWeight: 700,
                }}
              >
                {getInitials(offerData?.title || "").toUpperCase()}
              </Avatar>

              <Stack
                direction="column"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "start",
                }}
              >
                <Typography
                  fontWeight={600}
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ marginBottom: "1.5px" }}
                  noWrap
                >
                  {offerData?.title}
                </Typography>

                <Box display="flex" alignItems="center" gap="1rem">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon
                      icon={faLocationDot as IconProp}
                      style={{ width: "12px", height: "12px", marginRight: "5px" }}
                    />
                    <Typography fontSize="13px" fontWeight={500}>
                      {offerData?.address?.city}
                    </Typography>
                  </Box>
                </Box>

                <Typography fontSize="13px" color="rgba(0, 0, 0, 0.5)">
                  {`${__(messages.publishedTime)} ${publicationDate}`}
                </Typography>
              </Stack>
            </Box>

            {ability?.can("Update", "OfferApplications") && (
              <Box>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    toggleMenu(e);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </IconButton>

                <Menu
                  open={open}
                  onClose={toggleMenu}
                  anchorEl={anchorEl}
                  elevation={1}
                  MenuListProps={{
                    sx: {
                      padding: 0,
                      borderRadius: "6px",
                    },
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <MenuItem
                    onClick={e => {
                      openForm(offerId);
                      toggleMenu(e);
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      gap: ".7rem",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography variant="subtitle2">Modifier l’offre</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Stack>
          <TabsPage tabsContent={tabs} removeLastTabRight />
        </Stack>
      )}

      <ContainerPages padding={"5px 5px"}>
        <Outlet />
      </ContainerPages>

      {Form}
    </Box>
  );
};
export default OfferApplicationDetailsLayout;
