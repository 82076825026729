import { useGetCurrentEmployeeQuery } from "@/store/employee/endpoints";

const useCurrentUserHook = () => {
  const { data: currentEmployee, isLoading } = useGetCurrentEmployeeQuery(undefined, {
    skip: location.pathname.includes("logout-session"),
  });

  if (isLoading)
    return {
      isLoadingUser: isLoading,
      openUserModal: false,
    };

  const hasEnterpriseRecords = (currentEmployee?.enterpriseRecords ?? []).length > 0;
  const hasJobTitle = currentEmployee?.jobTitle;
  const hasExpertises = (currentEmployee?.expertises ?? []).length > 0;
  const hasSubActivityDomain = currentEmployee?.subActivityDomain;
  const hasValidPhoneNumber = currentEmployee?.phone1Valid;

  const openUserModal = Boolean(!(hasEnterpriseRecords && hasJobTitle && hasExpertises && hasSubActivityDomain && hasValidPhoneNumber));
  return { openUserModal };
};

export default useCurrentUserHook;
