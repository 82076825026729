import { defineMessages } from "react-intl";
const intl_scope = "dateTime";

export const dateTimemessages = defineMessages({
  publishedYear: {
    id: `${intl_scope}.publishedYear`,
    defaultMessage:
      "{value, plural, one {Publiée il y a {value} an.} other {Publiée il y a {value} ans.}}",
  },
  publishedMonth: {
    id: `${intl_scope}.publishedMonth`,
    defaultMessage:
      "{value, plural, one {Publiée il y a {value} mois.} other {Publiée il y a {value} mois.}}",
  },
  publishedDay: {
    id: `${intl_scope}.publishedDay`,
    defaultMessage:
      "{value, plural, one {Publiée il y a {value} jour.} other {Publiée il y a {value} jours.}}",
  },
  publishedHour: {
    id: `${intl_scope}.publishedHour`,
    defaultMessage:
      "{value, plural, one {Publiée il y a {value} heure.} other {Publiée il y a {value} heures.}}",
  },
  publishedMinute: {
    id: `${intl_scope}.publishedMinute`,
    defaultMessage:
      "{value, plural, one {Publiée il y a {value} minute} other {Publiée il y a {value} minutes}}",
  },
  yearValue: {
    id: `${intl_scope}.yearValue`,
    defaultMessage: "{value , plural , one {{value} an} other {{value} ans}}",
  },
  monthValue: {
    id: `${intl_scope}.monthValue`,
    defaultMessage: "{value , plural , one {{value} mois} other {{value} mois}}",
  },
  dayValue: {
    id: `${intl_scope}.dayValue`,
    defaultMessage: "{value , plural , one {{value} jour} other {{value} jours}}",
  },
  minuteValue: {
    id: `${intl_scope}.minuteValue`,
    defaultMessage: "{value , plural , one {{value} minute} other {{value} minutes}}",
  },
  isYearPlural: {
    id: `${intl_scope}.isYearPlural`,
    defaultMessage: "{value , plural , one {an} other {ans}}",
  },
  isMonthPlural: {
    id: `${intl_scope}.isMonthPlural`,
    defaultMessage: "{value , plural , one {mois} other {mois}}",
  },
  isDayPlural: {
    id: `${intl_scope}.isDayPlural`,
    defaultMessage: "{value , plural , one {jour} other {jours}}",
  },
  isHourPlural: {
    id: `${intl_scope}.isHourPlural`,
    defaultMessage: "{value , plural , one {heure} other {heures}}",
  },
  isMinutePlural: {
    id: `${intl_scope}.isMinutePlural`,
    defaultMessage: "{value , plural , one {minute} other {minutes}}",
  },
});
