import { getIn } from "formik";

export const isRequiredField = (validationSchema: any, name: string) => {
  const schemaDescription = validationSchema.describe();
  const accessor = name.split(".").join(".fields.");
  const field = getIn(schemaDescription?.fields, accessor);
  if (!field) {
    return false;
  }
  const isRequired = field.tests.some((test: any) => test.name === "required" || test.name === "min");
  return isRequired;
};

