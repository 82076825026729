import { Box } from "@mui/material";
import { FormikErrors } from "formik";
import { useState } from "react";
import ReactSelect, { Props as ReactSelectProps } from "react-select";
import theme from "@/theme/theme";

interface FloatingLabelSelectProps<T> extends ReactSelectProps<T> {
  label?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null; // Include errorText here
}

const OutlinedReactSelect = <T,>({
  label,
  error,
  onBlur: propOnBlur,
  ...props
}: FloatingLabelSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {label && (
        <label
          style={{
            position: "absolute",
            left: "12px",
            top: "-8px",
            fontSize: "12px",
            background: props?.isDisabled
              ? "transparent"
              : "linear-gradient(to bottom, transparent 50%, #fff 50%)",
            padding: "0 4px",
            height: "14px",
            transition: "all 0.2s ease-in-out",
            zIndex: 4,
            fontWeight: 600,
            color: isFocused ? theme.palette.primary.main : props?.isDisabled ? "#999" : "#000",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {label}
        </label>
      )}

      <ReactSelect
        styles={{
          control: (baseStyles: any, state) => ({
            ...baseStyles,
            fontSize: "14px",
            zIndex: 2,
            "&:hover": {
              borderColor: state.isFocused ? theme.palette.primary.main : baseStyles.borderColor,
            },
            borderColor: state.isFocused ? theme.palette.primary.main : baseStyles.borderColor,
            boxShadow: state.isFocused
              ? `0 0 0 1px ${theme.palette.primary.main}`
              : baseStyles.boxShadow,
          }),
          menu: (provided: any) => ({
            ...provided,
            height: "150px",
            zIndex: 6,
          }),
          menuList: (provided: any) => ({
            ...provided,
            height: "150px",
            zIndex: 6,
          }),
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={event => {
          setIsFocused(false);
          propOnBlur && propOnBlur(event);
        }}
        {...props}
      />
      {error && (
        <span style={{ color: theme.palette.error.main, fontSize: "12px" }}>{error as string}</span>
      )}
    </Box>
  );
};

export default OutlinedReactSelect;
