import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  name: string;
  id: string;
  taskId: string;
  time: string;
}[] = [];

const fakeFilesSlice = createSlice({
  name: "fakeComments",
  initialState,
  reducers: {
    addFile: (state, action: PayloadAction<(typeof initialState)[0]>) => {
      state.push(action.payload);
    },
    deleteFile: (state, action: PayloadAction<string>) => {
      return state
        .map((file) => (file.id === action.payload ? undefined : file))
        .filter(Boolean) as typeof initialState;
    },
  },
});

export const { addFile, deleteFile } = fakeFilesSlice.actions;

export default fakeFilesSlice.reducer;
