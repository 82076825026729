import { ApiEndpointBuilder } from '@/services/api/types';
import { DataResponse } from '@/types/reponse-data';
import {AdvancedFilter} from '@/types/search-request';
import {Expertise} from "@/types/profile";

const searchExpertiseDomainEndpoint = (builder: ApiEndpointBuilder) => {
    return builder.query<DataResponse<Expertise>, SearchRequest>({
        query: (request) => ({
            url: '/expertisedomains/search',
            method: 'POST',
            body: request,
        }),
    });
}

export default searchExpertiseDomainEndpoint;

export interface SearchRequest {
    advancedSearch?: {
        fields: string[];
        keyword: string;
    };
    keyword?: string;
    advancedFilter?: AdvancedFilter;
    pageNumber?: number;
    pageSize?: number;
    orderBy?: string[];
    type?: number;
    feedbackState?: number;
}
