import { UserRepository } from "@amityco/ts-sdk";
import useLiveObject from "./useAmityLiveObject";
import { useGetCurrentProfileQuery } from "@/store/profile/endpoints";

export default function useFollowRequests() {
  const { data: currentUser } = useGetCurrentProfileQuery();
  const {
    data: followRequests,
    error,
    initialLoading: loading,
  } = useLiveObject({
    fetcher: UserRepository.Relationship.getFollowers,
    params: { status: "pending", userId: currentUser?.id as string },
  });

  const acceptFollowRequest = (userId: string) => {
    return UserRepository.Relationship.acceptMyFollower(userId);
  };
  const rejectFollowRequest = (userId: string) => {
    return UserRepository.Relationship.declineMyFollower(userId);
  };

  return { followRequests, error, loading, acceptFollowRequest, rejectFollowRequest };
}
