import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  ToolbarType,
} from "@syncfusion/ej2-react-richtexteditor";
// import { registerLicense } from '@syncfusion/ej2-base';
import theme from "@/theme/theme";
import { Box, Typography } from "@mui/material";

// registerLicense(import.meta.env.VITE_APP_SYNCFUSION_LICENSE_KEY);

interface Props {
  hasError: boolean;
  height?: string;
  onChange?: (value: string) => void;
  initialValue?: string;
  onBlur?: () => void;
  errorText?: string;
  labelText?: string;
}

function CustomRichTextEditor({
  hasError = false,
  onChange,
  initialValue = "",
  height,
  onBlur,
  errorText,
  labelText = "",
}: Props) {
  const items = [
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "Outdent",
    "Indent",
    "|",
    "CreateLink",
    "Undo",
    "Redo",
  ];

  //Rich Text Editor ToolbarSettings
  const toolbarSettings = {
    type: ToolbarType.Expand,
    items: items,
    enableFloating: false,
  };

  let rteObj: RichTextEditorComponent;
  function change(): void {
    onChange && onChange(rteObj.getHtml());
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        "& .e-richtexteditor.e-rte-tb-expand": {
          border: hasError ? `1px ${theme.palette.error.main} solid` : "",
          borderRadius: "4px",
          overflow: "hidden",
        },
        "& .e-richtexteditor.e-rte-tb-expand .e-rte-content, .e-richtexteditor.e-rte-tb-expand .e-source-content":
          {
            borderBottom: hasError ? `none` : "",
          },
      }}
    >
      {labelText && (
        <Typography sx={{ marginLeft: "2px", fontWeight: 600, fontSize:"14px" }}>{labelText}</Typography>
      )}
      <RichTextEditorComponent
        toolbarSettings={toolbarSettings}
        ref={richtexteditor => {
          rteObj = richtexteditor!;
        }}
        saveInterval={1}
        change={change}
        value={initialValue}
        height={height}
        blur={onBlur}
      >
        <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
      </RichTextEditorComponent>
      {hasError && errorText && (
        <Typography sx={{ mt: "5px", fontSize: "12px" }} color={theme.palette.error.main}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
}
export default CustomRichTextEditor;
