import { FC } from "react";
import { Description, Link, Title, Type } from "@/components/error-handler-box/styles";
import logo from "@assets/images/ilan26Complete.svg";
import { Box, Button, Stack } from "@mui/material";
import { LogoIla26 } from "@/styles/style";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { resetTenant } from "@/store/tenants/tenantsSlice";
import Disconnect from "@assets/images/disconnected-error.svg";

type ErrorType =
  | "enterprise"
  | "user"
  | "userUnauthorized"
  | "invitation"
  | "subscriptionExpired"
  | "general"
  | "404";

const ErrorHandlerBox: FC<Props> = props => {
  const { errorType } = props;
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handelErrorNavigate = () => {
    if (errorType !== "subscriptionExpired") {
      dispatch(resetTenant());
      instance.logoutRedirect();
    } else {
      dispatch(resetTenant());
      window.location.href = "https://www.ila26.fr/";
    }
  };

  return (
    <Stack
      alignItems="center"
      height="100vh"
      px="25%"
      gap={2}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <LogoIla26 src={logo} />
      <img src={Disconnect} style={{ height: "60%" }} />

      <Box
        sx={{
          display: "flex",
          gap: "5px",
          maxWidth: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {errorType !== "user" && errorType !== "invitation" && errorType !== "userUnauthorized" ? (
          <>
            <Title>
              {errorType === "enterprise"
                ? "On ne trouve pas votre"
                : errorType === "subscriptionExpired"
                ? "Votre souscription a"
                : errorType === "404"
                ? ""
                : "Pas de panique !"}
            </Title>
            <Type>
              {errorType === "enterprise"
                ? "enterprise"
                : errorType === "subscriptionExpired"
                ? "expirée"
                : errorType === "404"
                ? "404 Error"
                : ""}
            </Type>
          </>
        ) : (
          <>
            <Type>
              {errorType === "user" || errorType === "userUnauthorized"
                ? "Utilisateur"
                : errorType === "invitation"
                ? "Invitation"
                : ""}
            </Type>
            <Title>
              {errorType === "user"
                ? "non trouvé"
                : errorType === "userUnauthorized"
                ? "non authorisé"
                : errorType === "invitation"
                ? "déjà acceptée"
                : ""}
            </Title>
          </>
        )}
      </Box>
      <Box sx={{ maxWidth: "70%", textAlign: "center" }}>
        <Description>
          {errorType === "enterprise" || errorType === "user"
            ? "Vous accéder a notre site web et acheter de nouvelles licences."
            : errorType === "invitation"
            ? "Vous avez déjà acceptée cette invitation, retournez à la page de login et ressayer de vous connecter"
            : errorType === "subscriptionExpired"
            ? "Votre souscription a expirée, vous pouvez accéder au site web d’ila26 et acheter une nouvelle souscription."
            : errorType === "404"
            ? "L'URL demandée n'a pas été trouvée sur ce serveur."
            : "Une erreur est produite veuillez checker avec votre Administrateur."}
        </Description>
        {(errorType === "enterprise" || errorType === "user") && (
          <Box sx={{ display: "flex", gap: "5px", flexWrap: "wrap", justifyContent: "center" }}>
            <Description> Vous pouvez visualiser ce</Description>
            <Link>tutoriel </Link>
            <Description> qui vous montre comment faire.</Description>
          </Box>
        )}
      </Box>
      {errorType !== "general" && errorType !== "404" && (
        <Button variant={"contained"} onClick={handelErrorNavigate}>
          {errorType !== "subscriptionExpired" ? "Retourner à la page login" : "Site web ila26"}
        </Button>
      )}
    </Stack>
  );
};

interface Props {
  errorType: ErrorType;
}

export default ErrorHandlerBox;
