import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locale } from './types';
import { LOCALES } from '@/i18n/locales';

const initialState: { locale: Locale } = {
  locale: localStorage.getItem('language') as Locale ?? LOCALES.FRENCH
};

const languageSlice = createSlice({
  name: 'Translation',
  initialState,
  reducers: {
      changeLanguage: (_, { payload }: PayloadAction<Locale>) => {
        localStorage.setItem('language', payload);
				return { locale: payload }
			}
  },
});

export const { changeLanguage } = languageSlice.actions
export const languageReducer = languageSlice.reducer
