import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Title = styled(Typography)(({}) => ({
  ["&"]: {
    fontWeight: "bold",
    fontSize: "36px",
    color: "black",
    marginTop: "10px",
    textAlign: "center",
  },
}));

export const Type = styled(Typography)(({ theme }) => ({
  ["&"]: {
    fontWeight: "bold",
    fontSize: "36px",
    color: theme.palette.primary.main,
    marginTop: "10px",
  },
}));

export const Description = styled(Typography)({
  ["&"]: {
    display: "flex",
    gap: "5px",
  },
});

export const Link = styled(Typography)({
  ["&"]: {
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
  },
});

