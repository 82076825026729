import { ApiEndpointBuilder } from "@/services/api/types";

const deleteEmployeeProfilePicture = (builder: ApiEndpointBuilder) => {
  return builder.mutation<string, Request>({
    query: ({ entityId }) => ({
      url: `/enterprisefiles/profile/${entityId}`,
      method: "DELETE",
    }),
  });
};

type Request = {
  entityId: string;
};

export default deleteEmployeeProfilePicture;
