import { ApiEndpointBuilder } from "@/services/api/types";
import { DataResponse } from "@/types/reponse-data";
import { SearchRequest } from "@/types/search-request";
import { ActivityDomain } from "@/types/activity-domain";

const searchActivityDomainEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<ActivityDomain>, SearchRequest>({
    query: request => ({
      url: "/enterpriseactivitydomain/search",
      method: "POST",
      body: request,
    }),
  });
};

export default searchActivityDomainEndpoint;
