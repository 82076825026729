import { Button, Modal, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { ButtonsBox, ModalBox } from "./styles";
import messages from "../messages";
import { useCallback } from "react";

type ConfirmationModalProps = {
  isOpen: boolean;
  toggle: () => void;
  okAction: (toggle: () => void) => void;
  okText?: string;
  cancelText?: string;
  content: string;
};

const ConfirmationModal = ({
  isOpen,
  toggle,
  okAction,
  content,
  cancelText,
  okText,
}: ConfirmationModalProps) => {
  const { formatMessage: __ } = useIntl();

  const confirmAndClose = useCallback(async () => {
    okAction(toggle);
    if (okAction.length < 1) {
      toggle();
    }
  }, [okAction, toggle]);

  return (
    <Modal open={isOpen} onClose={toggle}>
      <ModalBox>
        <Typography variant="h5">{content}</Typography>
        <ButtonsBox>
          <Button onClick={toggle}>{cancelText || __(messages.cancel)}</Button>
          <Button onClick={confirmAndClose} variant="contained">
            {okText || __(messages.confirm)}
          </Button>
        </ButtonsBox>
      </ModalBox>
    </Modal>
  );
};

export default ConfirmationModal;
