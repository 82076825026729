import { BoxIcon, classes } from "@/pages/layout/style";
import theme from "@/theme/theme";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ListItemIcon, styled } from "@mui/material";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListTextSideBar from "../list-item/ListTextSidebar";

interface IHorizontalListItem {
  title: string;
  link: string;
  matchFullLink?: boolean;
  iconName: string;
  key: number;
  notifCount?: number;
}

const classesListButtonSideBar = {
  isActive: "active",
};

export const NavbarItem = styled(NavLink)(({ theme }) => ({
  [`&`]: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 10px",
    position: "relative",
    marginRight: "20px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}1A`,
      color: `${theme.palette.primary.main}`,
      img: {
        filter:
          "brightness(0) saturate(100%) invert(18%) sepia(72%) saturate(3495%) hue-rotate(251deg) brightness(94%) contrast(95%)",
      },
    },
  },
  [`&.${classesListButtonSideBar.isActive}`]: {
    background: `${theme.palette.primary.main}1A`,
    color: theme.palette.primary.main,
    borderRadius: 3,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}1A`,
      color: `${theme.palette.background.default}`,
      img: {
        filter: "invert(1) brightness(2)",
      },
    },
  },

  [`&.${classesListButtonSideBar.isActive} .MuiListItemText-primary`]: {
    fontWeight: 700,
  },

  [`&.${classesListButtonSideBar.isActive} img`]: {
    filter: "invert(1) brightness(2)",
  },
}));

const HorizontalListItem: FC<IHorizontalListItem> = ({
  title,
  link,
  iconName,
  key,
  matchFullLink,
  notifCount = 0,
}) => {
  const location = useLocation();

  return (
    <NavbarItem
      to={link}
      className={({ isActive }) => (isActive ? classesListButtonSideBar.isActive : "")}
      key={key}
      sx={{
        "&": {
          position: "relative",
        },
        "&:hover": {
          borderRadius: 2,
          animation: "fadeInAnim 0.3s ease-in forwards",
          color: theme.palette.violet.main,
          "@keyframes fadeInAnim": {
            from: { backgroundColor: "transparent" },
            to: { backgroundColor: theme.palette.violet.light },
          },
          "& img": {
            filter:
              "brightness(0) saturate(100%) invert(18%) sepia(72%) saturate(3495%) hue-rotate(251deg) brightness(94%) contrast(95%)",
          },
        },
        [`&.${classesListButtonSideBar.isActive}`]: {
          background:
            "linear-gradient(90deg, rgba(45,91,255,1) 0%, rgba(77,60,225,1) 35%, rgba(92,45,211,1) 100%)",
          color: theme.palette.background.default,
          borderRadius: 2,
        },
      }}
    >
      {(matchFullLink ? location.pathname === link : location.pathname.includes(link)) && (
        <Box
          sx={{
            width: "4px",
            height: "35px",
            position: "absolute",
            top: "9px",
            left: "-2px",
            borderRadius: "4px",
          }}
        />
      )}
      <ListItemIcon sx={{ minWidth: 0, position: "relative" }}>
        {typeof iconName !== "string" ? (
          <BoxIcon className={classes.customIcon} sx={{ fontSize: "18px" }}>
            <FontAwesomeIcon icon={iconName as unknown as IconDefinition} />
          </BoxIcon>
        ) : (
          <>
            <img height="18px" src={iconName} alt="icon" />
          </>
        )}

        {notifCount > 0 && (
          <Box
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              backgroundColor: theme.palette.error.main,
              color: theme.palette.background.default,
              borderRadius: "50%",
              fontSize: "0.55rem !important",
              fontWeight: "bold",
              height: "18px",
              width: "18px",
              transform: "translateY(-8px) translateX(8px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {notifCount > 9 ? "9+" : notifCount}
          </Box>
        )}
      </ListItemIcon>
      <ListTextSideBar sx={{ mt: "8px" }} primary={title} />
    </NavbarItem>
  );
};

export default HorizontalListItem;
