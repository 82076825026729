import { ApiEndpointBuilder } from "@/services/api/types";
export enum TenantMembership {
  MEMBER,
  OWNER,
}
export enum TenantPlanType {
  BASIC,
  BUSINESS,
  ENTERPRISE,
}
export interface TenantType {
  id: string;
  name: string;
  isActive: boolean;
  isDefault: boolean;
  membership: TenantMembership;
  plan: TenantPlanType;
}

export interface CheckoutUrlRequestType {
  items: { priceId: string; quantity: number }[];
  localAccountId: string;
  referralCode: string | null;
}


export interface TenantDetailedType extends TenantType {
  usedLicence: number;
  avaialbleStorage: number;
  validUpto: string;
  quantity: number;
}

export const getTenantsList = (builder: ApiEndpointBuilder) => {
  return builder.query<TenantType[], void>({
    query: () => ({
      url: `/users/tenants`,
      method: "GET",
    }),
  });
};

export const getTenant = (builder: ApiEndpointBuilder) => {
  return builder.query<TenantDetailedType, string>({
    query: id => ({
      url: `/tenants/${id}`,
      method: "GET",
    }),
  });
};

// stripe portal

export const getStripePortalUrl = (builder: ApiEndpointBuilder) => {
  return builder.query<string, void>({
    query: () => ({
      url: `/checkout/portal`,
      method: "GET",
    }),
  });
};




const getCheckoutUrl = (builder: ApiEndpointBuilder) => {
  return builder.query<{ url: string | undefined }, CheckoutUrlRequestType>({
    query: ({ items, localAccountId, referralCode }) => ({
      url: `/checkout`,
      method: "POST",
      body: {
        checkoutItems: items,
        customerAzureObjectId: localAccountId,
        referralCode: referralCode,
        isWebsite: false,
      },
    }),
  });
};
export default getCheckoutUrl;

