import { Skeleton, Typography, Box } from "@mui/material";
import theme from "@/theme/theme";
import { useIntl } from "react-intl";
import { messages } from "@/pages/store/entreprise-page/entreprise-details-page/servicesOffers/messages";

export const FormLoadingSkeleton = ({ editMode }: { editMode: boolean }) => {
  const { formatMessage: __ } = useIntl();
  return (
    <Box display="flex" flexDirection="column" sx={{ width: "32vw" }}>
      {/* Header */}
      <Box
        sx={{
          backgroundColor: theme.palette.customGrey.dark,
          padding: "21px 24px",
          borderBottom: "1px solid #EEE",
          boxShadow: "0 2px 4px #fafafa",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography variant={"h5"} color={"default"} fontWeight={700}>
          {editMode ? __(messages.editServiceOffer) : __(messages.addOffer)}
        </Typography>
      </Box>
      {/* loaders */}
      <Box
        sx={{ padding: "10px 24px 0 24px", display: "flex", flexDirection: "column" }}
        gap="16px"
      >
        <Skeleton variant="rounded" height="40px" />
        <Skeleton variant="rounded" height="160px" />
        <Skeleton variant="rounded" height="40px" />
        <Skeleton variant="rounded" height="40px" sx={{ marginBottom: "10px" }} />
        <Skeleton variant="rounded" height="40px" />
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="rounded" height="40px" sx={{ flexGrow: 1, marginRight: "16px" }} />
          <Skeleton variant="rounded" height="40px" sx={{ width: "140px" }} />
        </Box>
        <Skeleton variant="rounded" height="40px" />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Skeleton
            variant="rounded"
            sx={{ width: "140px", marginRight: "16px", height: "40px" }}
          />
          <Skeleton variant="rounded" sx={{ width: "140px", height: "40px" }} />
        </Box>
      </Box>
    </Box>
  );
};
