import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  user: string;
  comment: string;
  id: string;
  time: string;
  taskId: string;
}[] = [];

const fakeCommentsSlice = createSlice({
  name: "fakeComments",
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<(typeof initialState)[0]>) => {
      state.push(action.payload);
    },
    deleteComment: (state, action: PayloadAction<string>) => {
      return state
        .map((comment) => (comment.id === action.payload ? undefined : comment))
        .filter(Boolean) as typeof initialState;
    },
  },
});

export const { addComment, deleteComment } = fakeCommentsSlice.actions;

export default fakeCommentsSlice.reducer;
