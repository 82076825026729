import { useCallback, useMemo, useState } from "react";
import { UserRepository } from "@amityco/ts-sdk";
import useFollowers from "./useFollowers";
import useFollowing from "./useFollowing";
import { intersection } from "lodash";
import useFollowRequests from "./useFollowRequests";
import { toast } from "react-hot-toast";

/**
 * @description useConnections is a custom hook that abstracts the usage of AmitySDK's one-directional relationship
 * to imitate a two-directional relationship, and hides the inherent complexity.
 *
 * This abstraction relies on a webhook on the backend to establish the second follow relationship
 * @see https://github.com/ILA-26/Ilan26/pull/1559
 *
 * @warning each declaration eg: useConnections() equals 5 fetches, don't use inside elements rendered under .map()
 */

export default function useConnections() {
  const { followers, loading: followersLoading } = useFollowers();
  const { followings, loading: followingLoading } = useFollowing();
  const { acceptFollowRequest, rejectFollowRequest: rejectConnectRequest } = useFollowRequests();

  const { followers: pendingFollowers } = useFollowers("pending");
  const { followings: pendingFollowings } = useFollowing("pending");

  const [newConnections, setNewConnections] = useState<string[]>([]);

  const connections = useMemo(() => {
    const followersIds = followers?.map(follower => follower.from) || [];
    const followingsIds = followings?.map(following => following.to) || [];
    return [...intersection(followersIds, followingsIds), ...newConnections];
  }, [followers, followings, newConnections]);

  const follow = useCallback(
    async (userId: string) => {
      const followersSet = new Set(followers.map(e => e.from));
      const followingsSet = new Set(followings.map(e => e.to));

      try {
        /**
         * Clean up
         * elimate one-directional relationships before establishing a new two-directional relationship
         */
        if (followingsSet.has(userId) && !followersSet.has(userId)) {
          await UserRepository.Relationship.unfollow(userId);
        } else if (followersSet.has(userId) && !followingsSet.has(userId)) {
          await UserRepository.Relationship.declineMyFollower(userId);
        }
      } catch (error: any) {
        console.error("Connection clean up error", error.toString());
      }

      try {
        await UserRepository.Relationship.follow(userId);
      } catch (error: any) {
        toast.error(error.toString());
      }
    },
    [followers, followings]
  );

  const unfollow = useCallback((userId: string) => {
    if (newConnections.length > 0) setNewConnections(prev => prev.filter(id => id !== userId));

    try {
      UserRepository.Relationship.unfollow(userId);
      UserRepository.Relationship.declineMyFollower(userId);
    } catch (error: any) {
      toast.error(error);
    }
  }, []);

  /**
   * We use optimistic update of connections because the backend takes a bit of time to create
   * the follow back relationship
   */
  const acceptConnectRequest = useCallback(
    async (userId: string) => {
      setNewConnections(prev => [...prev, userId]);
      try {
        await acceptFollowRequest(userId);
      } catch (error: any) {
        toast.error(error);
        setNewConnections(prev => prev.filter(id => id !== userId));
      }
    },
    [acceptFollowRequest]
  );

  const revokeConnectRequest = useCallback(async (userId: string) => {
    try {
      await UserRepository.Relationship.unfollow(userId);
    } catch (error: any) {
      toast.error(error);
    }
  }, []);

  const sentConnectRequests = useMemo(
    () => pendingFollowings?.map(following => following.to) || [],
    [pendingFollowings]
  );

  const receivedConnectRequests = useMemo(
    () => pendingFollowers?.map(follower => follower.from) || [],
    [pendingFollowers]
  );

  const getConnectionStatus = useCallback(
    (userId: string): ConnectionStatusType => {
      const connectionsSet = new Set(connections);
      const sentConnectRequestsSet = new Set(sentConnectRequests);
      const receivedConnectRequestsSet = new Set(receivedConnectRequests);

      if (connectionsSet.has(userId)) return "connected";
      if (sentConnectRequestsSet.has(userId)) return "sentPending";
      if (receivedConnectRequestsSet.has(userId)) return "receivedPending";
      return "none";
    },
    [connections, sentConnectRequests, receivedConnectRequests]
  );

  return {
    connections,
    sentConnectRequests,
    receivedConnectRequests,
    loading: followersLoading || followingLoading,
    sendConnectRequest: follow,
    revokeConnectRequest,
    removeConnection: unfollow,
    acceptConnectRequest,
    rejectConnectRequest,
    getConnectionStatus,
  };
}

export type ConnectionStatusType = "connected" | "sentPending" | "receivedPending" | "none";
