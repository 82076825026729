import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ChatPopupState {
  channelId: string | null;
}

const initialState: ChatPopupState = {
  channelId: null,
};

export const chatPopupState = createSlice({
  name: "chatpopup",
  initialState,
  reducers: {
    openChatPopup: (state, action: PayloadAction<string>) => {
      state.channelId = action.payload;
    },
    closeChatPopup: state => {
      state.channelId = null;
    },
  },
});

export const { openChatPopup, closeChatPopup } = chatPopupState.actions;
export default chatPopupState.reducer;
