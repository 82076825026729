import { api } from "@/services/api";
import { ApiEndpointBuilder } from "@/services/api/types";
import {
  OfferT,
  publishedOfferT,
  BErespT,
  OfferSkillsT,
  ApplicationRequest,
  OfferApplicationUser,
  SearchRequestOfferUserApplication,
  OfferApplication,
} from "@/types/offers";
import { DataResponse } from "@/types/reponse-data";
import { SearchRequest } from "@/types/search-request";
import { convertToLocalTime } from "@/utils/date/dayjs.config";

/**
 * this is handling the  - Granular RTK Query Tagging for optimal cache and queries management -
 * you can check its details in RTK Query Docs link below
 * {@link https://redux-toolkit.js.org/rtk-query/usage/automated-refetching}
 */
const tagsMapper = <T extends { id: string }>({
  queryResult,
  prefix,
}: {
  queryResult: DataResponse<T> | undefined;
  prefix: string;
}) => {
  const _fallbackTag = { type: prefix, id: "LIST" };
  if (!queryResult?.data) return [_fallbackTag];
  return [...queryResult.data.map(_item => ({ type: prefix, id: _item.id })), prefix];
};

const searchOffers = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<OfferT>, SearchRequest>({
    query: request => {
      const { HasMoreOfferApplicationThan, ...searchRequest } = request;
      const hasValidOfferCount = HasMoreOfferApplicationThan && +HasMoreOfferApplicationThan > -1;
      return {
        url: "/offers/search",
        method: "POST",
        body: {
          ...searchRequest,
          HasMoreOfferApplicationThan: hasValidOfferCount
            ? +HasMoreOfferApplicationThan
            : undefined,
        },
      };
    },
    transformResponse: (response: DataResponse<OfferT>) => {
      return {
        ...response,
        data: response.data.map(({ expirationDate, startDate, publicationDate, ...rest }) => ({
          ...rest,
          publicationDate: convertToLocalTime(publicationDate),
          expirationDate: convertToLocalTime(expirationDate),
          startDate: convertToLocalTime(startDate),
        })),
      };
    },
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return draft;
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    providesTags: result => {
      return result?.data
        ? [
            ...result.data.map((item: OfferT) => ({
              type: "offer",
              id: item.id,
            })),
            { type: "offerList" },
          ]
        : [{ type: "offerList" }];
    },
  });
};

const searchPublishedOffers = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<publishedOfferT>, SearchRequest>({
    query: payload => ({
      url: "/offers/published/search",
      method: "POST",
      body: payload,
    }),
    transformResponse: (response: DataResponse<publishedOfferT>) => {
      return {
        ...response,
        data: response.data.map(({ expirationDate, startDate, publicationDate, ...rest }) => ({
          ...rest,
          publicationDate: convertToLocalTime(publicationDate),
          expirationDate: convertToLocalTime(expirationDate),
          startDate: convertToLocalTime(startDate),
        })),
      };
    },
    providesTags: queryResult =>
      tagsMapper<publishedOfferT>({ prefix: "searchPublishedOffers", queryResult }),
  });
};

const getPublishedOfferDetails = (builder: ApiEndpointBuilder) => {
  return builder.query<OfferT, { offerId: string }>({
    query: ({ offerId }) => ({
      url: `/offers/published/${offerId}`,
      method: "GET",
    }),
    transformResponse: ({ publicationDate, startDate, expirationDate, ...rest }: OfferT) => ({
      ...rest,
      publicationDate: convertToLocalTime(publicationDate),
      startDate: convertToLocalTime(startDate),
      expirationDate: convertToLocalTime(expirationDate),
    }),
    providesTags: (__, ___, { offerId }) => [
      {
        type: "getPublishedOfferDetails",
        id: offerId,
      },
    ],
  });
};

const searchPublishedOffersInfinite = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<publishedOfferT>, SearchRequest>({
    query: payload => ({
      url: "/offers/published/search",
      method: "POST",
      body: payload,
    }),
    serializeQueryArgs: () => "getPublishedOffersInfinite",

    transformResponse: (response: DataResponse<publishedOfferT>) => {
      return {
        ...response,
        data: response.data.map(({ expirationDate, startDate, publicationDate, ...rest }) => ({
          ...rest,
          publicationDate: convertToLocalTime(publicationDate),
          expirationDate: convertToLocalTime(expirationDate),
          startDate: convertToLocalTime(startDate),
        })),
      };
    },
    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return { ...draft };
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    merge: (cachedResponse, response) => {
      if (response.currentPage === 0) {
        return {
          ...response,
          data: [...response.data],
        };
      }
      return {
        ...response,
        data: [...cachedResponse.data, ...response.data],
      };
    },
    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },
    providesTags: queryResult =>
      tagsMapper<publishedOfferT>({ prefix: "searchPublishedOffers", queryResult }),
  });
};

const searchOffersSkills = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<OfferSkillsT>, SearchRequest>({
    query: payload => ({
      url: "/offers/skills/search",
      method: "POST",
      body: payload,
    }),
    providesTags: queryResult =>
      tagsMapper<OfferSkillsT>({ prefix: "searchOfferSkills", queryResult }),
  });
};

const getOfferById = (builder: ApiEndpointBuilder) => {
  return builder.query<publishedOfferT, { id: string }>({
    query: ({ id }) => ({
      url: `/offers/${id}`,
      method: "GET",
    }),
    transformResponse: ({
      publicationDate,
      startDate,
      expirationDate,
      ...rest
    }: publishedOfferT) => ({
      ...rest,
      publicationDate: convertToLocalTime(publicationDate),
      startDate: convertToLocalTime(startDate),
      expirationDate: convertToLocalTime(expirationDate),
    }),
    providesTags: (_, __, { id }) => [{ type: "getOfferById", id }],
  });
};

const updateOffer = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, { payload: OfferT & { offerId: string }; id: string }>({
    query: ({ payload, id }) => ({
      url: `/offers/${id}`,
      method: "PUT",
      body: payload,
    }),

    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      const patchFetchResult = dispatch(
        offersAPI.util.updateQueryData("searchOffers", {}, draftList => {
          return {
            ...draftList,
            data: [
              ...(draftList?.data ?? []).map(x =>
                x.id === args?.id
                  ? {
                      ...args.payload,
                      id: args?.id,
                      expirationDate: new Date(args?.payload?.expirationDate).toDateString(),
                      publicationDate: new Date(args?.payload?.startDate).toDateString(),
                      status: 0,
                      offerTypeId: args.payload?.typeId || "",
                    }
                  : x
              ),
            ],
          };
        })
      );

      try {
        await queryFulfilled;
      } catch (err) {
        patchFetchResult.undo();
      }
    },

    invalidatesTags: (_r, _e, arg) => [{ type: "getPublishedOfferDetails", id: arg.id }],
  });
};

const deleteOffer = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, { id: string }>({
    query: ({ id }) => ({
      url: `/offers/${id}`,
      method: "DELETE",
    }),

    async onQueryStarted(args, { dispatch, queryFulfilled }) {
      const patchResult = dispatch(
        offersAPI.util.updateQueryData("searchOffers", {}, draftList => {
          return {
            ...draftList,
            data: [...(draftList?.data ?? []).filter(obj => obj.id != args?.id)],
          };
        })
      );

      try {
        await queryFulfilled;
      } catch (err) {
        patchResult.undo();
      }
    },
  });
};

const createOffer = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, OfferT>({
    query: payload => ({
      url: `/offers`,
      method: "POST",
      body: payload,
    }),

    async onQueryStarted(args, { queryFulfilled, dispatch }) {
      try {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(
            offersAPI.util.updateQueryData("searchOffers", {}, draftList => {
              draftList.data.unshift({
                ...args,
                id: data,
                expirationDate: new Date(args.expirationDate).toDateString(),
                publicationDate: new Date().toDateString(),
                status: 0,
              } as OfferT);
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    invalidatesTags: ["searchPublishedOffers"],
  });
};

const offerApplication = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, { offerId: string }>({
    query: payload => ({
      url: `/offerapplications`,
      method: "POST",
      body: payload,
    }),
    invalidatesTags: (queryResult, _, arg) => {
      return typeof queryResult === "string"
        ? [{ type: "getPublishedOfferDetails", id: arg.offerId }, { type: "offerApplicationsList" }]
        : [];
    },
  });
};

const offerApplicationFile = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, ApplicationRequest>({
    query: request => ({
      url: `/offerapplications/file/${request.offerApplicationId}`,
      method: "POST",
      body: request,
    }),
  });
};

const searchOfferApplicationEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<OfferApplicationUser>, SearchRequestOfferUserApplication>({
    query: request => {
      const { offerId: _, ...searchRequest } = request;
      return {
        url: `/offerapplications/search/offer/${request.offerId}`,
        method: "POST",
        body: searchRequest,
      };
    },
    transformResponse: (response: DataResponse<OfferApplicationUser>) => {
      return {
        ...response,
        data: response.data.map(({ createdOn, ...rest }) => ({
          ...rest,
          createdOn: convertToLocalTime(createdOn),
        })),
      };
    },
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },
    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return draft;
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    providesTags: result => {
      return result?.data
        ? [
            ...result.data.map((item: OfferApplicationUser) => ({
              type: "offerApplications",
              id: item.id,
            })),
            { type: "offerApplicationsList" },
          ]
        : [{ type: "offerApplicationsList" }];
    },
  });
};

const acceptOfferApplication = (builder: ApiEndpointBuilder) => {
  return builder.mutation<BErespT, { payload: { offerApplicationId: string }; id: string }>({
    query: ({ payload, id }) => ({
      url: `/offerapplications/${id}/accept`,
      method: "PUT",
      body: payload,
    }),
    invalidatesTags: (queryResult, _, arg) => {
      return typeof queryResult === "string"
        ? [{ type: "getPublishedOfferDetails", id: arg.id }, { type: "offerApplicationsList" }]
        : [];
    },
  });
};

const getOfferApplicationVerify = (builder: ApiEndpointBuilder) => {
  return builder.query<boolean, { offerId: string }>({
    query: ({ offerId }) => ({
      url: `/offerapplications/verify/${offerId}`,
      method: "GET",
    }),
    providesTags: (__, ___, { offerId }) => [
      {
        type: "getPublishedOfferDetails",
        id: offerId,
      },
    ],
  });
};

const searchMyOfferApplicationEndpoint = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<OfferApplication>, SearchRequest>({
    query: request => {
      return {
        url: "/offerapplications/search",
        method: "POST",
        body: request,
      };
    },
    transformResponse: (response: DataResponse<OfferApplication & { createdOn: string }>) => {
      return {
        ...response,
        data: response.data.map(({ createdOn, ...rest }) => ({
          ...rest,
          createdOn: convertToLocalTime(createdOn),
        })),
      };
    },
    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },
    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return draft;
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },
  });
};

const offersAPI = api.injectEndpoints({
  endpoints: build => ({
    searchOffers: searchOffers(build),
    searchPublishedOffers: searchPublishedOffers(build),
    getPublishedOfferDetails: getPublishedOfferDetails(build),
    searchOfferSkills: searchOffersSkills(build),
    getOfferById: getOfferById(build),
    getOfferApplicationVerify: getOfferApplicationVerify(build),
    createOffer: createOffer(build),
    updateOffer: updateOffer(build),
    acceptOfferApplication: acceptOfferApplication(build),
    deleteOffer: deleteOffer(build),
    offerApplication: offerApplication(build),
    offerApplicationFile: offerApplicationFile(build),
    getPublishedOffersInfinite: searchPublishedOffersInfinite(build),
    searchOfferApplication: searchOfferApplicationEndpoint(build),
    searchMyOfferApplication: searchMyOfferApplicationEndpoint(build),
  }),
});

export const {
  useSearchOffersQuery,
  useLazySearchOffersQuery,
  useSearchPublishedOffersQuery,
  useSearchOfferSkillsQuery,
  useGetOfferByIdQuery,
  useGetOfferApplicationVerifyQuery,
  useUpdateOfferMutation,
  useDeleteOfferMutation,
  useCreateOfferMutation,
  useLazyGetOfferByIdQuery,
  useAcceptOfferApplicationMutation,
  useOfferApplicationMutation,
  useOfferApplicationFileMutation,
  useGetPublishedOfferDetailsQuery,
  useGetPublishedOffersInfiniteQuery,
  useLazyGetPublishedOffersInfiniteQuery,
  useSearchOfferApplicationQuery,
  useLazySearchOfferApplicationQuery,
  useLazySearchMyOfferApplicationQuery,
} = offersAPI;
