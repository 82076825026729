import { AbilityBuilder } from "@casl/ability";
import { appAbility } from ".";
import { ProfileResponse } from "@/types/profile";

export const defineBasicAbility = (user: ProfileResponse) => {
  const { can, rules } = new AbilityBuilder(appAbility);

  can(["View"], ["Products", "Article", "Event",
    "Announcement", "Feedbacks", "Kanban", "Calendar",
    "Enterprise", "Support", "PersoLite", "Certificates", "Offers", "Signatures"]);

  can("Update", "Feedbacks", { assignedTo: user.id });

  can("Interact", ["EnterpriseReferents"]);

  return rules;
};

export const B_Ability = (user: ProfileResponse) =>
  new appAbility(defineBasicAbility(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });
