import { Box, styled } from "@mui/material";

export const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: "#fff",
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  gap: "2rem",
  flexDirection: "column",
  maxWidth: "30rem",
}));

export const ButtonsBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  gap: "10px",
});
