import theme from "@/theme/theme";
import { ObjectId } from "@/types/object-id";
import { CustomChip } from "@components/auto-complete/styles";
import {
  AutocompleteChangeDetails,
  Box,
  Autocomplete as MAutocomplete,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { SyntheticEvent, useRef, useState } from "react";
export interface Option {
  id: ObjectId;

  [key: string]: any;
}

interface AutocompleteProps {
  data: Option[];
  selectedValue: Option[] | Option;
  setSelectedValue: (value: Option[]) => void;
  multi?: boolean;
  label: string | JSX.Element;
  placeholder?: string;
  submitCount: number;
  errors: { [key: string]: string };
  name: string;
  optionLabel: string;
  id: string;
  disabled: boolean;
  sx?: any;
  attributes?: any;
  InputLabelStyle?: SxProps<Theme>;
  onTextValueChange?: (value?: string) => void;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  data,
  selectedValue,
  setSelectedValue,
  label,
  placeholder,
  submitCount,
  errors,
  name,
  optionLabel,
  id,
  disabled,
  attributes,
  InputLabelStyle,
  multi,
  onTextValueChange,
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    value: Option[],
    _reason: string,
    _details?: AutocompleteChangeDetails<Option> | undefined
  ) => {
    setSelectedValue(value);
    if (boxRef.current) {
      boxRef.current.scrollLeft = boxRef.current.scrollWidth;
    }
  };

  const isOptionEqualToValue = (option: Option, value: Option) => option.id === value.id;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <MAutocomplete
        id={id}
        options={data}
        getOptionLabel={(option: Option) => option[optionLabel] || ""}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={multi ? undefined : x => x}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            helperText={submitCount > 0 && errors[name] ? errors[name] : ""}
            error={submitCount > 0 && Boolean(errors[name])}
            label={label}
            onChange={
              !multi && onTextValueChange ? v => onTextValueChange(v.target.value) : undefined
            }
            placeholder={placeholder}
            sx={{
              minWidth: "15%",
              "& legend": {
                fontWeight: 600,
              },
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                fontWeight: 600,
                ...InputLabelStyle,
              },
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          return (
            <Box
              ref={boxRef}
              sx={{
                lineHeight: "1.25rem",
                display: "flex",
                maxWidth: "85%",
                overflowX: "auto",
                minHeight: "35px",
                flexWrap: attributes?.wrapped ? "wrap" : undefined,
                "&::-webkit-scrollbar": {
                  width: "1px",
                  height: "5px",
                  display: isHovered ? "block" : "none",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f0f0f0",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.primary.light,
                  cursor: "initial",
                },
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {value.map((option: any, index) => (
                <CustomChip
                  style={{ maxHeight: "30px" }}
                  label={option[optionLabel]}
                  {...getTagProps({ index })}
                />
              ))}
            </Box>
          );
        }}
        value={selectedValue}
        onChange={handleAutocompleteChange}
        size="small"
        multiple={multi}
        fullWidth
        disabled={disabled}
        ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
        {...attributes}
      />
    </>
  );
};

export default Autocomplete;
