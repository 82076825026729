import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const classesIcon = {
  danger: "bg-danger",
  success: "bg-success",
  warning: "bg-warning",
};

export const ValidationIcon = styled(FontAwesomeIcon)({
  ["&"]: {
    height: "20px",
    width: "20px",
    marginRight: "8px",
    cursor: "pointer",
  },
  [`&.${classesIcon.danger}`]: {
    color: "#cc1a36",
  },
  [`&.${classesIcon.success}`]: {
    color: "#25b255",
  },
  [`&.${classesIcon.warning}`]: {
    color: "#fac22d",
  },
});

