import { defineMessages } from "react-intl";

const intl_scope = "marketplace.servicesOffers";

export const messages = defineMessages({
  back: {
    id: `${intl_scope}.back`,
    defaultMessage: "Retour",
  },
  offerDescription: {
    id: `${intl_scope}.offerDescription`,
    defaultMessage: "Description de l’offre",
  },
  offerDetails: {
    id: `${intl_scope}.offerDetails`,
    defaultMessage: "Détails de l’offre",
  },
  opened: {
    id: `${intl_scope}.opened`,
    defaultMessage: "Ouverte",
  },
  closed: {
    id: `${intl_scope}.closed`,
    defaultMessage: "Fermée",
  },
  SiteAddress: {
    id: `${intl_scope}.SiteAddress`,
    defaultMessage: "Adresse du chantier",
  },
  expirationDate: {
    id: `${intl_scope}.expirationDate`,
    defaultMessage: "Date d’expiration de l’offre",
  },
  completionDeadline: {
    id: `${intl_scope}.completionDeadline`,
    defaultMessage: "Délai de réalisation",
  },
  offerDocuments: {
    id: `${intl_scope}.offerDocuments`,
    defaultMessage: "Documents de l’offre",
  },
  published: {
    id: `${intl_scope}.published`,
    defaultMessage: "Publié",
  },
  draft: {
    id: `${intl_scope}.draft`,
    defaultMessage: "Brouillon",
  },
  noOffer: {
    id: `${intl_scope}.noOffer`,
    defaultMessage: "Aucune offre pour l'instant",
  },
  offersLoadingError: {
    id: `${intl_scope}.offersLoadingError`,
    defaultMessage: "Erreur lors du chargement des offres !!!",
  },
  confirmDelete: {
    id: `${intl_scope}.confirmDelete`,
    defaultMessage: "Confirmer la suppression",
  },
  title: {
    id: `${intl_scope}.title`,
    defaultMessage: "Titre :",
  },
  address: {
    id: `${intl_scope}.address`,
    defaultMessage: "Adresse :",
  },
  cancel: {
    id: `${intl_scope}.cancel`,
    defaultMessage: "Annuler",
  },
  confirm: {
    id: `${intl_scope}.confirm`,
    defaultMessage: "Confirmer",
  },
  successOperation: {
    id: `${intl_scope}.successOperation`,
    defaultMessage: "Opération effectuée avec succès",
  },
  errorOperation: {
    id: `${intl_scope}.errorOperation`,
    defaultMessage: "Erreur lors de l'opération",
  },
  addOffer: {
    id: `${intl_scope}.addOffer`,
    defaultMessage: "Ajoutez une offre de sous-traitance",
  },
  addOffer2: {
    id: `${intl_scope}.addOffer2`,
    defaultMessage: "Ajoutez une offre",
  },
  servicesOffers: {
    id: `${intl_scope}.servicesOffers`,
    defaultMessage: "Offres de sous-traitance",
  },
  offerTitle: {
    id: `${intl_scope}.offerTitle`,
    defaultMessage: "Titre de l'offre",
  },
  fieldRequired: {
    id: `${intl_scope}.fieldRequired`,
    defaultMessage: "Champ requis",
  },
  publicationStatus: {
    id: `${intl_scope}.publicationStatus`,
    defaultMessage: "Statut de publication",
  },
  invalidValue: {
    id: `${intl_scope}.invalidValue`,
    defaultMessage: "Valeur invalide",
  },
  active: {
    id: `${intl_scope}.active`,
    defaultMessage: "Actif",
  },
  expireTomorrow: {
    id: `${intl_scope}.expireTomorrow`,
    defaultMessage: "Expire demain",
  },
  expired: {
    id: `${intl_scope}.expired`,
    defaultMessage: "Expiré",
  },
  title2: {
    id: `${intl_scope}.title2`,
    defaultMessage: "Titre",
  },
  durationMonths: {
    id: `${intl_scope}.durationMonths`,
    defaultMessage: "Durée en mois",
  },
  durationDays: {
    id: `${intl_scope}.durationDays`,
    defaultMessage: "Durée en jours",
  },
  expirationStatus: {
    id: `${intl_scope}.expirationStatus`,
    defaultMessage: "Statut d'expiration",
  },
  publicationDate: {
    id: `${intl_scope}.publicationDate`,
    defaultMessage: "Date de publication",
  },
  expirationDate2: {
    id: `${intl_scope}.expirationDate2`,
    defaultMessage: "Date d’expiration",
  },
  editServiceOffer: {
    id: `${intl_scope}.editServiceOffer`,
    defaultMessage: "Modifier une offre de sous-traitance",
  },
  maxCharacters: {
    id: `${intl_scope}.maxCharacters`,
    defaultMessage: "{value} caractères maximum",
  },
  skills: {
    id: `${intl_scope}.skills`,
    defaultMessage: "Compétences",
  },
  dates: {
    id: `${intl_scope}.dates`,
    defaultMessage: "Dates",
  },
  startDate: {
    id: `${intl_scope}.startDate`,
    defaultMessage: "Date de début",
  },
  requiredSkills: {
    id: `${intl_scope}.requiredSkills`,
    defaultMessage: "Sélectionnez les compétences requises pour cette offre",
  },
  description: {
    id: `${intl_scope}.description`,
    defaultMessage: "Description",
  },
  expirationDateValidation: {
    id: `${intl_scope}.expirationDateValidation`,
    defaultMessage: "La date d'expiration doit être supérieure à la date de début.",
  },
  sendMy: {
    id: `${intl_scope}.sendMy`,
    defaultMessage: "Envoyer ma",
  },
  application: {
    id: `${intl_scope}.application`,
    defaultMessage: "Candidature",
  },
  userApplied: {
    id: `${intl_scope}.userApplied`,
    defaultMessage: "Candidature envoyée",
  },
  deleteOfferError: {
    id: `${intl_scope}.deleteOfferError`,
    defaultMessage: "Veuillez fermer les candidatures avant de supprimer cette offre.",
  },
  localisation: {
    id: `${intl_scope}.localisation`,
    defaultMessage: "Localisation",
  },
  nbApplication: {
    id: `${intl_scope}.nbApplication`,
    defaultMessage: "Nombre de candidatures",
  },

  updateOffer: {
    id: `${intl_scope}.updateOffer`,
    defaultMessage: "Modifier l’offre",
  },

  deleteOffer: {
    id: `${intl_scope}.deleteOffer`,
    defaultMessage: "Supprimer l’offre",
  },
  Applicants: {
    id: `${intl_scope}.Applicants`,
    defaultMessage: "Candidateurs",
  },
  share: {
    id: `${intl_scope}.share`,
    defaultMessage: "Partager",
  },
  sharedSuccessfully: {
    id: `${intl_scope}.sharedSuccessfully`,
    defaultMessage: "L'offre de sous-traitance est partagée avec succès.",
  },
  shareError: {
    id: `${intl_scope}.shareError`,
    defaultMessage: "Erreur lors du partage de l'offre de sous-traitance.",
  },
  serviceOffer: {
    id: `${intl_scope}.serviceOffer`,
    defaultMessage: "Offre de sous-traitance",
  },
  activity: {
    id: `${intl_scope}.activity`,
    defaultMessage: "Activités",
  },
  speciality: {
    id: `${intl_scope}.speciality`,
    defaultMessage: "Spécialités",
  },
  publishedTime: {
    id: `${intl_scope}.publishedTime`,
    defaultMessage: "Publiée",
  },
});
