import { ApiEndpointBuilder } from "@/services/api/types";
import { Profile } from "@/types/profile";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const getEmployeeById = (builder: ApiEndpointBuilder) => {
  return builder.query<Profile, string | undefined>({
    async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
      // GETS EMPLOYEE DATA
      const employeeResponse1 = await fetchWithBQ(`/enterpriseemployees/public/${id}`); // search in public first
      if (employeeResponse1.data) {
        return { data: employeeResponse1.data as Profile }; // return early if found in public
      }
      // repeat for internal
      const employeeResponse2 = await fetchWithBQ(`/enterpriseemployees/internal/${id}`);
      if (employeeResponse2.data) {
        return { data: employeeResponse2.data as Profile };
      }

      return {
        error: (employeeResponse1?.error || employeeResponse2?.error) as FetchBaseQueryError,
      };
    },
    providesTags: res =>
      res ? [{ type: "Employee", id: res.id }] : [{ type: "Employee", id: "LIST" }],
  });
};
export default getEmployeeById;

