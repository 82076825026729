import { defineMessages } from "react-intl";

export const common = "common";

export default defineMessages({
  browse: {
    id: `${common}.browse`,
    defaultMessage: "Parcourir",
  },
  dragAndDrop: {
    id: `${common}.dragAndDrop`,
    defaultMessage: "Ou glisser-déposer",
  },
  disabled: {
    id: `${common}.disabled`,
    defaultMessage: " Désactivé",
  },
  accepts: {
    id: `${common}.accepts`,
    defaultMessage: "Fichiers acceptés",
  },
  readyToUpload: {
    id: `${common}.readyToUpload`,
    defaultMessage: " Ready to upload",
  },
});
