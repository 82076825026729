import { ContentCategory } from '@/types/content-category';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CategoryState {
  data: ContentCategory[] | null;
}

const initialState: CategoryState = {
  data: null,
};

const CategoriesSlice = createSlice({
  name: 'articleNews',
  initialState,
  reducers: {
    fetchCategories(state, action: PayloadAction<ContentCategory[]>) {
      state.data = action.payload;
    },
    addCategoryState(state, action: PayloadAction<ContentCategory>) {
      // Check if the state already has data, and if not, initialize it as an empty array
      if (!state.data) {
        state.data = [];
      }
      // Add the new article to the state's data array
      state.data.push(action.payload);
    },
    deleteCategoryState(state, action: PayloadAction<any>) {
      // Check if the state has data
      if (state.data) {
        // Find the index of the category with the specified ID
        const index = state.data.findIndex(
          (category) => category.id === action.payload
        );
        // If the category is found, remove it from the state's data array
        if (index !== -1) {
          state.data.splice(index, 1);
        }
      }
    },
  },
});

export const { fetchCategories, addCategoryState, deleteCategoryState } =
  CategoriesSlice.actions;
export default CategoriesSlice.reducer;
