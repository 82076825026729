import { ArticleOfNews } from "@/types/articles-of-news";
import { Feedback } from "@/types/feedback/feedback";
import { Document } from "@/types/file-manager/document";
import { Announcement } from "@/types/news/announcement";
import { Event } from "@/types/news/event";
import { Profile } from "@/types/profile";
import { Ability, AbilityClass } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { Consumer, createContext } from "react";

export interface Post {
  id: number;
  title: string;
  userId: number;
}

export type Actions =
  | "View" //includes["Search","View","SearchAll","Assign","Export"]
  | "Create" //includes["Create","Generate"]
  | "Delete" //includes["Delete","Clean"]
  | "Update" //includes["Update","UpdateOwner"]
  | "Interact" //includes["Comment","Reviews","Reaction"]
  | "Sign"
  | "Post"
  | "Post_As_Enterprise";

export type SubjectsNames =
  "Users" // includes ["Users","UserRoles", "Roles", "RoleClaims", "Tenants"]
  | "Products" // includes ["Products", "Brands", "EnterpriseProductType", "EnterpriseProductBrand", "EnterpriseProductLike", "EnterpriseProductReview", "EnterpriseProduct", "EnterpriseProductCategory"]
  | "Announcement"
  | "Comments"
  | "Kanban" // includes ["Projects", "ProjectStatuses", "CustomTasks", "KanbanFiles", "KanbanTags", "KanbanComments", "KanbanReactions"]
  | "Calendar" // includes ["Calendar", "Rseources.Skill", "Rseources.ActivityZone", "Rseources.ResourcesJobType", "Rseources.EmployeeReferral"]
  | "CalendarResources" // includes "Rseources.Room", "Rseources.Equipment"
  | "Enterprise" // includes ["EnterpriseFile", "EnterpriseCategory", "EnterpriseSubActivities"]
  | "EnterpriseReferents" // includes ["EnterpriseReferentComments", "ReferentCustomState", "EnterpriseReferentCommentReactions", "ProfileValidation"]
  | "EnterpriseProfile"
  | "Feedbacks"
  | "Feedback" // feedbacks assigned to me
  | "Employee"
  | "PersoLite" // includes ["PersoLiteZips", "PersoLiteEntreprises", "PersoLiteSharedZips", "PersoLiteSharedDirectories", "PersoLiteSharedDocuments", "PersoLiteSharedTags", "PersoLiteEntreprisesSharedDirectories", "PersoLiteUsers", "PersoLiteUsersSharedDirectories", "PersoLiteDirectories", "PersoLiteDocuments", "PersoLiteTags", "Zips"]
  | "Certificates" // includes ["Certificates","Signatures"]
  | "Offers" //includes ["Offer","OfferApplication.(* - searchAll)"]
  | "OfferApplications" // includes ["OfferApplication.SearchAll"]
  | "Support"
  | "Event"
  | "Article"
  | "Network"
  | "Signatures"
  | "News"
  | "Documents"
  | "SavPro";

export type SubjectsTypes = ArticleOfNews | Event | Feedback | Announcement | Document | Profile;

export type Subjects = SubjectsNames | SubjectsTypes;

export type AppAbility = Ability<[Actions, Subjects]>;

export const AbilityContext = createContext<AppAbility | undefined>(undefined);
export const appAbility = Ability as AbilityClass<AppAbility>;

export const Can = createContextualCan(
  AbilityContext.Consumer as Consumer<AppAbility>,
);

