import { createPortal } from "react-dom";
import { useCallback, useMemo, useState } from "react";
import ConfirmationModal from "@/components/confirmation-modal/v2";

/**
 * 
 * @param content: [optional*]: the text displayed on the modal body
 * @param okText: [optional]: the text on the confirm button
 * @param cancelText: [optional]: the text on the cancel button
 * @param okAction: [optional*]: the function to execute upon confirmation, takes "toggle" as param that allows developer to control when the modal is closed
 *                  
 * @note [optional*] specifies params that must be provided either on custom hook declaration or toggle function calling
 * 
 * @returns modalJSX: contains the JSX of the modal that will be injected at the <body> level
 *          toggle: function used to toggle modal into view, it can override any of the params provided on custom hook declaration
 */

const useConfirmationModal = ({ content, okAction, cancelText, okText }: Props = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [overrideOptions, setOverrideOptions] = useState<Props | undefined>(undefined);

  const toggle = useCallback(
    (options?: Props) => {
      if (!content && !options?.content) throw new Error(errorMessage("'content'"));
      if (!okAction && !options?.okAction) throw new Error(errorMessage("'okAction'"));

      if (options) setOverrideOptions(options);
      setIsOpen(prev => !prev);
    },
    [content, okAction]
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modalJSX = useMemo(
    () =>
      createPortal(
        <ConfirmationModal
          isOpen={isOpen}
          toggle={closeModal}
          content={content as string}
          cancelText={cancelText}
          okText={okText}
          okAction={okAction as () => void}
          {...overrideOptions}
        />,
        document.body
      ),
    [isOpen, closeModal, content, cancelText, okText, okAction, overrideOptions]
  );

  return { toggle, modalJSX };
};

const errorMessage = (argument: string) =>
  `useConfirmationModal: ${argument} is not defined on custom hook declaration nor toggle function call`;

type Props = {
  content?: string;
  okText?: string;
  cancelText?: string;
  okAction?: (toggle: () => void) => void;
};

export default useConfirmationModal;
