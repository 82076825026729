import { ApiEndpointBuilder } from "@/services/api/types";
import { Enterprise } from "@/types/store/profile";

const getCurrentEnterprise = (builder: ApiEndpointBuilder) => {
  return builder.query<Enterprise, any>({
    query: () => ({
      url: `/enterprises/current`,
      method: "GET",
    }),
    transformResponse: (enterpriseData: Enterprise) => ({
      ...enterpriseData,
      enterprisePictures: enterpriseData.enterprisePictures?.map(picture => ({
        ...picture,
        azureBlobUri: picture?.azureBlobUri
          ? `${picture?.azureBlobUri}?t=${new Date().getTime()}`
          : picture?.azureBlobUri,
      })),
    }),
    providesTags: ["Enterprise"],
  });
};
export default getCurrentEnterprise;
