import { useEffect, useState } from "react";
import { UserRepository } from "@amityco/ts-sdk";
import { useGetCurrentProfileQuery } from "@/store/profile/endpoints";

export default function useFollowing(
  status: "accepted" | "all" | "pending" | "blocked" = "accepted"
) {
  const { data: currentUser } = useGetCurrentProfileQuery();

  const [followings, setFollowings] = useState<Amity.FollowStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (!currentUser) return;
    const unsubscribe = UserRepository.Relationship.getFollowings(
      { userId: currentUser.id, limit: 200, status },
      ({ data: data, loading, error, hasNextPage, onNextPage, hasPrevPage }) => {
        if (error) {
          setError(error);
        }
        if (loading) {
          setLoading(true);
        }
        if (data) {
          setFollowings(prev => {
            if (data.length === 0) {
              return prev.length === 1 ? data : prev;
            }
            if (data.length === 1) {
              const followingsSet = new Set(prev.map(f => f.to));
              if (followingsSet.has(data[0].to)) {
                return data;
              } else {
                return [...prev, ...data];
              }
            }
            if (!hasPrevPage) {
              return data;
            } else {
              return [...prev, ...data];
            }
          });
        }
        if (hasNextPage) {
          onNextPage && onNextPage();
        } else {
          setLoading(false);
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return { followings, error, loading };
}
