import { defineMessages } from "react-intl";

export const servicesOffersScope = "marketplace.servicesOffers";

export default defineMessages({
  offer: {
    id: `${servicesOffersScope}.offer`,
    defaultMessage: "Toutes les offres",
  },
  ourOffer: {
    id: `${servicesOffersScope}.ourOffer`,
    defaultMessage: "Nos offres",
  },
  myApplications: {
    id: `${servicesOffersScope}.myApplications`,
    defaultMessage: "Mes candidatures",
  },
  headerTitle: {
    id: `${servicesOffersScope}.headerTitle`,
    defaultMessage: "Sous-traitances",
  },
  headerSubTitle: {
    id: `${servicesOffersScope}.headerSubTitle`,
    defaultMessage:
      "Recherchez des offres de sous-traitances dans votre domaine d’activité partout en France.",
  },
  buttonAction: {
    id: `${servicesOffersScope}.buttonAction`,
    defaultMessage: "Gérer mes sous-traitances",
  },
  buttonAddOffer: {
    id: `${servicesOffersScope}.buttonAddOffer`,
    defaultMessage: "Ajouter une offre",
  },
  offerDetails: {
    id: `${servicesOffersScope}.offerDetails`,
    defaultMessage: "Détails de l’offre",
  },
  applicationsReceived: {
    id: `${servicesOffersScope}.applicationsReceived`,
    defaultMessage: "Candidatures reçues",
  },
  Back: {
    id: `${servicesOffersScope}.Back`,
    defaultMessage: "Retour",
  },
  publishedTime: {
    id: `${servicesOffersScope}.publishedTime`,
    defaultMessage: "Publiée",
  },
});
