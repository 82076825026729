import { useCallback, useEffect, useState } from "react";

function useLiveObject<TParams, TCallback, TConfig>({
  fetcher,
  params,
  options,
}: {
  fetcher: (
    params: TParams,
    callback: Amity.LiveObjectCallback<TCallback>,
    options?: any
  ) => Amity.Unsubscriber;
  params: TParams;
  options?: Amity.LiveObjectOptions<TConfig>;
}) {
  const [item, setItem] = useState<TCallback | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const callbackFn: Amity.LiveObjectCallback<TCallback> = useCallback(response => {
    if (params == null) return;
    if (response.data) {
      setItem(response.data);
      setError(null);
    }
    if (response.error) setError(response.error);
    setInitialLoading(false);
  }, []);

  useEffect(() => {
    const unsubscribe = fetcher(params, callbackFn, options);
    return () => {
      unsubscribe();
    };
  }, []);

  return { data: item, error, initialLoading };
}

export default useLiveObject;
