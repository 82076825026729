import { ApiEndpointBuilder } from "@/services/api/types";
import { Employee } from "@/types/employee";
import { DataResponse } from "@/types/reponse-data";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const merge = <T extends Employee>(a: T[], b: T[]): T[] => {
  const idSet = new Set(a.map(item => item.id));
  const result = [...a];

  b.forEach(bItem => {
    if (!idSet.has(bItem.id)) {
      result.push(bItem);
      idSet.add(bItem.id);
    }
  });

  return result;
};

const getPublicAndInternalEmployees = (builder: ApiEndpointBuilder) => {
  return builder.query<DataResponse<Employee>, GetEmployeeQueryType & { scoreThreshold: number }>({
    queryFn: async (arg, _queryApi, _extraOptions, fetchWithBQ) => {
      const searchByName = {
        logic: "or",
        filters: [
          {
            field: "firstName",
            operator: "contains",
            value: arg?.debouncedSearch,
          },
          {
            field: "lastName",
            operator: "contains",
            value: arg?.debouncedSearch,
          },
        ],
      };

      const searchByJobType = {
        field: "jobtype.id",
        operator: "eq",
        value: arg?.selectedJobId,
      };

      const isFiltering = arg?.debouncedSearch || arg?.selectedJobId;

      const filters: any = [];

      if (arg?.debouncedSearch) {
        filters.push(searchByName);
      }

      if (arg?.selectedJobId) {
        filters.push(searchByJobType);
      }

      const allProvidedQuery = isFiltering
        ? {
            advancedFilter: {
              logic: "and",
              filters,
            },
          }
        : {};

      const requestBody = {
        ...allProvidedQuery,
        activityZones: arg?.selectedActivityZone ? [arg?.selectedActivityZone] : [],
        countryCode: arg?.countryCode,
        pageNumber: arg?.pageNumber,
        pageSize: arg?.pageSize ?? 50,
        enterpriseTenantId: arg?.enterpriseTenantId,
      };

      const [publicDataResponse, internalDataResponse] = await Promise.all([
        fetchWithBQ({
          url: "/enterpriseemployees/public/search",
          method: "POST",
          body: requestBody,
        }),
        fetchWithBQ({
          url: "/enterpriseemployees/internal/search",
          method: "POST",
          body: requestBody,
        }),
      ]);

      if (publicDataResponse.error) {
        return { error: publicDataResponse.error };
      }

      if (internalDataResponse.error) {
        return { error: internalDataResponse.error };
      }

      const publicData = publicDataResponse as QueryReturnValue<
        DataResponse<Employee>,
        FetchBaseQueryError
      >;
      const internalData = internalDataResponse as QueryReturnValue<
        DataResponse<Employee>,
        FetchBaseQueryError
      >;

      const mergedData = {
        ...publicData.data,
        data: merge(internalData?.data?.data || [], publicData?.data?.data || []),
        hasNextPage: publicData?.data?.hasNextPage || publicData?.data?.hasNextPage,
        totalCount: Math.max(
          publicData?.data?.totalCount || 0,
          internalData?.data?.totalCount || 0
        ),
      };

      return {
        data: mergedData as DataResponse<Employee>,
      };
    },

    serializeQueryArgs: ({ endpointName }) => {
      return endpointName;
    },

    async onQueryStarted(_arg, api) {
      try {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.updateCachedData(draft => {
            return { ...draft };
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    forceRefetch({ currentArg, previousArg }) {
      return currentArg !== previousArg;
    },

    // merge current cache with new data
    merge: (currentCache, newData) => {
      const cachePage = currentCache?.currentPage || 0;
      if (currentCache.data && newData?.currentPage && cachePage < +newData?.currentPage) {
        return {
          ...newData,
          data: [...currentCache.data, ...newData.data],
        };
      } else {
        return newData;
      }
    },

    providesTags: result =>
      result
        ? [
            ...(result?.data ?? []).map((item: Employee) => ({
              type: "publicAndInternalEmployee",
              id: item.id,
            })),
            { type: "publicAndInternalEmployee", id: "LIST" },
          ]
        : [{ type: "publicAndInternalEmployee", id: "LIST" }],
  });
};

export default getPublicAndInternalEmployees;

type GetEmployeeQueryType = {
  debouncedSearch?: string;
  selectedJobId?: string;
  tenantId?: string;
  countryCode?: string;
  pageNumber?: number;
  pageSize?: number;
  selectedActivityZone?: string | null;
  enterpriseTenantId?: string;
};
