import { ArticleOfNews } from '@/types/articles-of-news';
import { ObjectId } from '@/types/object-id';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ArticleOfNewsState {
  data: ArticleOfNews[] | null;
}

const initialState: ArticleOfNewsState = {
  data: null,
};

const articleOfNewsSlice = createSlice({
  name: 'articleNews',
  initialState,
  reducers: {
    fetchArticlesOfNews(state, action: PayloadAction<ArticleOfNews[]>) {
      state.data = action.payload;
    },
    addArticleOfNewsState(state, action: PayloadAction<ArticleOfNews>) {
      // Check if the state already has data, and if not, initialize it as an empty array
      if (!state.data) {
        state.data = [];
      }
      // Add the new article to the state's data array
      state.data.push(action.payload);
    },
    deleteArticleFromState(state, action: PayloadAction<ObjectId>) {
      if (state.data) {
        state.data = state.data.filter((news) => news.id !== action.payload);
      }
    },
  },
});

export const {
  fetchArticlesOfNews,
  addArticleOfNewsState,
  deleteArticleFromState,
} = articleOfNewsSlice.actions;
export default articleOfNewsSlice.reducer;
