import * as Yup from "yup";
import parsePhoneNumberFromString from "libphonenumber-js";

const numericPattern = /^\d+$/;
export const isNumeric = (str: string) => numericPattern.test(str);

export const PhoneValidationSchema = (invalidPhoneNumber: string, requiredMessage?: string) => {
  let schema = Yup.string().test("is-valid-phone-number", invalidPhoneNumber, function (value) {
    if (value === "" || value === undefined) {
      return true;
    } else if (!isNumeric(value.startsWith("+") ? value.slice(1) : value)) {
      return false;
    } else {
      const phoneValue = value.startsWith("+") ? value : "+" + value;
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneValue);
      if (parsedPhoneNumber) {
        return parsedPhoneNumber.isValid();
      }
      return false;
    }
  });

  return requiredMessage ? schema.required(requiredMessage) : schema;
};

//isOtpValidation: boolean,
export const PhoneObjectValidationSchema = (
  invalidOtpValidation: string,
  invalidPhoneNumber: string,
  requiredMessage?: string
) => {
  let schema = Yup.object().shape({
    phoneNumber: PhoneValidationSchema(invalidPhoneNumber, requiredMessage),
    otp: Yup.boolean().test("is-otp-validation", invalidOtpValidation, function (value) {
      return value;
    }),
    employeeId: Yup.string().required("required"),
  });
  return schema;
};
