import { TabProps, Tab, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

export const classesTabs = {
  changeTabPosition: "position-right",
  addingBorder: "add-border-bottom",
  selectedItemBg: "selected-item-bg",
};

export const LayoutTabs = styled(Box)(({ theme, height, fontSize }) => ({
  ["&"]: {
    display: "flex",
    justifyContent: "space-between",
    height: height || "50px",
    "& a": {
      paddingBlock: 0,
      height: height || "50px",
      fontSize: fontSize || "14px",
    },
    [`&.${classesTabs.addingBorder}`]: {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));

export const TabCustom = styled(Tab)<TabCustomProps>(({ theme }) => ({
  [`&`]: {
    fontWeight: 500,
    color: `${theme.palette.text.secondary}CC`,
    justifyContent: "start",
  },
  [`&.${classesTabs.changeTabPosition}`]: {
    marginLeft: "auto",
    "&:has(svg)": {
      top: "-12px",
    },
  },
  [`&.${classesTabs.selectedItemBg}`]: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary,
      fontWeight: 700,
    },
  },
}));

type TabCustomProps = {
  component?: typeof Link;
  to?: string;
} & TabProps;
