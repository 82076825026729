import useUser from "@/hooks/amity/useUser";
import useAmityChatPopup from "@/hooks/useAmityChatPopup";
import { networkIla26Endpoint } from "@/pages/layout/pagesListSideBar";
import { useGetEmployeeByIdQuery } from "@/store/employees/endpoints";
import theme from "@/theme/theme";
import { Employee } from "@/types/employee";
import { getInitials } from "@/utils/user-utilities";
import { Avatar, Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import messages from "../messages";
import { ICONS_ACTIONS } from "../utils";
import DeleteMenu from "../components/delete-menu";

export default function Connection({
  userId,
  removeFollower,
  search,
}: {
  userId: string;
  removeFollower: (userId: string) => void;
  search: string;
}) {
  const { formatMessage: __ } = useIntl();

  const navigate = useNavigate();
  const { data, isLoading } = useGetEmployeeByIdQuery(userId, {
    skip: !userId,
  });

  const { openAmityChatPopup } = useAmityChatPopup();
  const { user, loading } = useUser(userId);

  const id = userId;
  const firstName = user?.displayName?.split(" ")[0];
  const lastName = user?.displayName?.split(" ")[1];
  if (
    loading ||
    !user ||
    !user.displayName?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  )
    return null;

  return (
    <Box
      onClick={() =>
        !data && !isLoading
          ? navigate(`404`)
          : navigate(`${networkIla26Endpoint}/shared-resources/${userId}`)
      }
      sx={{ marginX: "30px", marginY: "10px" }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", gap: "13px", cursor: "pointer", height: "full", my: "auto" }}>
          <Box sx={{ height: "full", my: "auto" }}>
            <Avatar
              alt={user.displayName}
              src={user.avatar?.fileUrl}
              sx={{
                width: "65px",
                height: "65px",
                bgcolor: theme.palette.violet.contrastText,
                color: theme.palette.violet.main,
                fontSize: "30px",
                fontWeight: 700,
                imageRendering: "-webkit-optimize-contrast",
              }}
            >
              {user.displayName && getInitials(user.displayName, "").toUpperCase()}
            </Avatar>
          </Box>
          <Box
            sx={{
              height: "full",
              my: "auto",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              flexDirection: "column",
              gap: 0.3,
            }}
          >
            <Box>
              <Typography fontWeight={600} fontSize={"15px"} lineHeight={"1.1rem"}>
                {user.displayName}
              </Typography>

              {isLoading ? (
                <Skeleton sx={{ maxWidth: "150px", height: "13px" }} />
              ) : (
                <Typography fontWeight={500} fontSize={"12px"}>
                  {data?.jobTitle}
                </Typography>
              )}
            </Box>

            {isLoading ? (
              <Skeleton sx={{ maxWidth: "150px", height: "14px" }} />
            ) : (
              <Typography fontWeight={600} fontSize={"12px"}>
                {data?.enterprises?.map(obj => obj?.title).join(", ")}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifySelf: "center",
            gap: 1,
            height: "full",
            my: "auto",
            alignItems: "center"
          }}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              openAmityChatPopup({ id, firstName, lastName } as Employee);
            }}
            size="small"
            variant="outlined"
            sx={{
              py: 0.4,
              px: 1.4,
              textTransform: "none",
              minWidth: "90px",
            }}
            startIcon={
              <FontAwesomeIcon
                icon={ICONS_ACTIONS.message}
                size="xs"
                style={{
                  fontSize: "14px",
                }}
              />
            }
          >
            {__(messages.Message)}
          </Button>

          <DeleteMenu
            deleteAction={() => removeFollower(userId)}
            deleteLabel={__(messages.Delete)}
          />
        </Box>
      </Box>
      <Divider sx={{ marginTop: "10px" }} />
    </Box>
  );
}
