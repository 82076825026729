import { FC, useEffect, useState } from "react";
import {
  classesTimer, InformationIcon,
  Timer,
} from "./styles";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@components/tooltip";
import { useIntl } from "react-intl";
import messages from "./messages";

interface Props {
  duration: number;// This value should be specified in minutes.
  resend: boolean;
  setResend: (value: boolean) => void;
  expired: boolean;
  setExpired: (value: boolean) => void;
}

const TimerComponent: FC<Props> = (props) => {
  const { duration, resend, setResend, expired, setExpired } = props;
  const { formatMessage: __ } = useIntl();
  const [minutes, setMinutes] = useState(duration);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        setExpired(true);
        clearInterval(timer);
      } else if (seconds === 0) {
        setMinutes(prevMinutes => prevMinutes - 1);
        setSeconds(59);
      } else {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [duration, minutes, seconds]);


  useEffect(() => {
    if (!expired || resend) {
      setMinutes(duration);
      setSeconds(0);
      if(resend){
        setResend(false);
      }
    }
  }, [expired, resend]);

  return (
    <Timer className={minutes === 0 && seconds <= 10 ? classesTimer.danger : ""}>
      {minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0") + "s"}{" "}
      {minutes === 0 && seconds === 0 ? (
        <Tooltip title={__(messages.expired)}>
          <InformationIcon icon={faCircleInfo} />
        </Tooltip>
      ) : (
        <></>
      )}
    </Timer>
  );
};

export default TimerComponent;
