import { FC, ReactElement, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { messages } from './messages';
import { flattenObject } from '@/utils/misc';

const IntlMessages: FC<Props> = (props) => {
  const { children } = props;
  const locale = useSelector((state: RootState) => state.language.locale); 
  return (
    <IntlProvider locale={locale} messages={flattenObject(messages[locale])}>
      {children}
    </IntlProvider>
  );
};

interface Props {
  children: React.JSX.Element | ReactElement | ReactNode;
}

export default IntlMessages;
