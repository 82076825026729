import logo from "@assets/images/ilan26Complete.svg";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box"; // Assuming you are using Material-UI

const BlockedDevices = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
      }}
    >
      <img src={logo} height={20} width={80} loading="lazy" />
      <Box sx={{ display: "flex", margin: "5px 0px" }}>
        <Typography sx={{ marginX: 2, textAlign: "center" }}>
          ila26 n'est pas encore accessible depuis les mobiles. Veuillez utiliser un ordinateur pour
          accéder à toutes les fonctionnalités.
        </Typography>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${theme.palette.text.primary}`,
          width: "fit-content",
          borderRadius: "16px",
          height: "35px",
          margin: ".5rem 0px",
          padding: "1rem",
        }}
      >
        <FontAwesomeIcon icon={faApple} style={{ margin: "0px 5px" }} />
        <Link sx={{ all: "unset" }}>
          <Typography sx={{ marginRight: "4px" }}>App store</Typography>
        </Link>
      </Box>
      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: `1px solid ${theme.palette.text.primary}`,
          width: "fit-content",
          borderRadius: "16px",
          height: "35px",
          margin: ".5rem 0px",
        }}
      >
        <FontAwesomeIcon icon={faAndroid} style={{ margin: "0px 5px" }} />
        <Typography sx={{ marginRight: "4px" }}>Play store</Typography>
      </Box> */}
    </Box>
  );
};

export default BlockedDevices;
