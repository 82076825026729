import { Box, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ContainerPages = styled(Box)(({ padding }) => ({
  padding: `${padding}`,
}));

export const IconFontCardList = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    width: "16px",
    height: "16px",
  },
}));

export const IconFontBadge = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    width: "20px",
    height: "20px",
    margin: "3px",
  },
}));

export const IconFontWhiteBadge = styled(FontAwesomeIcon)({
  ["&"]: {
    fontSize: "24px",
    marginTop: 4,
    marginLeft: 6,
    color: "white",
  },
});

export const IconFontDark = styled(FontAwesomeIcon)({
  ["&"]: {
    fontSize: "18px",
    color: "black",
    padding: 1,
  },
});

export const IconFontBig = styled(FontAwesomeIcon)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.primary.main,
    height: "68px",
    width: "68px",
  },
}));

export const IconFontInfo = styled(FontAwesomeIcon)(() => ({
  ["&"]: {
    height: "35px",
    width: "35px",
  },
}));

export const DescriptionBox = styled(Box)(({ theme }) => ({
  ["&"]: {
    backgroundColor: "#EDE7F9",
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: 5,
    padding: 40,
    color: theme.palette.primary.dark,
  },
}));

export const TextError = styled(Typography)(({ theme }) => ({
  ["&"]: {
    color: theme.palette.error.main,
    marginLeft: "10px",
    marginTop: "5px",
  },
}));

export const LightChip = styled(Chip)(({ theme }) => ({
  ["&"]: {
    backgroundColor: "#EDE7F9",
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
    margin: "4px",
  },
}));
