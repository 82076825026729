import { defineMessages } from 'react-intl';

export const scope = 'taskManagement.confirmModal';


export default defineMessages({
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: "Annuler"
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: "Confirmer"
  },
});