import { api } from '@/services/api';
import searchExpertiseDomainEndpoint from './search-expertise-domains';


export const expertiseDomainApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchExpertiseDomain: searchExpertiseDomainEndpoint(build),

  }),
});

export const { useSearchExpertiseDomainQuery } = expertiseDomainApi;
