import { api } from "@/services/api";
import searchSubActivityDomainEndpoint from "./search-enterprise-sub-activity-domain.ts";
import searchEmployeeSubActivityDomainEndpoint from "./search-employee-sub-activity-domain.ts";

export const subActivityDomainApi = api.injectEndpoints({
  endpoints: build => ({
    searchSubActivityDomain: searchSubActivityDomainEndpoint(build),
    searchEmployeeSubActivityDomain: searchEmployeeSubActivityDomainEndpoint(build),
  }),
});

export const {
  useSearchSubActivityDomainQuery,
  useLazySearchSubActivityDomainQuery,
  useSearchEmployeeSubActivityDomainQuery,
} = subActivityDomainApi;
