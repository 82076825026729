import { AbilityBuilder } from "@casl/ability";
import { appAbility } from ".";
import { ProfileResponse } from "@/types/profile";
import { defineBasicAbility } from "@/casl/basicAbilities";
import { defineCommunityManagerAbility } from "@/casl/communityManagerAbilities";

export const defineCommercialAbility = (_user: ProfileResponse) => {
  const { can, rules } = new AbilityBuilder(appAbility);

  can(["View", "Create", "Update"], ["OfferApplications"]);
  can(["View", "Create", "Update", "Delete"], ["Offers"]);
  can("Post", ["Network", "Offers", "Products"]);
  can(["Create", "View", "Update", "Delete"], "Products");

  return rules;
};

//Commercial && Basic
export const defineBC_Ability = (user: ProfileResponse) => {
  const B_Rules = defineBasicAbility(user);
  const C_Rules = defineCommercialAbility(user);

  const combinedRules = [...B_Rules, ...C_Rules];

  return combinedRules;
};

//Commercial && Manager
export const defineCM_Ability = (user: ProfileResponse) => {
  const C_Rules = defineCommercialAbility(user);
  const M_Rules = defineCommunityManagerAbility(user);

  const combinedRules = [...M_Rules, ...C_Rules];

  return combinedRules;
};

//Commercial && Manager && Basic
export const defineCMB_Ability = (user: ProfileResponse) => {
  const CM_Rules = defineCM_Ability(user);
  const B_Rules = defineBasicAbility(user);

  const combinedRules = [...B_Rules, ...CM_Rules];

  return combinedRules;
};

export const C_Ability = (user: ProfileResponse) =>
  new appAbility(defineCommercialAbility(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const BC_Ability = (user: ProfileResponse) =>
  new appAbility(defineBC_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const CM_Ability = (user: ProfileResponse) =>
  new appAbility(defineCM_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });

export const CMB_Ability = (user: ProfileResponse) =>
  new appAbility(defineCMB_Ability(user), {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    detectSubjectType: (object: any) => object.__caslSubjectType__,
  });
