import { api } from "@/services/api";
import getEmployeeById from "./get-employee-by-id";
import getInternalEmployees from "./get-internal-employees";
import getPublicEmployees from "./get-public-employees";
import getPublicAndInternalEmployees from "./get-public-and-internal-employees";

export const categoryApi = api.injectEndpoints({
  endpoints: build => ({
    getEmployeeById: getEmployeeById(build),
    getInternalEmployees: getInternalEmployees(build),
    getPublicEmployees: getPublicEmployees(build),
    getPublicAndInternalEmployees: getPublicAndInternalEmployees(build),
  }),
});

export const {
  useGetEmployeeByIdQuery,
  useGetInternalEmployeesQuery,
  useLazyGetInternalEmployeesQuery,
  useGetPublicEmployeesQuery,
  useLazyGetPublicEmployeesQuery,
  useGetPublicAndInternalEmployeesQuery,
  useLazyGetPublicAndInternalEmployeesQuery,
} = categoryApi;
