import { useGetCurrentProfileQuery } from "@/store/profile/endpoints";
import {
  A_Ability,
  AB_Ability,
  AC_Ability,
  ACB_Ability,
  ACM_Ability,
  ACMB_Ability,
  AM_Ability,
  AMB_Ability,
} from "@/casl/adminAbilities";
import { BC_Ability, C_Ability, CM_Ability, CMB_Ability } from "@/casl/commercialAbilities";
import { BM_Ability, M_Ability } from "@/casl/communityManagerAbilities";
import { ProfileResponse } from "@/types/profile";
import { B_Ability } from "@/casl/basicAbilities";
import {
  ABS_Ability,
  ACBS_Ability,
  ACMBS_Ability,
  ACMS_Ability,
  ACS_Ability,
  AMBS_Ability,
  AMS_Ability,
  AS_Ability,
  BCS_Ability,
  BMS_Ability,
  BS_Ability,
  CMBS_Ability,
  CMS_Ability,
  CS_Ability,
  MS_Ability,
  S_Ability,
} from "@/casl/savProAbilities.ts";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

type Roles = "Basic" | "Commercial" | "CommunityManager" | "SavPro" | "Admin";

const useAppAbility = () => {
  const { shouldSelectTenant, tenantId } = useSelector((state: RootState) => state.tenant);
  const {
    data: currentUser,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetCurrentProfileQuery(undefined, {
    skip: !tenantId || shouldSelectTenant || window.location.pathname.includes("logout-session"),
  });

  if (isLoading || isError || !currentUser)
    return {
      isLoading,
      isError,
      error,
      ability: undefined,
      retryAbility: refetch,
    };

  const roles = checkRolesAbility(currentUser.roles);
  const ability = getAbility(roles, currentUser);

  return { ability, isLoading, isError, error, retryAbility: refetch };
};

export default useAppAbility;

const AbilityList = {
  A: ["Admin"],
  B: ["Basic"],
  C: ["Commercial"],
  M: ["CommunityManager"],
  S: ["SavPro"],
  AB: ["Admin", "Basic"],
  AC: ["Admin", "Commercial"],
  AM: ["Admin", "CommunityManager"],
  AS: ["Admin", "SavPro"],
  BS: ["Basic", "SavPro"],
  CS: ["Commercial", "SavPro"],
  MS: ["CommunityManager", "SavPro"],
  BC: ["Commercial", "Basic"],
  BCS: ["Commercial", "Basic", "SavPro"],
  BM: ["CommunityManager", "Basic"],
  BMS: ["CommunityManager", "Basic", "SavPro"],
  ABS: ["Admin", "Basic", "SavPro"],
  ACS: ["Admin", "Commercial", "SavPro"],
  ACB: ["Admin", "Commercial", "Basic"],
  AMB: ["Admin", "CommunityManager", "Basic"],
  AMS: ["Admin", "CommunityManager", "SavPro"],
  CM: ["Commercial", "CommunityManager"],
  CMS: ["Commercial", "CommunityManager", "SavPro"],
  ACM: ["Admin", "Commercial", "CommunityManager"],
  CMB: ["Commercial", "CommunityManager", "Basic"],
  ACBS: ["Admin", "Commercial", "Basic", "SavPro"],
  ACMB: ["Admin", "Commercial", "CommunityManager", "Basic"],
  ACMS: ["Admin", "Commercial", "CommunityManager", "SavPro"],
  AMBS: ["Admin", "CommunityManager", "Basic", "SavPro"],
  CMBS: ["Commercial", "CommunityManager", "Basic", "SavPro"],
  ACMBS: ["Admin", "Commercial", "CommunityManager", "Basic", "SavPro"],
} as { [key: string]: Roles[] };

const checkRolesAbility = (roles: Roles[]) => {
  for (const key in AbilityList) {
    if (roles.every(role => AbilityList[key].includes(role))) {
      return key;
    }
  }
  return null;
};

const getAbility = (key: string | null, user: ProfileResponse) => {
  switch (key) {
    case "A":
      return A_Ability(user);
    case "C":
      return C_Ability(user);
    case "M":
      return M_Ability(user);
    case "S":
      return S_Ability(user);
    case "AB":
      return AB_Ability(user);
    case "AC":
      return AC_Ability(user);
    case "AM":
      return AM_Ability(user);
    case "AS":
      return AS_Ability(user);
    case "BC":
      return BC_Ability(user);
    case "BM":
      return BM_Ability(user);
    case "BS":
      return BS_Ability(user);
    case "ACB":
      return ACB_Ability(user);
    case "AMB":
      return AMB_Ability(user);
    case "CM":
      return CM_Ability(user);
    case "CS":
      return CS_Ability(user);
    case "MS":
      return MS_Ability(user);
    case "ACM":
      return ACM_Ability(user);
    case "CMB":
      return CMB_Ability(user);
    case "BCS":
      return BCS_Ability(user);
    case "BMS":
      return BMS_Ability(user);
    case "ABS":
      return ABS_Ability(user);
    case "ACS":
      return ACS_Ability(user);
    case "AMS":
      return AMS_Ability(user);
    case "CMS":
      return CMS_Ability(user);
    case "ACMB":
      return ACMB_Ability(user);
    case "ACBS":
      return ACBS_Ability(user);
    case "ACMS":
      return ACMS_Ability(user);
    case "AMBS":
      return AMBS_Ability(user);
    case "CMBS":
      return CMBS_Ability(user);
    case "ACMBS":
      return ACMBS_Ability(user);
    default:
      return B_Ability(user);
  }
};
